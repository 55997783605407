import React, { useContext, useState } from 'react';
import { Box, TextField, Modal, Button, useTheme } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import ModalCustom from '../../../../../../../components/Modal/ModalCustom';
import useStyles from './style';
import notify from '../../../../../../../../../utils/notify';
import { ThemeContext } from '../../../../../../../../../utils/context/ThemeContext';
import { useAppDispatch } from '../../../../../../../../../hooks/useAppDispatch';
import { businessAddUser } from '../../../../../../../../../stories/actions/business';
import { backofficeBusinessAddUser } from '../../../../../../../../../stories/actions/backoffice';
import { TranslateInterface } from '../../../../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../../../../utils/hooks/useLanguage';

type ModalBusinessAddUserProps = {
  open: boolean;
  toggle: () => void;
  business: any;
  onRefresh: any;
};

const ModalBusinessAddUser = ({
  open,
  toggle,
  business,
  onRefresh
}: ModalBusinessAddUserProps) => {
  const classes = useStyles();

  const { translate }: TranslateInterface = useLanguage();

  const themeMode = useContext(ThemeContext);

  const dispatch = useAppDispatch();

  const theme = useTheme();

  const [form, setForm] = useState<any>({ email: '' });

  const [loading, setLoading] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    setForm({ ...form, [type]: e.target.value });
  };

  const onClose = async () => {
    setForm({ email: '' });

    setLoading(false);

    toggle();
  };

  const onSubmit = async (e: any) => {
    if (e) e.preventDefault();

    if (form.subject === '' || form.description === '') {
      return notify.error(
        translate('notify.backoffice.business.user.add.required.all')
      );
    }

    setLoading(true);

    const cb = {
      success: (business: any) => {
        setLoading(false);

        setForm({ email: '' });

        notify.success(
          translate('notify.backoffice.business.user.add.success')
        );

        onRefresh(business);

        toggle();
      },
      error: () => {
        setLoading(false);
      }
    };

    dispatch(
      backofficeBusinessAddUser({ ...form, businessId: business._id }, cb)
    );
  };

  return (
    <ModalCustom open={open} toggle={onClose} containerClass={classes.root}>
      <form onSubmit={onSubmit} noValidate autoComplete="off">
        <Box
          sx={{
            // width: 400,
            backgroundColor:
              themeMode.mode === 'light'
                ? `${theme.palette.background.paper}`
                : `${theme.palette.background.default}`,
            borderRadius: '15px',
            padding: '0rem 1rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem'
          }}
        >
          <div>
            <h2 id="parent-modal-title">
              {translate(
                'page.backoffice.business.update.user.modal.add.title'
              )}
            </h2>

            <p id="parent-modal-description">
              {translate(
                'page.backoffice.business.update.user.modal.add.description'
              )}
            </p>
          </div>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2rem'
            }}
          >
            <TextField
              id="outlined-basic"
              label={translate(
                'page.backoffice.business.update.user.modal.add.form.email.label'
              )}
              variant="outlined"
              color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
              value={form.email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                handleChange(e, 'email')
              }
              sx={{
                fontFamily: 'Inter'
              }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '1rem'
              }}
            >
              <Button
                onClick={onClose}
                variant="outlined"
                color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
                sx={{
                  textTransform: 'none!important',
                  fontFamily: 'Inter'
                }}
              >
                {translate(
                  'page.backoffice.business.update.user.modal.add.action.cancel'
                )}
              </Button>
              <LoadingButton
                loading={loading}
                onClick={onSubmit}
                variant="contained"
                style={{
                  backgroundColor:
                    themeMode.mode === 'light'
                      ? `${theme.palette.primary.main}`
                      : `${theme.palette.secondary.main}`,
                  color: loading
                    ? themeMode.mode === 'light'
                      ? `${theme.palette.primary.main}`
                      : `${theme.palette.secondary.main}`
                    : themeMode.mode === 'light'
                    ? `${theme.palette.text.white}`
                    : `${theme.palette.text.black}`
                }}
                sx={{
                  textTransform: 'none!important',
                  fontFamily: 'Inter'
                }}
              >
                {translate(
                  'page.backoffice.business.update.user.modal.add.action.confirm'
                )}
              </LoadingButton>
            </div>
          </Box>
        </Box>
      </form>
    </ModalCustom>
  );
};

export default ModalBusinessAddUser;

/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import LoadingButton from '@mui/lab/LoadingButton';
import { useReduxState } from '../../../../../../../hooks/useReduxState';
import { TemplateContainerInterface } from './interface';
import { useAppDispatch } from '../../../../../../../hooks/useAppDispatch';
import useStyles from './style';
import toast, { Toaster } from 'react-hot-toast';
import { ThemeContext } from '../../../../../../../utils/context/ThemeContext';
import notify from '../../../../../../../utils/notify';
import { getCredits } from '../../../../../../../stories/actions/credits';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../../../../../../utils/context/GlobalContext';
import { TranslateInterface } from '../../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../../utils/hooks/useLanguage';
import { getPlatformVersion } from '../../../../../../../utils/function/getPlatformVersion';
import {
  toolAiDetect,
  toolTranslate
} from '../../../../../../../stories/actions/tools';
import AiDetectResult from '../Result';

const TemplateContainer = ({
  id,
  title,
  description,
  type,
  fields
}: TemplateContainerInterface) => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();
  const ctx = useContext(GlobalContext);
  const { translate }: TranslateInterface = useLanguage();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { user, brands } = useReduxState();

  const [loading, setLoading] = useState(false);

  const [outputs, setOutputs] = useState([]);

  const [activeHistoric, setActiveHistoric] = useState<any>(false);

  const [loadGetAll, setLoadGetAll] = React.useState(false);

  const [form, setForm] = useState<any>({
    type: type,
    template: id,
    content: '',
    result: null,
    language: {
      input: 'detect',
      output: 'portuguese'
    },
    quantity: 1
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    if (type === 'quantity') {
      const value: any = e.target.value;

      let quantity = value;

      if (value && value > 10) {
        quantity = 10;
      }

      if (value && value < 1) {
        quantity = 1;
      }

      return setForm({
        ...form,
        [type]: quantity
      });
    }

    setForm({ ...form, [type]: e.target.value });
  };

  const handleSelect = (event: SelectChangeEvent, type: string) => {
    setForm({
      ...form,
      language: { ...form.language, [type]: event.target.value }
    });
  };

  const onClearForm = () => {
    setForm({
      type: type,
      template: id,
      content: '',
      result: null,
      language: {
        input: 'detect',
        output: 'portuguese'
      },
      quantity: 1
    });
  };

  const handleError = () => {
    return notify.error(translate('notify.tools.aiDetect.required.all'));
  };

  const handleSubmit = () => {
    setLoading(true);

    setForm({
      ...form,
      result: null
    });

    const cb = {
      success: (data: any) => {
        if (data) {
          setForm({
            ...form,
            result: data
          });
        }

        dispatch(getCredits());

        setLoading(false);
      },
      error: () => {
        setLoading(false);
      }
    };

    return toast.promise(dispatch(toolAiDetect(form, cb)), {
      loading: translate('notify.tools.aiDetect.loading'),
      success: data => {
        setLoading(false);

        return <b>{translate('notify.tools.aiDetect.success')}</b>;
      },
      error: err => {
        setLoading(false);

        if (err.message) {
          if (err.message === 'Você atingiu o limite de créditos!') {
            if (!ctx.modal.conversion.open) {
              ctx.modal.conversion.toggle();
            }

            return <b>{err.message}</b>;
          }

          return <b>{err.message}</b>;
        }

        return <b>{translate('notify.tools.aiDetect.error')}</b>;
      }
    });
  };

  const onSubmit = () => {
    const validate: any = [];

    Object.keys(fields).map(field => {
      if (form[field] === '' && field !== 'tone') {
        const response = fields[field];

        if (response?.required) {
          validate.push(field);
        }
      }

      return field;
    });

    if (validate.length > 0) {
      return handleError();
    } else {
      return handleSubmit();
    }
  };

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return (
    <>
      <Box className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid item xs={12} id="container-left-header">
              <div className={classes.titleContainer}>
                <p className={classes.title}>{title}</p>

                <p className={classes.description}>{description}</p>
              </div>
            </Grid>

            <Grid item xs={12} id="container-left">
              <div className={classes.formContainer}>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { width: '100%' }
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                      <FormControl
                        sx={{ width: '95%', marginBottom: '30px' }}
                        size="small"
                      >
                        <InputLabel
                          id="demo-select-small"
                          color={
                            themeMode.mode === 'light' ? 'primary' : 'secondary'
                          }
                          sx={{
                            fontFamily: ['Inter'].join(',')
                          }}
                        >
                          {translate(
                            'page.tools.list.aiDetect.fields.language.input.label'
                          )}
                        </InputLabel>
                        <Select
                          color={
                            themeMode.mode === 'light' ? 'primary' : 'secondary'
                          }
                          labelId="demo-select-small-language-input"
                          id="demo-select-small-anguage-input"
                          value={form.language?.input}
                          label={translate(
                            'page.tools.list.aiDetect.fields.language.input.label'
                          )}
                          onChange={e => handleSelect(e, 'input')}
                          sx={{
                            fontFamily: ['Inter'].join(',')
                          }}
                        >
                          <MenuItem
                            sx={{
                              fontFamily: ['Inter'].join(',')
                            }}
                            value="detect"
                          >
                            {translate(
                              'page.tools.list.aiDetect.fields.language.input.options.detect'
                            )}
                          </MenuItem>
                          <MenuItem
                            sx={{
                              fontFamily: ['Inter'].join(',')
                            }}
                            value="portuguese"
                          >
                            {translate(
                              'page.tools.list.aiDetect.fields.language.input.options.portuguese'
                            )}
                          </MenuItem>
                          <MenuItem
                            sx={{
                              fontFamily: ['Inter'].join(',')
                            }}
                            value="english"
                          >
                            {translate(
                              'page.tools.list.aiDetect.fields.language.input.options.english'
                            )}
                          </MenuItem>
                          <MenuItem
                            sx={{
                              fontFamily: ['Inter'].join(',')
                            }}
                            value="spanish"
                          >
                            {translate(
                              'page.tools.list.aiDetect.fields.language.input.options.spanish'
                            )}
                          </MenuItem>
                        </Select>
                      </FormControl>

                      {Object.keys(fields).map((field, index) => {
                        if (fields[field] && field === 'content') {
                          return (
                            <TextField
                              key={field + index}
                              className={classes.input}
                              id="outlined-multiline-static"
                              label={fields.content.label}
                              placeholder={fields.content.placeholder}
                              multiline={
                                fields.content.rows && fields.content.rows > 1
                              }
                              rows={fields.content.rows || 4}
                              variant="outlined"
                              value={form.content}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ): void => handleChange(e, 'content')}
                              color={
                                themeMode.mode === 'light'
                                  ? 'primary'
                                  : 'secondary'
                              }
                              sx={{ width: '95%' }}
                            />
                          );
                        }

                        return null;
                      })}
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                      <AiDetectResult result={form.result} />
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </Grid>

            <Grid item xs={12} id="container-left-footer">
              <div className={classes.submitContainer}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={
                    getPlatformVersion(user) === 'standard'
                      ? {}
                      : {
                          justifyContent: 'end'
                        }
                  }
                >
                  {getPlatformVersion(user) === 'standard' && (
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                        <Button
                          variant="outlined"
                          onClick={onClearForm}
                          style={{
                            marginLeft: '0px',
                            width: '100%',
                            marginBottom: '10px',
                            borderColor:
                              themeMode.mode === 'light'
                                ? `${theme.palette.primary.main}`
                                : `${theme.palette.secondary.main}`,
                            color:
                              themeMode.mode === 'light'
                                ? `${theme.palette.primary.main}`
                                : `${theme.palette.secondary.main}`
                          }}
                          sx={{
                            textTransform: 'none!important',
                            fontFamily: ['Inter'].join(',')
                          }}
                        >
                          {translate('page.tools.list.aiDetect.action.clean')}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                        <Box
                          component="form"
                          sx={{
                            '& > :not(style)': {
                              width: '100%',
                              marginBottom: '10px'
                            }
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <TextField
                            type="number"
                            id="outlined-basic"
                            label={translate(
                              'page.tools.list.aiDetect.quantity.label'
                            )}
                            variant="outlined"
                            placeholder={translate(
                              'page.tools.list.aiDetect.quantity.label'
                            )}
                            value={form.quantity}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ): void => handleChange(e, 'quantity')}
                            InputLabelProps={{
                              shrink: true
                            }}
                            InputProps={{
                              inputProps: {
                                fontFamily: ['Inter'].join(','),
                                min: 1,
                                max: 10
                              }
                            }}
                            size="small"
                            sx={{
                              fontFamily: ['Inter'].join(',')
                            }}
                          />
                        </Box>
                      </Grid>
                    </>
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{ textAlign: 'right' }}
                  >
                    <div>
                      <LoadingButton
                        style={{
                          marginLeft: '0px',
                          width: '250x',
                          backgroundColor:
                            themeMode.mode === 'light'
                              ? `${theme.palette.primary.main}`
                              : `${theme.palette.secondary.main}`,
                          color: loading
                            ? themeMode.mode === 'light'
                              ? `${theme.palette.primary.main}`
                              : `${theme.palette.secondary.main}`
                            : themeMode.mode === 'light'
                            ? `${theme.palette.text.white}`
                            : `${theme.palette.text.black}`
                        }}
                        loading={loading}
                        variant="contained"
                        onClick={onSubmit}
                        sx={{
                          textTransform: 'none!important',
                          fontFamily: ['Inter'].join(',')
                        }}
                      >
                        {translate('page.tools.list.aiDetect.action.generate')}
                      </LoadingButton>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default TemplateContainer;

import React, { ReactNode, useCallback, useEffect } from 'react';
import { RouterInterface } from './interface';
import AppLayout from '../layouts/App';
import generateRoute from './function/generateRoute';
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useParams,
  useLocation
} from 'react-router-dom';
import AuthLayout from '../layouts/Auth';
import listRoutes from './function/generateRoute/routes';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useReduxState } from '../hooks/useReduxState';
import { checkToken } from '../stories/actions/auth';
import { getProjects } from '../stories/actions/projects';
import { getCredits } from '../stories/actions/credits';
import analytics from '../utils/function/analytics';
import { getPlans } from '../stories/actions/payment';
import { getAllDocument } from '../stories/actions/documents';
import { getBrands } from '../stories/actions/brands';
import mapping from '../utils/function/mapping';
import { getBusinessModel } from '../utils/function/getBusinessModel';
import { getBusiness } from '../stories/actions/business';
import { backofficeGetAllBusiness } from '../stories/actions/backoffice';
import { TranslateInterface } from '../utils/interfaces/TranslateInterface';
import useLanguage from '../utils/hooks/useLanguage';

const Router = (props: RouterInterface) => {
  const [loaded, setLoaded] = React.useState<any>(undefined);

  const [locationHistory, setLocationHistory] = React.useState<any>(undefined);

  const dispatch = useAppDispatch();

  const { auth, user } = useReduxState();

  const { authenticated } = auth;

  const filteredAuthenticated = listRoutes.filter(route => route.protected);

  const filteredNotAuthenticated = listRoutes.filter(route => !route.protected);

  const location = useLocation();

  const navigate = useNavigate();

  const params = useParams();

  const translation: TranslateInterface = useLanguage();

  useEffect(() => {
    if (location?.search.includes('?utm_source=website')) {
      localStorage.setItem('utm_source', 'website');
    }

    if (location?.search.includes('?utm_source=education')) {
      localStorage.setItem('utm_source', 'education');
    }

    const token = location.search.split('token=')[1] || '';

    if (token) {
      localStorage.setItem('token', token);

      navigate(`/`);
    }
  }, [location]);

  useEffect(() => {
    if (user?.detail?.stripe_id) {
      // @ts-ignore
      if (window.barepay && window.barepay.created)
        window.console &&
          console.error &&
          console.error('Barepay snippet included twice.');
      else {
        // @ts-ignore
        window.barepay = { created: !0 };
        var a = document.createElement('script');
        (a.src =
          'https://baremetrics-dunning.baremetrics.com/js/application.js'),
          (a.async = !0);
        var b = document.getElementsByTagName('script')[0];
        // @ts-ignore
        b.parentNode.insertBefore(a, b),
          // @ts-ignore
          (window.barepay.params = {
            access_token_id: '5daf8c60-f3f5-41f0-9c73-aa59d04ff88b',
            customer_oid: user?.detail?.stripe_id
            // customer_oid: 'cus_QJAFQHMfETwaXY'
          });
      }
    }
  }, [user?.detail?.stripe_id]);

  const loadDataCallback = useCallback(() => {
    const cb = {
      success: () => {
        dispatch(getProjects());
        dispatch(getCredits());
        dispatch(getAllDocument());
        dispatch(getBrands());
        dispatch(getPlans());
        dispatch(getBusiness());
        dispatch(backofficeGetAllBusiness());

        setLoaded(true);
      },
      error: () => {
        // VERIFICAR SE A PÁGINA ACESSADA FOI A PAYMENT CHECKOUT
        if (location.pathname.includes('payment/checkout')) {
          localStorage.setItem('PAYMENT_CHECKOUT', location.pathname);
        } else {
          localStorage.setItem('PAYMENT_CHECKOUT', 'inactive');
        }

        if (location.pathname.includes('plans')) {
          if (location?.search.includes('?utm_source=website')) {
            return navigate('/sign-up' + location?.search);
          }

          if (location?.search.includes('?utm_source=education')) {
            return navigate('/sign-up' + location?.search);
          }

          return navigate('/sign-up');
        }

        navigate('/sign-in');
      }
    };

    if (!authenticated) {
      dispatch(checkToken(location, navigate, cb));
    } else {
      cb.success();
    }

    setLoaded(true);
  }, [dispatch]);

  useEffect(() => {
    if (loaded !== undefined && !auth.loaded) {
      setLoaded(true);

      if (
        !['/sign-up', '/forgot-password'].includes(location.pathname) &&
        !location.pathname.includes('password-recovery') &&
        !location.pathname.includes('sign-in')
        //   &&
        // localStorage.getItem('token') &&
        // localStorage.getItem('token') !== ''
      ) {
        loadDataCallback();
      }
    } else {
      setLoaded(true);
    }

    if (location.pathname && location.pathname !== locationHistory) {
      setLocationHistory(location.pathname);
    }
  }, [location, authenticated, loaded, loadDataCallback, auth.loaded]);

  useEffect(() => {
    if (locationHistory) {
      analytics.logPageView();
    }
  }, [locationHistory]);

  const routesAuthenticated: ReactNode = generateRoute(filteredAuthenticated);

  const routesNotAuthenticated: ReactNode = generateRoute(
    filteredNotAuthenticated
  );

  mapping.init('2b580e4768ca44d99a7acd1518b7ef02', {
    debug: true,
    track_pageview: true,
    persistence: 'localStorage'
  });

  const [refresh, setRefresh] = React.useState(false);

  useEffect(() => {
    const businessModel = getBusinessModel();

    if (
      authenticated &&
      !user?.isSubscriber &&
      businessModel !== 'freemium' &&
      !location?.pathname?.includes('payment')
    ) {
      const freeTrial = user?.detail?.options?.freeTrial;

      if (
        location?.pathname?.includes('home') &&
        location?.search.includes('product_tour_id') &&
        location?.search.includes('session_id')
      ) {
        if (!refresh) {
          // window.location.reload();

          const cb = {
            success: () => {
              dispatch(getProjects());
              dispatch(getCredits());
              dispatch(getAllDocument());
              dispatch(getBrands());
              dispatch(getPlans());
              dispatch(getBusiness());
              dispatch(backofficeGetAllBusiness());

              setLoaded(true);

              // navigate('/home?product_tour_id=479884');
            },
            error: () => {
              // VERIFICAR SE A PÁGINA ACESSADA FOI A PAYMENT CHECKOUT
              if (location.pathname.includes('payment/checkout')) {
                localStorage.setItem('PAYMENT_CHECKOUT', location.pathname);
              } else {
                localStorage.setItem('PAYMENT_CHECKOUT', 'inactive');
              }

              if (location.pathname.includes('plans')) {
                return navigate('/sign-up');
              }

              navigate('/sign-in');
            }
          };

          dispatch(checkToken(location, navigate, cb));

          setRefresh(true);
        }

        return;
      }

      if (freeTrial) {
        navigate('/plans');
      }
    }
  }, [authenticated, location, user, refresh]);

  return (
    <Routes>
      <Route path="/" element={<AuthLayout />}>
        {routesNotAuthenticated}
      </Route>

      {authenticated && (
        <Route path="/" element={<AppLayout />}>
          {routesAuthenticated}
        </Route>
      )}

      {authenticated && !user?.isSubscriber && (
        <>
          <Route path="/home" element={<Navigate to="/plans" replace />} />
        </>
      )}

      <Route path="/" element={<Navigate to="/home" replace />} />
      <Route path="/premium" element={<Navigate to="/plans" replace />} />
      <Route
        path="/my-account"
        element={<Navigate to="/account/profile" replace />}
      />
    </Routes>
  );
};

export default Router;

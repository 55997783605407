import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  button: {
    marginRight: '24px!important',

    fontFamily: 'Inter',
    display: 'inline-flex',
    padding: '12px!important',
    justifyContent: 'center',
    gap: '8px',

    // display: 'flex',
    alignItems: 'center',
    minWidth: '48px!important',
    height: '48px',
    textTransform: 'none',
    borderRadius: '100px!important',
    '&:disabled': {
      background: theme.palette.primary.main,
      opacity: 0.7,
      color: 'white'
    },
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      flex: 'auto'
    }
  }
}));

export default Styles;

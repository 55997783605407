import React from 'react';
import { CheckboxAcceptTermsInterface } from './interface';
import useStyles from './style';
import { Checkbox } from '@mui/material';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';

const CheckboxAcceptTerms = ({
  accept,
  handleChange
}: CheckboxAcceptTermsInterface) => {
  const classes: any = useStyles();

  const { translate }: TranslateInterface = useLanguage();

  return (
    <label className={classes.root}>
      <Checkbox
        className={classes.checkmark}
        checked={accept}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
        sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
      />

      <span>
        {translate('page.signUp.form.action.acceptedTerms.one')}{' '}
        <a href="https://clarice.ai/termos" target="_blank" rel="noreferrer">
          {translate('page.signUp.form.action.acceptedTerms.two')}
        </a>
        <span style={{ marginRight: '2px' }}>
          {translate('page.signUp.form.action.acceptedTerms.three')}
        </span>
        <a
          href="https://clarice.ai/privacidade"
          target="_blank"
          rel="noreferrer"
        >
          {translate('page.signUp.form.action.acceptedTerms.four')}
        </a>
        <span style={{ marginLeft: '5px', marginRight: '2px' }}>
          {translate('page.signUp.form.action.acceptedTerms.five')}
        </span>
        <a
          href="https://clarice.ai/reembolsos"
          target="_blank"
          rel="noreferrer"
        >
          {translate('page.signUp.form.action.acceptedTerms.six')}
        </a>
      </span>
    </label>
  );
};

export default CheckboxAcceptTerms;

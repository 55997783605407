/* eslint-disable no-undef */
import {
  BACKOFFICE_BUSINESS_GET_ALL,
  BACKOFFICE_BUSINESS_CREATE,
  BACKOFFICE_BUSINESS_UPDATE,
  BACKOFFICE_BUSINESS_DELETE,
  BUSINESS_ADD_USER,
  BUSINESS_DELETE_USER
} from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';
import notify from '../../utils/notify';
import { Dispatch } from 'react';
import { BasicDispatchParam } from '../../models/dispatchTypes';
import BackofficeRequest from '../repositories/backoffice';
import RequestErrorHelper from '../../utils/helper/RequestErrorHelper';
import analytics from '../../utils/function/analytics';
import BusinessRequest from '../repositories/business';
import mapping from '../../utils/function/mapping';

export const backofficeGetAllBusiness =
  (cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      const payload: any = await BackofficeRequest.getAllBusiness();

      dispatch({
        payload,
        type: BACKOFFICE_BUSINESS_GET_ALL
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (err) {
      if (err instanceof Error) {
        // console.log(err.message);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const backofficeCreateBusiness =
  (business: any, cb?: any) => async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      const payload: any = await BackofficeRequest.createBusiness(business);

      dispatch({
        payload,
        type: BACKOFFICE_BUSINESS_CREATE
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (e) {
      if (e instanceof Error) {
        if (cb && cb.error) {
          cb.error();
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const backofficeUpdateBusiness =
  (business: any, cb?: any) => async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      const payload: any = await BackofficeRequest.updateBusiness(business);

      dispatch({
        payload,
        type: BACKOFFICE_BUSINESS_UPDATE
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (e) {
      if (e instanceof Error) {
        if (cb && cb.error) {
          cb.error();
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const backofficeDeleteBusiness =
  (_id: any, cb?: any) => async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      const payload: any = await BackofficeRequest.deleteBusiness({ _id });

      dispatch({
        payload: { _id },
        type: BACKOFFICE_BUSINESS_DELETE
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (e) {
      if (e instanceof Error) {
        if (cb && cb.error) {
          cb.error();
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const backofficeBusinessAddUser =
  (data: any, cb?: any) => async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      const payload: any = await BackofficeRequest.businessAddUser(data);

      dispatch({
        payload,
        type: BACKOFFICE_BUSINESS_UPDATE
      });

      if (cb && cb.success) {
        cb.success(payload);
      }
    } catch (e) {
      if (e instanceof Error) {
        if (cb && cb.error) {
          cb.error();
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const backofficeBusinessDeleteUser =
  (_id: string, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      const payload = await BackofficeRequest.businessDeleteUser(_id);

      dispatch({
        payload: { _id },
        type: BACKOFFICE_BUSINESS_UPDATE
      });

      if (cb && cb.success) {
        cb.success(payload);
      }
    } catch (err) {
      if (err instanceof Error) {
        // console.log(err.message);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

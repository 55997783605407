import React from 'react';
import Container from './components/Container';
import { TranslateInterface } from '../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../utils/hooks/useLanguage';

const UpdateBrand = () => {
  const { translate }: TranslateInterface = useLanguage();

  const fields = {
    name: {
      label: translate('page.brand.update.form.name.label'),
      placeholder: translate('page.brand.update.form.name.placeholder'),
      required: true,
      type: 'input'
    },
    description: {
      label: translate('page.brand.update.form.description.label'),
      placeholder: translate('page.brand.update.form.description.placeholder'),
      required: true,
      rows: 5,
      type: 'textarea'
    },
    audience: {
      label: translate('page.brand.update.form.audience.label'),
      placeholder: translate('page.brand.update.form.audience.placeholder'),
      rows: 5,
      required: true,
      type: 'textarea'
    },
    tone: {
      label: translate('page.brand.update.form.tone.label'),
      placeholder: translate('page.brand.update.form.tone.placeholder'),
      rows: 5,
      required: true,
      type: 'textarea'
    },
    personality: {
      label: translate('page.brand.update.form.personality.label'),
      placeholder: translate('page.brand.update.form.personality.placeholder'),
      rows: 5,
      required: true,
      type: 'textarea'
    },
    missionVisionAndValue: {
      label: translate('page.brand.update.form.missionVisionAndValue.label'),
      placeholder: translate(
        'page.brand.update.form.missionVisionAndValue.placeholder'
      ),
      rows: 6,
      required: true,
      type: 'textarea'
    },
    keywords: {
      label: translate('page.brand.update.form.keywords.label'),
      placeholder: translate('page.brand.update.form.keywords.placeholder'),
      required: true,
      type: 'textarea'
    },
    preferredWordsAndPhrases: {
      label: translate('page.brand.update.form.preferredWordsAndPhrases.label'),
      placeholder: translate(
        'page.brand.update.form.preferredWordsAndPhrases.placeholder'
      ),
      rows: 5,
      required: true,
      type: 'textarea'
    },
    wordsAndPhrasesToAvoid: {
      label: translate('page.brand.update.form.wordsAndPhrasesToAvoid.label'),
      placeholder: translate(
        'page.brand.update.form.wordsAndPhrasesToAvoid.placeholder'
      ),
      rows: 5,
      required: true,
      type: 'textarea'
    },
    image: {
      label: translate('page.brand.update.form.image.label'),
      placeholder: translate('page.brand.update.form.image.placeholder'),
      required: false,
      type: 'image'
    }
  };

  return (
    <>
      <Container fields={fields} />
    </>
  );
};

export default UpdateBrand;

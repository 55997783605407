/* eslint-disable no-undef */
import instance from '../../utils/axios/instance';
import withAuth from '../../utils/axios/withAuth';

const ToolsRequests = {
  translate: async (fields: models.ToolTranslate) => {
    const { data } = await withAuth().post(`/tools/translate`, {
      content: fields.content,
      language: {
        input: fields.language?.input,
        output: fields.language?.output
      },
      quantity: fields.quantity
    });

    return data;
  },
  aiDetect: async (fields: models.ToolAiDetect) => {
    const { data } = await withAuth().post(`/tools/ai-detect`, {
      content: fields.content,
      language: {
        input: fields.language?.input
      },
      quantity: fields.quantity
    });

    return data;
  }
};

export default ToolsRequests;

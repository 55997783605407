/* eslint-disable no-undef */
import withAuth from '../../utils/axios/withAuth';
import PaymentApi from '../repositories/payment';
import mapping from '../../utils/function/mapping';

import { GET_PLANS, USER_UPDATE_SUBSCRIPTION } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const getPlans = (type?: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const { plans, trialDays }: any = await PaymentApi.getPlans(type);

    dispatch({
      payload: {
        plans,
        trialDays
      },
      type: GET_PLANS
    });

    await mapping.track('Payment Get Plans');
  } catch (err) {
    if (err instanceof Error) {
      // console.log(err.message);
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const openPortal = (cb: any) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const res = await withAuth().post('/payments/portal/session', {
      return_url: 'http://localhost:3000/my-account'
    });

    window.location.href = res.data.url;

    await mapping.track('Payment Portal Session');
  } catch (err) {
    if (err instanceof Error) {
      // console.log(err.message);
      return cb;
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const checkoutPaymentSuccess =
  (sessionId: string, email: string | undefined, cb: any) =>
  async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      const res = await withAuth().get(
        `/payments/checkout-session-success/${sessionId}`
      );

      const price = res.data.subscription.price;

      // @ts-ignore
      if (window.gtag) {
        if (price.unit_amount === 17960) {
          // http://localhost:3000/account/credits-billings?session_id=cs_live_b1GQ5hvl0HAl4vcn3vAGzWRwonVYUu2ZpHctVQVXXT13g1MxjmcNLPiGRU

          // @ts-ignore
          window.gtag('event', 'conversion', {
            send_to: 'AW-349889675/cYB7CNu18OAZEIvJ66YB',
            value: 17960,
            currency: 'BRL',
            transaction_id: price.external_id
          });
        } else if (price.unit_amount === 35960) {
          // @ts-ignore
          window.gtag('event', 'conversion', {
            send_to: 'AW-349889675/cYB7CNu18OAZEIvJ66YB',
            value: 35960,
            currency: 'BRL',
            transaction_id: price.external_id
          });
        } else {
          // @ts-ignore
          window.gtag('event', 'conversion', {
            send_to: 'AW-349889675/Q1hhCPaZvPACEIvJ66YB',
            value: price.unit_amount / 100,
            currency: 'BRL',
            transaction_id: price.external_id
          });
        }
      }

      // @ts-ignore
      if (window.fbq) {
        // @ts-ignore
        window.fbq('track', 'Purchase', {
          currency: 'BRL',
          value: price.unit_amount / 100
        });
      }

      dispatch({
        payload: {
          subscription: res.data.subscription || undefined
        },
        type: USER_UPDATE_SUBSCRIPTION
      });

      await mapping.track('Payment Checkout Success');

      if (cb && cb.success) {
        cb.success();
      }
    } catch (e) {
      if (e instanceof Error) {
        if (cb && cb.error) {
          cb.error();
        }

        // await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const clearPlans = () => (dispatch: any) => {
  dispatch({
    payable: null,
    type: GET_PLANS
  });
};

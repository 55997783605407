import withAuth from '../../utils/axios/withAuth';
import instance from '../../utils/axios/instance';

const BrandsRequests = {
  create: async (form: any) => {
    const { data } = await withAuth().post(`/brands`, {
      ...form
    });

    return data;
  },
  update: async (form: any) => {
    const { data } = await withAuth().put(`/brands/${form._id}`, {
      ...form
    });

    return data;
  },
  getAll: async () => {
    const { data } = await withAuth().get(`/brands`);

    return data;
  },
  delete: async (id: string) => {
    const { data } = await instance().delete(`/brands/${id}`);

    return data;
  },
  generateWithIA: async (description: any) => {
    const { data } = await withAuth().post(`/brands/generate-brand`, {
      description: description
    });

    // const data = {
    //   name: 'Nike',
    //   description:
    //     'A Nike é uma marca global de roupas e calçados esportivos conhecida por sua inovação e qualidade.',
    //   audience:
    //     'Atletas, entusiastas do esporte, jovens adultos interessados em fitness.',
    //   tone: 'Motivacional e inspirador.',
    //   personality:
    //     'Uma marca ousada, corajosa e inspiradora, focada em atletas e pessoas que buscam superar seus limites.',
    //   missionVisionAndValue:
    //     'Missão: Inspirar e inovar para todos os atletas no mundo. \nVisão: Trazer inspiração e inovação para todos os atletas. \nValores: Determinação, inovação, trabalho em equipe.',
    //   keywords: 'Inovação, desempenho, superação.',
    //   preferredWordsAndPhrases: 'Inovação, desempenho, superação.',
    //   wordsAndPhrasesToAvoid: 'Fracasso, desistência, limitações.'
    // };

    return data;
  }
};

export default BrandsRequests;

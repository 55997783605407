import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LANGUAGE_PT from './languages/PT';
import LANGUAGE_EN from './languages/EN';
import LanguageDetector from 'i18next-browser-languagedetector';
import LANGUAGE_ES from './languages/ES';

const languages = {
  BR: 'pt',
  PT: 'pt',
  US: 'en',
  ES: 'es',
  MX: 'es'
};

const settings: any = {
  fallbackLng: 'pt',
  interpolation: {
    escapeValue: false
  },
  resources: {
    default: {
      translation: LANGUAGE_PT
    },
    pt: {
      translation: LANGUAGE_PT
    },
    en: {
      translation: LANGUAGE_EN
    },
    es: {
      translation: LANGUAGE_ES
    }
  },
  fallbackNS: false,
  react: {
    wait: true
  },
  detection: {
    order: ['localStorage', 'querystring', 'cookie', 'navigator'],
    caches: ['cookie']
  }
};

const getPosition = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });
};

async function getSettings() {
  if ('geolocation' in navigator) {
    try {
      const position: any = await getPosition();

      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;

      const response = await fetch(apiUrl);

      const data = await response.json();

      return String(data.address.country_code).toUpperCase();
    } catch (error) {
      return undefined;
    }
  } else {
    return undefined;
  }
}

const language = localStorage.getItem('i18nextLng');

getSettings()
  .then((country: string | undefined) => {
    if (language) {
      settings.fallbackLng = language;
      settings.lng = language;

      return;
    }

    if (!country) {
      // settings.fallbackLng = 'en';
      // settings.lng = 'en';
      //
      // localStorage.setItem('i18nextLng', 'en');

      return;
    }

    // if (country && languages[country]) {
    //   console.log(country);
    //   settings.fallbackLng = languages[country];
    //   settings.lng = languages[country];
    //
    //   localStorage.setItem('i18nextLng', languages[country]);
    //
    //   return;
    // }
  })
  .catch(r => {
    // settings.fallbackLng = 'en';
    // settings.lng = 'en';
    //
    // localStorage.setItem('i18nextLng', 'en');

    return;
  });

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(settings)
  .then(r => r);

export default i18n;

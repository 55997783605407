import React from 'react';
import { ChannelsContainerInterface } from './interface';
import useStyles from './style';
import IconHelp from '../../../../assets/images/auth/icon-help.svg';
import { TranslateInterface } from '../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../utils/hooks/useLanguage';

const ChannelsContainer = ({}: ChannelsContainerInterface) => {
  const classes: any = useStyles();

  const { translate }: TranslateInterface = useLanguage();

  return (
    <div className={classes.root}>
      <img src={IconHelp} alt="" />
      <p className={classes.description}>
        {translate('standard.channels.one')}{' '}
        <a
          href="https://clarice.ai/?fale-conosco"
          target="_blank"
          rel="noreferrer"
        >
          {translate('standard.channels.two')}
        </a>
      </p>
    </div>
  );
};

export default ChannelsContainer;

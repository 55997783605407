/* eslint-disable no-undef */
import {
  FEEDBACK,
  SET_ACTIVE_CORRECTION_ITEM,
  TEXT_ACTIVE_CATEGORY,
  TEXT_ACTIVE_CATEGORY_TYPE,
  TEXT_CREATE,
  TEXT_EDIT,
  TEXT_PROCESS_READABILITY,
  TEXT_UPDATE_GENRE,
  TEXT_UPDATE_LANGUAGE
} from '../actions/actionTypes';

const initialState: reducers.EditorReducer = {
  text: null,
  process: null,
  activeCategory: 'deviations',
  activeCategoryType: 'all',
  activeItem: '',
  feedback: null
};

const editorReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TEXT_EDIT:
      state = {
        ...state,
        text: action.payload
      };
      break;
    case TEXT_CREATE:
      state = {
        ...state,
        text: action.payload
      };
      break;
    case TEXT_PROCESS_READABILITY:
      state = {
        ...state,
        process: action.payload
      };
      break;
    case TEXT_UPDATE_GENRE:
      if (state.process) {
        state.process.genre = action.payload.genre;
      }

      state = {
        ...state
      };
      break;
    case TEXT_UPDATE_LANGUAGE:
      if (state?.process?.language) {
        state.process.language = action.payload.language;
      }

      if (state?.text?.language) {
        state.text.language = action.payload.language;
      }

      state = {
        ...state
      };
      break;
    case TEXT_ACTIVE_CATEGORY:
      state = {
        ...state,
        activeCategory: action.payload,
        activeCategoryType: 'all',
        activeItem: ''
      };
      break;
    case TEXT_ACTIVE_CATEGORY_TYPE:
      state = {
        ...state,
        activeCategoryType: action.payload
      };
      break;
    case FEEDBACK:
      state = {
        ...state,
        feedback: action.payload
      };
      break;
    case SET_ACTIVE_CORRECTION_ITEM:
      state = {
        ...state,
        activeItem: action.payload.item
      };
      break;

    default:
      return state;
  }

  return state;
};

export default editorReducer;

import React, { useContext, useState } from 'react';
import { TemplateCardInterface } from './interface';
import useStyles from './style';
import { CardActionArea, CardActions, Chip, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import clsx from 'clsx';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import GradeIcon from '@mui/icons-material/Grade';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';

const TemplateCard = ({
  id,
  title,
  description,
  type,
  onSelect,
  imageUrl,
  notReady,
  favorite,
  onToggleFavorite
}: TemplateCardInterface) => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  const { translate }: TranslateInterface = useLanguage();

  const color = themeMode.mode === 'light' ? 'primary' : 'secondary';

  return (
    <Grid className={classes.root} item xs={12} sm={12} md={6} lg={4} xl={3}>
      <Card
        className={clsx(classes.card, {
          [classes.cardNotReady]: notReady,
          [classes.cardFavorite]: favorite
        })}
        onClick={onSelect}
        id={id}
      >
        <CardActionArea disableRipple>
          <div
            style={{
              width: '70px',
              height: '70px',
              objectFit: 'cover',
              margin: '1rem 1rem 0 1rem',
              backgroundColor:
                themeMode.mode === 'light' ? '#f1f1f189' : '#44444488',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <img
              style={{
                width: '55px',
                height: '55px',
                objectFit: 'cover'
              }}
              src={imageUrl}
              alt=""
            />
          </div>
          <CardContent className={classes.content}>
            <Typography
              gutterBottom
              style={{
                // fontSize: '18px',
                // fontWeight: '600',
                // fontFamily: 'Inter',

                fontSize: '20px',
                fontWeight: '700',
                fontFamily: 'Inter',
                lineHeight: '125%'
              }}
            >
              {title}
            </Typography>
            <Typography
              style={{
                fontSize: '16px',
                // fontFamily: 'Inter',
                // fontWeight: '400'

                // fontSize: '14px',
                fontFamily: 'Inter',
                fontWeight: '400',
                lineHeight: '150%',
                color:
                  themeMode.mode === 'light'
                    ? 'rgb(79, 61, 102)'
                    : 'rgb(149, 149, 149)'
              }}
              // color="text.secondary"
            >
              {description}
            </Typography>
          </CardContent>

          <CardActions
            style={{
              marginLeft: '5px',
              marginBottom: '3px'
              // paddingRight: '20px!important'
            }}
          >
            {type.map((el, index) => {
              if (el === 'Outros') {
                return null;
              }

              // if (el === 'Marketing') {
              //   return null;
              // }

              if (index >= 2) {
                return null;
              }

              return (
                <Chip
                  label={translate(`page.templates.options.${el}`)}
                  key={el + index}
                  color="default"
                  size="small"
                />
              );
            })}

            {notReady && (
              <Chip
                label={translate(`page.templates.badge.shortly`)}
                color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
                size="small"
                style={{
                  background:
                    themeMode.mode === 'light'
                      ? 'rgb(124 36 250 / 80%)'
                      : '#8CFFE1'
                }}
              />
            )}

            <Tooltip
              title={
                favorite
                  ? translate(`page.templates.action.unfavorite`)
                  : translate(`page.templates.action.favorite`)
              }
            >
              <IconButton
                size="small"
                color={favorite ? color : 'default'}
                style={
                  favorite
                    ? {
                        color: 'rgb(255, 200, 55)',
                        position: 'absolute',
                        right: '5px',
                        marginLeft: '3px'
                      }
                    : {
                        color:
                          themeMode.mode === 'light'
                            ? 'rgb(0 0 0 / 37%)'
                            : 'rgb(255 200 55 / 63%)',
                        position: 'absolute',
                        right: '5px',
                        marginLeft: '3px'
                      }
                }
                // className={classes.button}
                onClick={e => onToggleFavorite(e, id)}
              >
                {favorite ? <GradeIcon /> : <StarOutlineIcon />}
              </IconButton>
            </Tooltip>
          </CardActions>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default TemplateCard;

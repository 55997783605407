import React, { useEffect, useState } from 'react';
import useStyles from './style';
import ContainerAuth from '../../components/ContainerAuth';
import LeftContainerFlow from '../../components/LeftContainerFlow';
import HeadAuth from '../../components/HeadAuth';
import SignInForm from './components/SignInForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useReduxState } from '../../../../hooks/useReduxState';
import { mailConfirmation, signIn } from '../../../../stories/actions/auth';
import { getBusinessModel } from '../../../../utils/function/getBusinessModel';
import notify from '../../../../utils/notify';
import { businessInviteConfirmation } from '../../../../stories/actions/business';
import { TranslateInterface } from '../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../utils/hooks/useLanguage';
import analytics from '../../../../utils/function/analytics';
import mapping from '../../../../utils/function/mapping';

type Props = {};

const SignIn = (props: Props) => {
  const classes = useStyles();

  const { translate }: TranslateInterface = useLanguage();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [connected, setConnected] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingConfirmation, setLoadingConfirmation] =
    useState<any>(undefined);
  const [captcha, setCaptcha] = useState(false);

  const params = useParams();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { auth } = useReduxState();

  useEffect(() => {
    const code: any = params['*'] || '';

    console.log('Code: ', code);

    if (code.length === 90) {
      if (loadingConfirmation === false) {
        setLoadingConfirmation(true);

        const cb = {
          success: () => {
            if (location?.search.includes('?utm_source=website')) {
              if (location?.search.includes('language=pt')) {
                analytics.logEvent('user', 'mail-confirmation-pt');

                mapping.track('E-mail Confirmation PT');
              }

              if (location?.search.includes('language=en')) {
                analytics.logEvent('user', 'mail-confirmation-en');

                mapping.track('E-mail Confirmation EN');
              }

              if (location?.search.includes('language=es')) {
                analytics.logEvent('user', 'mail-confirmation-es');

                mapping.track('E-mail Confirmation ES');
              }
            }

            if (location?.search.includes('?utm_source=education')) {
              if (location?.search.includes('language=pt')) {
                analytics.logEvent('user', 'mail-confirmation-education-pt');

                mapping.track('E-mail Confirmation Education PT');
              }

              if (location?.search.includes('language=en')) {
                analytics.logEvent('user', 'mail-confirmation-education-en');

                mapping.track('E-mail Confirmation Education EN');
              }

              if (location?.search.includes('language=es')) {
                analytics.logEvent('user', 'mail-confirmation-education-es');

                mapping.track('E-mail Confirmation Education ES');
              }
            }

            notify.success(translate('notify.mailConfirmation.success'));

            setTimeout(() => {
              setLoadingConfirmation(false);
            }, 400);

            navigate(`/sign-in`);
          },
          error: () => {
            setTimeout(() => {
              setLoadingConfirmation(false);
            }, 400);

            navigate(`/sign-in`);
          }
        };

        dispatch(mailConfirmation({ code }, cb));
      } else {
        if (loadingConfirmation === undefined) {
          setLoadingConfirmation(false);
        }
      }
    } else {
      if (code.includes('businessCode')) {
        const businessCode = code.split('businessCode=')[1];

        if (businessCode) {
          if (loadingConfirmation === false) {
            setLoadingConfirmation(true);

            const cb = {
              success: () => {
                setTimeout(() => {
                  setLoadingConfirmation(false);
                }, 400);

                navigate(`/sign-in`);
              },
              error: () => {
                setTimeout(() => {
                  setLoadingConfirmation(false);
                }, 400);

                navigate(`/sign-in`);
              }
            };

            dispatch(businessInviteConfirmation({ code: businessCode }, cb));
          } else {
            if (loadingConfirmation === undefined) {
              setLoadingConfirmation(false);
            }
          }
        }
      } else {
        navigate(`/sign-in`);
      }
    }
  }, [loadingConfirmation]);

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (email === '') {
      return notify.error(translate('page.signIn.notify.required.email'));
    }

    if (password === '') {
      return notify.error(translate('page.signIn.notify.required.password'));
    }

    if (!loading && captcha) {
      setLoading(true);

      const cb = {
        success: (user?: any, firstAccess = false) => {
          setCaptcha(false);

          localStorage.setItem('utm_source', '');

          const paymentCheckout = localStorage.getItem('PAYMENT_CHECKOUT');

          if (
            paymentCheckout &&
            paymentCheckout !== '' &&
            paymentCheckout !== 'inactive'
          ) {
            localStorage.getItem('');

            navigate(paymentCheckout);
          } else {
            const businessModel = getBusinessModel();

            if (!user?.isSubscriber && businessModel !== 'freemium') {
              const freeTrial = user?.detail?.options?.freeTrial;

              if (freeTrial) {
                return navigate('/plans');
              }
            }

            if (firstAccess) {
              navigate(`/home?product_tour_id=479884`);

              if (user?.options?.platformVersion === 'standard') {
                if (user.options.language === 'PT-BR') {
                  analytics.logEvent('user', 'first-access-pt');

                  mapping.track('First Access PT');
                }

                if (user.options.language === 'EN-US') {
                  analytics.logEvent('user', 'first-access-en');

                  mapping.track('First Access EN');
                }

                if (user.options.language === 'ES-ES') {
                  analytics.logEvent('user', 'first-access-es');

                  mapping.track('First Access ES');
                }
              }

              if (user?.options?.platformVersion === 'education') {
                if (user.options.language === 'PT-BR') {
                  analytics.logEvent('user', 'first-access-education-pt');

                  mapping.track('First Access Education PT');
                }

                if (user.options.language === 'EN-US') {
                  analytics.logEvent('user', 'first-access-education-en');

                  mapping.track('First Access Education EN');
                }

                if (user.options.language === 'ES-ES') {
                  analytics.logEvent('user', 'first-access-education-es');

                  mapping.track('First Access Education ES');
                }
              }

              // navigate(`/home`);

              if (user) {
                // @ts-ignore
                window.intercomSettings = {
                  api_base: 'https://api-iam.intercom.io',
                  app_id: 'awj7l5s9',
                  name: user.name, // Nome completo
                  email: user.email // Endereço de e-mail
                };
              }

              // @ts-ignore
              window.Intercom('boot', {
                app_id: 'awj7l5s9'
              });
            } else {
              navigate(`/home`);
            }
          }

          setLoading(false);
        },
        error: () => {
          setLoading(false);
        }
      };

      const local = localStorage.getItem('utm_source');

      const type = local === 'education' ? 'education' : 'standard';

      dispatch(signIn({ email, password, connected, type }, cb));
    }
  };

  const handleChange = ({ target }: any) => {
    setConnected(target.checked);
  };

  return (
    <>
      <div className={classes.root}>
        <HeadAuth
          title={'Clarice.ai | ' + translate('page.signIn.title')}
          description={translate('page.signIn.description')}
        />
        <ContainerAuth>
          <LeftContainerFlow
            description={translate('page.signIn.leftContainerFlow.description')}
          />

          <SignInForm
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            connected={connected}
            loading={loading}
            handleChange={handleChange}
            onSubmit={onSubmit}
            captcha={captcha}
            setCaptcha={setCaptcha}
          />
        </ContainerAuth>
      </div>
    </>
  );
};

export default SignIn;

/* eslint-disable no-undef */
import withAuth from '../../utils/axios/withAuth';

const BackofficeRequest = {
  getAllBusiness: async () => {
    const { data } = await withAuth().get(`/backoffice/businesses`);

    return data;
  },
  createBusiness: async (business: any) => {
    const { data } = await withAuth().post(`/backoffice/business/create`, {
      ...business
    });

    return data;
  },
  updateBusiness: async (business: any) => {
    const { data } = await withAuth().post(`/backoffice/business/update`, {
      ...business
    });

    return data;
  },
  deleteBusiness: async (business: any) => {
    const { data } = await withAuth().post(`/backoffice/business/delete`, {
      ...business
    });

    return data;
  },
  businessAddUser: async (business: any) => {
    const { data } = await withAuth().put(`/backoffice/business/add-user`, {
      ...business
    });

    return data;
  },
  businessDeleteUser: async (id: string) => {
    const { data } = await withAuth().put(`/backoffice/business/delete-user`, {
      userId: id
    });

    return data;
  }
};

export default BackofficeRequest;

/* eslint-disable no-undef */
import {
  EDITOR_ADD_WORD_FROM_DICTIONARY,
  TEMPLATE_TOGGLE_FAVORITE,
  USER_ADD_WORD_FROM_DICTIONARY,
  USER_DELETE_WORD_FROM_DICTIONARY,
  USER_DETAIL,
  USER_DISABLE_RULE,
  USER_ENABLE_RULE,
  USER_STATS,
  USER_UPDATE_CHAT_HISTORIC,
  USER_UPDATE_PROFILE,
  USER_UPDATE_PROJECT_ACTIVE,
  USER_UPDATE_BRAND_ACTIVE,
  USER_UPDATE_SUBSCRIPTION,
  USER_UPDATE_THEME,
  USER_UPDATE_ACCEPTED_TERMS,
  USER_UPDATE_PLATFORM
} from '../actions/actionTypes';

const initialState: reducers.UserReducer = {
  detail: null,
  stats: null,
  dictionary: [],
  disabledRules: [],
  templates: {
    favorites: []
  }
};

const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case USER_DETAIL:
      state = {
        ...state,
        ...action.payload,
        detail: action.payload
      };
      break;
    case USER_UPDATE_PROFILE:
      state = {
        ...state,
        ...action.payload,
        detail: {
          ...state.detail,
          ...action.payload
        }
      };
      break;
    case TEMPLATE_TOGGLE_FAVORITE:
      state = {
        ...state,
        templates: {
          ...state.templates,
          favorites: action.payload?.options?.templates?.favorites || []
        },
        detail: state.detail
          ? {
              ...state.detail,
              templates: {
                ...state.detail.templates,
                favorites: action.payload?.options?.templates?.favorites || []
              }
            }
          : null
      };

      break;
    case USER_STATS:
      state = {
        ...state,
        stats: action.payload
      };
      break;
    case EDITOR_ADD_WORD_FROM_DICTIONARY:
      state = {
        ...state,
        dictionary: [...state.dictionary, action.payload]
      };
      break;
    case USER_ADD_WORD_FROM_DICTIONARY:
      state = {
        ...state,
        dictionary: [...state.dictionary, action.payload]
      };
      break;
    case USER_DELETE_WORD_FROM_DICTIONARY:
      state = {
        ...state,
        dictionary: state.dictionary.filter(word => word !== action.payload)
      };
      break;
    case USER_ENABLE_RULE:
      state = {
        ...state,
        disabledRules: state.disabledRules.filter(
          word => word !== action.payload
        )
      };
      break;

    case USER_DISABLE_RULE:
      state = {
        ...state,
        disabledRules: [...state.disabledRules, action.payload]
      };
      break;
    case USER_UPDATE_PROJECT_ACTIVE:
      state = {
        ...state,
        options: action.payload.options
      };
      break;
    case USER_UPDATE_BRAND_ACTIVE:
      state = {
        ...state,
        options: action.payload.options
      };
      break;
    case USER_UPDATE_SUBSCRIPTION:
      state = {
        ...state,
        ...action.payload,
        detail: {
          ...state.detail,
          ...action.payload
        }
      };
      break;
    case USER_UPDATE_CHAT_HISTORIC:
      state = {
        ...state,
        ...action.payload,
        detail: {
          ...state.detail,
          ...action.payload
        }
      };
      break;
    case USER_UPDATE_PLATFORM:
      state = {
        ...state,
        ...action.payload,
        detail: {
          ...state.detail,
          ...action.payload
        }
      };
      break;
    case USER_UPDATE_ACCEPTED_TERMS:
      state = {
        ...state,
        ...action.payload,
        detail: {
          ...state.detail,
          ...action.payload
        }
      };
      break;
    default:
      return state;
  }

  return state;
};

export default userReducer;

import React, { useContext, useEffect, useState } from 'react';
import useStyles from './style';
import {
  useTheme,
  Avatar,
  Typography,
  Divider,
  Button,
  useMediaQuery,
  Grid
} from '@mui/material';
import Box from '@mui/material/Box';
import { useReduxState } from '../../../../../hooks/useReduxState';
import { ThemeContext } from '../../../../../utils/context/ThemeContext';
import { useNavigate } from 'react-router-dom';
import {
  ArchitectureRounded,
  BoltRounded,
  TungstenOutlined,
  WorkspacePremiumRounded
} from '@mui/icons-material';
import { GlobalContext } from '../../../../../utils/context/GlobalContext';
import { GlobalContextInterface } from '../../../../../utils/context/GlobalContext/interface';
import { TranslateInterface } from '../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../utils/hooks/useLanguage';
import formatDate from '../../../../../utils/function/formatDate';
import { isBetaUser } from '../../../../../utils/function/isBetaUser';
import { getPlatformVersion } from '../../../../../utils/function/getPlatformVersion';
import {
  updateLanguage,
  updatePlatform
} from '../../../../../stories/actions/user';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';

const ProfilePage: React.FC = () => {
  const classes = useStyles();

  const themeMode = useContext(ThemeContext);

  const [words, setWords] = useState(0);

  const theme = useTheme();

  const ctx = useContext(GlobalContext);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  const { user, documents } = useReduxState();

  const isCollaborators = isBetaUser(user, 'clarice-collaborators');

  const preventDefault = (event: React.SyntheticEvent) => {
    event.preventDefault();

    navigate(`/account/edit-account`);
  };

  const linkPremium = () => {
    navigate('/plans');
    // navigate('/premium');
  };

  useEffect(() => {
    setWords(getWords());
  }, [documents.all]);

  const getWords = () => {
    let quantity: any = 0;

    if (documents.all.length > 0) {
      documents.all.map((document: any) => {
        const text = document.fullText || '';

        const response =
          text?.split(/\s+/).filter(el => el.length > 0 && el !== '—') || [];

        quantity = quantity + response.length;
      });

      return quantity;
    }

    return '0';
  };

  const { translate }: TranslateInterface = useLanguage();

  const dispatch = useAppDispatch();

  const language = ctx.language;

  const togglePlatform = (platform: string) => {
    dispatch(updatePlatform(platform));
  };

  return (
    <Box display={'flex'} gap={'2rem'} className={classes.root}>
      <div className={classes.container}>
        <div className={classes.content}>
          <h2 className={classes.title}>
            {translate('page.profile.section.info.description')}
          </h2>
          <Avatar
            alt={user.detail?.name}
            className={classes.avatar}
            src={user.detail?.picture}
          />
        </div>
        <Divider orientation={'horizontal'} className={classes.divider} />
        <div className={classes.info}>
          <Typography
            className={classes.infoLabel}
            sx={{
              fontFamily: 'Inter'
            }}
          >
            {translate('page.profile.section.info.name')}
          </Typography>
          <Typography
            className={classes.infoValue}
            sx={{
              fontFamily: 'Inter'
            }}
          >
            {user.detail?.name}
          </Typography>
          <Typography
            className={classes.infoLabel}
            sx={{
              fontFamily: 'Inter'
            }}
          >
            {translate('page.profile.section.info.email')}
          </Typography>
          <Typography
            className={classes.infoValue}
            sx={{
              fontFamily: 'Inter'
            }}
          >
            {user.detail?.email}
          </Typography>
          <Typography
            className={classes.infoLabel}
            sx={{
              fontFamily: 'Inter'
            }}
          >
            {translate('page.profile.section.info.phone')}
          </Typography>
          <Typography
            className={classes.infoValue}
            sx={{
              fontFamily: 'Inter'
            }}
          >
            {user.detail?.phone ||
              translate('page.profile.section.info.uninformed')}
          </Typography>
        </div>
        <Button
          className={classes.button}
          variant={'outlined'}
          color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
          sx={{
            fontFamily: 'Inter',
            textTransform: 'none!important'
          }}
          disableElevation
          onClick={(e: any) => {
            preventDefault(e);
          }}
        >
          {translate('page.profile.section.info.action.edit.label')}
        </Button>
      </div>

      <div className={classes.statsContainer}>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          rowSpacing={1}
          columnSpacing={{ xs: 0, sm: 0, md: 1, lg: 1, xl: 1 }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ marginTop: '16px' }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center'
                // marginBottom: '1rem'
              }}
            >
              <h2
                className={classes.statsTitle}
                style={{
                  justifyContent: 'center',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {translate('page.profile.section.general.description')}
                {!user.isSubscriber ? (
                  <span className={classes.freeAccount}>
                    {translate('page.profile.section.general.tag.free')}
                  </span>
                ) : (
                  <div className={classes.premiumAccount}>
                    {translate('page.profile.section.general.tag.paid')}
                  </div>
                )}
              </h2>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            xl={4}
            style={{ marginTop: '16px' }}
          >
            <div>
              <Typography
                className={classes.infoLabel}
                sx={{
                  fontFamily: 'Inter'
                }}
              >
                {translate('page.profile.section.general.firstAccess')}
              </Typography>
              <Typography
                className={classes.infoValue}
                sx={{
                  fontFamily: 'Inter'
                }}
              >
                {user.since
                  ? formatDate(user.since, language)
                  : translate('page.profile.section.general.uninformed')}
              </Typography>
            </div>
          </Grid>

          {getPlatformVersion(user) === 'standard' && (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={4}
              xl={4}
              style={{ marginTop: '16px' }}
            >
              <div>
                <Typography
                  className={classes.infoLabel}
                  sx={{
                    fontFamily: 'Inter'
                  }}
                >
                  {translate('page.profile.section.general.texts')}
                </Typography>
                <Typography
                  className={classes.infoValue}
                  sx={{
                    fontFamily: 'Inter'
                  }}
                >
                  {documents.all.length || 0}
                </Typography>
              </div>
            </Grid>
          )}

          {getPlatformVersion(user) === 'standard' && (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={4}
              xl={4}
              style={{ marginTop: '16px', marginBottom: '10px' }}
            >
              <div>
                <Typography
                  className={classes.infoLabel}
                  sx={{
                    fontFamily: 'Inter'
                  }}
                >
                  {translate('page.profile.section.general.words')}
                </Typography>
                <Typography
                  className={classes.infoValue}
                  sx={{
                    fontFamily: 'Inter'
                  }}
                >
                  {words}
                </Typography>
              </div>
            </Grid>
          )}

          {getPlatformVersion(user) === 'standard' && (
            <>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ marginTop: '16px', marginBottom: '8px' }}
              >
                <h2 className={classes.statsSubtitle}>
                  {translate('page.profile.section.general.suggestion.title')}
                </h2>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                style={{ marginTop: '16px' }}
              >
                <div className={classes.statsCardsItem}>
                  <div
                    style={{
                      width: isMobile ? '50px' : '65px',
                      height: '50px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#7C24FB',
                      borderRadius: '8.72727px'
                      //boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.08)'
                    }}
                  >
                    <TungstenOutlined
                      sx={{ width: '30px', height: '30px', color: '#fff' }}
                    ></TungstenOutlined>
                  </div>
                  <div>
                    <p className={classes.statsLabel}>
                      {translate(
                        'page.profile.section.general.suggestion.options.clarity'
                      )}
                    </p>
                    <p className={classes.statsValue}>
                      0{' '}
                      {translate(
                        'page.profile.section.general.suggestion.corrected'
                      )}
                    </p>
                  </div>
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                style={{ marginTop: '16px' }}
              >
                <div className={classes.statsCardsItem}>
                  <div
                    style={{
                      width: isMobile ? '50px' : '65px',
                      height: '50px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#09B386',
                      borderRadius: '8.72727px'
                      //boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.08)'
                    }}
                  >
                    <ArchitectureRounded
                      sx={{ width: '30px', height: '30px', color: '#fff' }}
                    ></ArchitectureRounded>
                  </div>
                  <div>
                    <p className={classes.statsLabel}>
                      {translate(
                        'page.profile.section.general.suggestion.options.conciseness'
                      )}
                    </p>
                    <p className={classes.statsValue}>
                      0{' '}
                      {translate(
                        'page.profile.section.general.suggestion.corrected'
                      )}
                    </p>
                  </div>
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                style={{ marginTop: '16px' }}
              >
                <div className={classes.statsCardsItem}>
                  <div
                    style={{
                      width: isMobile ? '50px' : '65px',
                      height: '50px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#A4A3FF',
                      borderRadius: '8.72727px'
                      //boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.08)'
                    }}
                  >
                    <BoltRounded
                      sx={{ width: '30px', height: '30px', color: '#fff' }}
                    ></BoltRounded>
                  </div>
                  <div>
                    <p className={classes.statsLabel}>
                      {translate(
                        'page.profile.section.general.suggestion.options.strength'
                      )}
                    </p>
                    <p className={classes.statsValue}>
                      0{' '}
                      {translate(
                        'page.profile.section.general.suggestion.corrected'
                      )}
                    </p>
                  </div>
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                style={{ marginTop: '16px' }}
              >
                <div className={classes.statsCardsItem}>
                  <div
                    style={{
                      width: isMobile ? '50px' : '65px',
                      height: '50px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#FFC560',
                      borderRadius: '8.72727px'
                      //boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.08)'
                    }}
                  >
                    <WorkspacePremiumRounded
                      sx={{ width: '30px', height: '30px', color: '#fff' }}
                    ></WorkspacePremiumRounded>
                  </div>
                  <div>
                    <p className={classes.statsLabel}>
                      {translate(
                        'page.profile.section.general.suggestion.options.originality'
                      )}
                    </p>
                    <p className={classes.statsValue}>
                      0{' '}
                      {translate(
                        'page.profile.section.general.suggestion.corrected'
                      )}
                    </p>
                  </div>
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                style={{ marginTop: '16px' }}
              >
                <div className={classes.statsCardsItem}>
                  <div
                    style={{
                      width: isMobile ? '50px' : '65px',
                      height: '50px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#4CD7E4',
                      borderRadius: '8.72727px'
                      //boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.08)'
                    }}
                  >
                    <WorkspacePremiumRounded
                      sx={{ width: '30px', height: '30px', color: '#fff' }}
                    ></WorkspacePremiumRounded>
                  </div>
                  <div>
                    <p className={classes.statsLabel}>
                      {translate(
                        'page.profile.section.general.suggestion.options.formatting'
                      )}
                    </p>
                    <p className={classes.statsValue}>
                      0{' '}
                      {translate(
                        'page.profile.section.general.suggestion.corrected'
                      )}
                    </p>
                  </div>
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                style={{ marginTop: '16px' }}
              >
                <div className={classes.statsCardsItem}>
                  <div
                    style={{
                      width: isMobile ? '50px' : '65px',
                      height: '50px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#E44C5D',
                      borderRadius: '8.72727px'
                      //boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.08)'
                    }}
                  >
                    <WorkspacePremiumRounded
                      sx={{ width: '30px', height: '30px', color: '#fff' }}
                    ></WorkspacePremiumRounded>
                  </div>
                  <div>
                    <p className={classes.statsLabel}>
                      {translate(
                        'page.profile.section.general.suggestion.options.correction'
                      )}
                    </p>
                    <p className={classes.statsValue}>
                      0{' '}
                      {translate(
                        'page.profile.section.general.suggestion.corrected'
                      )}
                    </p>
                  </div>
                </div>
              </Grid>
            </>
          )}
        </Grid>

        {isCollaborators && (
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            mt={3}
            rowSpacing={1}
            columnSpacing={{ xs: 0, sm: 0, md: 1, lg: 1, xl: 1 }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ marginTop: '16px' }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'center'
                  // marginBottom: '1rem'
                }}
              >
                <h2
                  className={classes.statsTitle}
                  style={{
                    justifyContent: 'center',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  {translate('configurator.language.title')}
                </h2>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ marginTop: '16px', marginBottom: '8px' }}
            >
              <Box>
                <Button
                  variant={
                    ctx.language.includes('pt') ? 'contained' : 'outlined'
                  }
                  color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
                  size="small"
                  sx={{
                    textTransform: 'unset',
                    cursor: ctx.language.includes('pt') ? 'auto' : 'pointer',
                    minWidth: '95px'
                  }}
                  onClick={() => ctx.toggleLanguage('pt')}
                >
                  {translate('configurator.language.options.portuguese')}
                </Button>

                <Button
                  variant={
                    ctx.language.includes('en') ? 'contained' : 'outlined'
                  }
                  color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
                  size="small"
                  sx={{
                    marginLeft: '10px',
                    textTransform: 'unset',
                    cursor: ctx.language.includes('en') ? 'auto' : 'pointer',
                    minWidth: '85px'
                  }}
                  onClick={() => ctx.toggleLanguage('en')}
                >
                  {translate('configurator.language.options.english')}
                </Button>

                <Button
                  variant={
                    ctx.language.includes('es') ? 'contained' : 'outlined'
                  }
                  color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
                  size="small"
                  sx={{
                    marginLeft: '10px',
                    textTransform: 'unset',
                    cursor: ctx.language.includes('es') ? 'auto' : 'pointer',
                    minWidth: '85px'
                  }}
                  onClick={() => ctx.toggleLanguage('es')}
                >
                  {translate('configurator.language.options.spanish')}
                </Button>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ marginTop: '16px' }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'center'
                  // marginBottom: '1rem'
                }}
              >
                <h2
                  className={classes.statsTitle}
                  style={{
                    justifyContent: 'center',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  {translate('configurator.platform.title')}
                </h2>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ marginTop: '16px', marginBottom: '8px' }}
            >
              <Box>
                <Button
                  variant={
                    getPlatformVersion(user) === 'standard'
                      ? 'contained'
                      : 'outlined'
                  }
                  color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
                  size="small"
                  sx={{
                    textTransform: 'unset',
                    cursor:
                      getPlatformVersion(user) === 'standard'
                        ? 'auto'
                        : 'pointer',
                    minWidth: '95px'
                  }}
                  onClick={() => togglePlatform('standard')}
                >
                  {translate('configurator.platform.options.standard')}
                </Button>

                <Button
                  variant={
                    getPlatformVersion(user) === 'education'
                      ? 'contained'
                      : 'outlined'
                  }
                  color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
                  size="small"
                  sx={{
                    marginLeft: '10px',
                    textTransform: 'unset',
                    cursor:
                      getPlatformVersion(user) === 'education'
                        ? 'auto'
                        : 'pointer',
                    minWidth: '95px'
                  }}
                  onClick={() => togglePlatform('education')}
                >
                  {translate('configurator.platform.options.education')}
                </Button>

                <Button
                  variant={
                    getPlatformVersion(user) === 'agents'
                      ? 'contained'
                      : 'outlined'
                  }
                  color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
                  size="small"
                  sx={{
                    marginLeft: '10px',
                    textTransform: 'unset',
                    cursor:
                      getPlatformVersion(user) === 'agents'
                        ? 'auto'
                        : 'pointer',
                    minWidth: '95px'
                  }}
                  onClick={() => togglePlatform('agents')}
                >
                  {translate('configurator.platform.options.agents')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </div>
    </Box>
  );
};

export default ProfilePage;

import { BusinessUsersInterface } from './interface';
import {
  Grid,
  Box,
  TextField,
  Divider,
  useTheme,
  Button,
  Chip
} from '@mui/material';
import useStyles from './style';
import React, { useContext, useState } from 'react';
import { ThemeContext } from '../../../../../../../../../utils/context/ThemeContext';
import LoadingButton from '@mui/lab/LoadingButton';
import { useReduxState } from '../../../../../../../../../hooks/useReduxState';
import {
  businessDeleteUser,
  createBusiness
} from '../../../../../../../../../stories/actions/business';
import { useAppDispatch } from '../../../../../../../../../hooks/useAppDispatch';
import ModalCreateBusiness from '../ModalCreateBusiness';
import { backofficeDeleteBusiness } from '../../../../../../../../../stories/actions/backoffice';
import { useNavigate } from 'react-router-dom';
import { TranslateInterface } from '../../../../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../../../../utils/hooks/useLanguage';
import notify from '../../../../../../../../../utils/notify';

const Businesses = ({ onlyView }: BusinessUsersInterface) => {
  const classes: any = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const { translate }: TranslateInterface = useLanguage();

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const dispatch = useAppDispatch();

  const { backoffice } = useReduxState();

  const onDelete = async (_id: string) => {
    const cb = {
      success: () => {
        notify.success(translate('notify.backoffice.business.delete.success'));
      },
      error: () => {}
    };

    dispatch(backofficeDeleteBusiness(_id, cb));
  };

  const onUpdate = async (_id: string) => {
    navigate(`/backoffice/business/${_id}`);
  };

  const formatDate = (newDate: string) => {
    const date = newDate?.split('T')[0];

    const [year, month, day] = date.split('-');

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  const formatChip = (item: any) => {
    const date = new Date();

    if (!item?.subscription) {
      return {
        label: translate('page.backoffice.business.list.badge.pending'),
        color:
          themeMode.mode === 'light'
            ? 'rgba(255, 191, 73, 1)'
            : 'rgba(255, 191, 73, 1)'
      };
    }

    const start = new Date(item.subscription.period.start);
    const end = new Date(item.subscription.period.end);

    if (date >= start && date <= end) {
      return {
        label: translate('page.backoffice.business.list.badge.active'),
        color:
          themeMode.mode === 'light'
            ? 'rgba(70, 189, 159, 1)'
            : 'rgba(70, 189, 159, 1)'
      };
    }

    if (date > end) {
      return {
        label: translate('page.backoffice.business.list.badge.expired'),
        color:
          themeMode.mode === 'light'
            ? 'rgba(255, 191, 73, 1)'
            : 'rgba(255, 191, 73, 1)'
      };
    }

    if (date < start) {
      return {
        label: translate('page.backoffice.business.list.badge.pending'),
        color:
          themeMode.mode === 'light'
            ? 'rgba(255, 191, 73, 1)'
            : 'rgba(255, 191, 73, 1)'
      };
    }
  };

  const getDashboard = () => {
    const total = backoffice?.businesses.length;

    if (total === 0) {
      return {
        total: 0,
        active: 0,
        expired: 0
      };
    }

    const active = backoffice?.businesses.filter((item: any) => {
      const date = new Date();

      if (!item?.subscription) {
        return false;
      }

      const start = new Date(item.subscription.period.start);
      const end = new Date(item.subscription.period.end);

      return date >= start && date <= end;
    });

    const expired = backoffice?.businesses.filter((item: any) => {
      const date = new Date();

      if (!item?.subscription) {
        return false;
      }

      const start = new Date(item.subscription.period.start);
      const end = new Date(item.subscription.period.end);

      return date > end;
    });

    return {
      total,
      active: active.length,
      expired: expired.length
    };
  };

  const dashboard = getDashboard();

  return (
    <Box className={classes.root}>
      <ModalCreateBusiness open={modalOpen} toggle={toggleModal} />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid item xs={12}>
            <div className={classes.contentContainer}>
              <div className={classes.formContainer}>
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  flexDirection={'row'}
                  width={'100%'}
                >
                  <Grid item xs={6}>
                    <p className={classes.title}>
                      {translate('page.backoffice.business.list.title')}
                      {dashboard.total > 0 && (
                        <Chip
                          label={`${translate(
                            'page.backoffice.business.dashboard.badge.total'
                          )}: ${dashboard.total}`}
                          color={
                            themeMode.mode === 'light' ? 'primary' : 'secondary'
                          }
                          sx={{
                            height: '32px!important',
                            fontSize: '0.8rem!important',
                            fontWeight: '700!important',
                            marginLeft: '8px'
                          }}
                          style={{
                            background:
                              themeMode.mode === 'light'
                                ? 'rgb(124 36 250 / 80%)'
                                : 'rgba(70, 189, 159, 1)'
                          }}
                          size="small"
                        />
                      )}
                      {dashboard.active > 0 && (
                        <Chip
                          label={`${translate(
                            'page.backoffice.business.dashboard.badge.active'
                          )}: ${dashboard.active}`}
                          color={
                            themeMode.mode === 'light' ? 'primary' : 'secondary'
                          }
                          sx={{
                            height: '32px!important',
                            fontSize: '0.8rem!important',
                            fontWeight: '700!important',
                            marginLeft: '8px',
                            background:
                              themeMode.mode === 'light'
                                ? 'rgba(70, 189, 159, 1)'
                                : 'rgba(70, 189, 159, 1)'
                          }}
                          size="small"
                        />
                      )}
                      {dashboard.expired > 0 && (
                        <Chip
                          label={`${translate(
                            'page.backoffice.business.dashboard.badge.expired'
                          )}: ${dashboard.expired}`}
                          color={
                            themeMode.mode === 'light' ? 'primary' : 'secondary'
                          }
                          sx={{
                            height: '32px!important',
                            fontSize: '0.8rem!important',
                            fontWeight: '700!important',
                            marginLeft: '8px',
                            background:
                              themeMode.mode === 'light'
                                ? 'rgba(255, 191, 73, 1)'
                                : 'rgba(255, 191, 73, 1)'
                          }}
                          size="small"
                        />
                      )}
                    </p>
                  </Grid>

                  {!onlyView && (
                    <Grid item xs={6} className={classes.submitContainer}>
                      <LoadingButton
                        style={{
                          fontFamily: 'Inter',
                          marginLeft: '0px',
                          backgroundColor:
                            themeMode.mode === 'light'
                              ? `${theme.palette.primary.main}`
                              : `${theme.palette.secondary.main}`,
                          color:
                            themeMode.mode === 'light'
                              ? `${theme.palette.text.white}`
                              : `${theme.palette.text.black}`
                        }}
                        sx={{
                          textTransform: 'none!important'
                        }}
                        variant="contained"
                        onClick={toggleModal}
                      >
                        {translate(
                          'page.backoffice.business.list.action.create'
                        )}
                      </LoadingButton>
                    </Grid>
                  )}
                </Box>

                {backoffice?.businesses.length === 0 && (
                  <p
                    className={classes.description}
                    style={{
                      textAlign: 'center',
                      marginTop: '20px',
                      marginBottom: '20px'
                    }}
                  >
                    {backoffice?.loaded
                      ? translate('page.backoffice.business.list.notAdded')
                      : translate('page.backoffice.business.list.loading')}
                  </p>
                )}
              </div>

              {backoffice?.businesses.map((item: any, index: number) => {
                const format: any = formatChip(item);

                return (
                  <div
                    style={{
                      paddingLeft: '25px',
                      paddingRight: '25px',
                      paddingTop: '10px',
                      paddingBottom: '10px'
                    }}
                    key={index}
                  >
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      flexDirection={'row'}
                      width={'100%'}
                    >
                      <div>
                        <p
                          className={classes.description}
                          style={{ marginBottom: '20px' }}
                        >
                          <b>
                            {translate('page.backoffice.business.list.company')}
                            :
                          </b>{' '}
                          {item.name}
                          {item?.licenses && (
                            <Chip
                              label={
                                item?.users.length +
                                ` ${translate(
                                  'page.backoffice.business.list.licenses.of'
                                )} ` +
                                item?.licenses +
                                ` ${translate(
                                  'page.backoffice.business.list.licenses.used'
                                )}`
                              }
                              color={
                                themeMode.mode === 'light'
                                  ? 'primary'
                                  : 'secondary'
                              }
                              sx={{
                                height: '32px!important',
                                fontSize: '0.8rem!important',
                                fontWeight: '700!important',
                                marginLeft: '10px',
                                background:
                                  themeMode.mode === 'light'
                                    ? 'rgba(70, 189, 159, 1)'
                                    : 'rgba(70, 189, 159, 1)'
                              }}
                              size="small"
                            />
                          )}
                          {item?.subscription && (
                            <Chip
                              label={format.label}
                              color={
                                themeMode.mode === 'light'
                                  ? 'primary'
                                  : 'secondary'
                              }
                              sx={{
                                height: '32px!important',
                                fontSize: '0.8rem!important',
                                fontWeight: '700!important',
                                marginLeft: '5px',
                                background: format.color
                              }}
                              size="small"
                            />
                          )}
                        </p>

                        {item?.subscription?.type && (
                          <div>
                            <p
                              className={classes.description}
                              style={{ marginBottom: '30px' }}
                            >
                              <b>
                                {translate(
                                  'page.backoffice.business.list.plan'
                                )}
                                :
                              </b>{' '}
                              {item.subscription.type}
                            </p>

                            {item?.subscription?.period && (
                              <p
                                className={classes.description}
                                style={{ marginBottom: '30px' }}
                              >
                                <b>
                                  {translate(
                                    'page.backoffice.business.list.startDate'
                                  )}
                                  :
                                </b>{' '}
                                {formatDate(item.subscription.period.start)}
                              </p>
                            )}

                            <p
                              className={classes.description}
                              style={{ marginBottom: '30px' }}
                            >
                              <b>
                                {' '}
                                {translate(
                                  'page.backoffice.business.list.endDate'
                                )}
                                :
                              </b>{' '}
                              {formatDate(item.subscription.period.end)}
                            </p>
                          </div>
                        )}
                      </div>

                      <div>
                        <Button
                          color={
                            themeMode.mode === 'light' ? 'primary' : 'secondary'
                          }
                          size={'small'}
                          variant="outlined"
                          className={classes.enBtn}
                          onClick={() => onUpdate(item._id)}
                          sx={{
                            textTransform: 'none!important',
                            fontFamily: 'Inter',
                            marginLeft: '10px'
                          }}
                        >
                          {translate(
                            'page.backoffice.business.list.action.update'
                          )}
                        </Button>

                        <Button
                          color={
                            themeMode.mode === 'light' ? 'primary' : 'secondary'
                          }
                          size={'small'}
                          variant="outlined"
                          className={classes.enBtn}
                          onClick={() => onDelete(item._id)}
                          sx={{
                            textTransform: 'none!important',
                            fontFamily: 'Inter',
                            marginLeft: '10px'
                          }}
                        >
                          {translate(
                            'page.backoffice.business.list.action.delete'
                          )}
                        </Button>
                      </div>
                    </Box>
                    {index != backoffice?.businesses?.length && (
                      <Divider className={classes.dividerTable} />
                    )}
                  </div>
                );
              })}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Businesses;

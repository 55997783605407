/* eslint-disable no-undef */
import withAuth from '../../utils/axios/withAuth';
import instance from '../../utils/axios/instance';
import { getLanguage } from '../../utils/function/getLanguage';

const BusinessRequest = {
  createBusiness: async (business: any) => {
    const { data } = await withAuth().post(`/business`, {
      name: business.name
    });

    return data;
  },
  updateBusiness: async (business: any) => {
    const language = getLanguage();

    let formatted = 'PT-BR';

    if (language === 'pt') {
      formatted = 'PT-BR';
    }

    if (language === 'en') {
      formatted = 'EN-US';
    }

    if (language === 'es') {
      formatted = 'ES-ES';
    }

    const { data } = await withAuth().put(`/business/update/${business._id}`, {
      ...business,
      language: formatted
    });

    return data;
  },
  businessAddUser: async (business: any) => {
    const { data } = await withAuth().put(`/business/add-user`, {
      ...business
    });

    return data;
  },
  businessDeleteUser: async (id: string) => {
    const { data } = await withAuth().delete(`/business/delete-user/${id}`);

    return data;
  },
  getBusiness: async () => {
    const { data } = await withAuth().get(`/business`);

    return data;
  },
  inviteConfirmation: async (code: string) => {
    const res = await instance().post(`/business/invite-confirmation`, {
      code
    });

    return res.data;
  }
};

export default BusinessRequest;

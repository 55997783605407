/* eslint-disable no-undef */
import {
  BUSINESS_ADD_USER,
  BUSINESS_CREATE,
  BUSINESS_DELETE_USER,
  BUSINESS_GET,
  BUSINESS_UPDATE
} from '../actions/actionTypes';

const initialState: any = {
  loaded: false,
  detail: null
};

const brandsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case BUSINESS_CREATE:
      state = {
        ...state,
        detail: action.payload
      };
      break;
    case BUSINESS_UPDATE:
      state = {
        ...state,
        detail: action.payload
      };
      break;
    case BUSINESS_ADD_USER:
      state = {
        ...state,
        detail: action.payload
      };
      break;
    case BUSINESS_DELETE_USER:
      state = {
        ...state,
        detail: {
          ...state.detail,
          users: state.detail.users.filter(
            (item: any) => item._id !== action.payload._id
          )
        }
      };
      break;
    case BUSINESS_GET:
      state = {
        ...state,
        loaded: true,
        detail: action.payload
      };
      break;
    default:
      return state;
  }

  return state;
};

export default brandsReducer;

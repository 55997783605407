import { BusinessUsersInterface } from './interface';
import {
  Grid,
  Box,
  TextField,
  Divider,
  useTheme,
  Button,
  Chip
} from '@mui/material';
import useStyles from './style';
import React, { useContext, useState } from 'react';
import { ThemeContext } from '../../../../../../../utils/context/ThemeContext';
import LoadingButton from '@mui/lab/LoadingButton';
import { useReduxState } from '../../../../../../../hooks/useReduxState';
import {
  businessDeleteUser,
  createBusiness
} from '../../../../../../../stories/actions/business';
import { useAppDispatch } from '../../../../../../../hooks/useAppDispatch';
import ModalBusinessAddUser from '../ModalBusinessAddUser';
import { TranslateInterface } from '../../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../../utils/hooks/useLanguage';
import notify from '../../../../../../../utils/notify';
import formatDate from '../../../../../../../utils/function/formatDate';
import { GlobalContext } from '../../../../../../../utils/context/GlobalContext';

const BusinessUsers = ({ onlyView }: BusinessUsersInterface) => {
  const classes: any = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();
  const { translate }: TranslateInterface = useLanguage();

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const dispatch = useAppDispatch();

  const { user, business } = useReduxState();

  const onDelete = async (_id: string) => {
    const cb = {
      success: () => {
        notify.success(translate('notify.business.user.delete.success'));
      },
      error: () => {}
    };

    dispatch(businessDeleteUser(_id, cb));
  };

  const ctx = useContext(GlobalContext);
  const language = ctx.language;

  return (
    <Box className={classes.root}>
      <ModalBusinessAddUser open={modalOpen} toggle={toggleModal} />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid item xs={12}>
            <div className={classes.contentContainer}>
              <div className={classes.formContainer}>
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  flexDirection={'row'}
                  width={'100%'}
                >
                  <p
                    className={classes.title}
                    style={{
                      minWidth: '50%'
                    }}
                  >
                    {translate('page.business.list.title')}{' '}
                    {business?.detail?.licenses && (
                      <Chip
                        label={
                          business?.detail?.users?.length +
                          ` ${translate('page.business.list.licenses.of')} ` +
                          business?.detail?.licenses +
                          ` ${translate('page.business.list.licenses.used')}`
                        }
                        color={
                          themeMode.mode === 'light' ? 'primary' : 'secondary'
                        }
                        sx={{
                          height: '32px!important',
                          fontSize: '0.8rem!important',
                          fontWeight: '700!important',
                          marginLeft: '5px',
                          background:
                            themeMode.mode === 'light'
                              ? 'rgba(70, 189, 159, 1)'
                              : 'rgba(70, 189, 159, 1)'
                        }}
                        size="small"
                      />
                    )}
                  </p>

                  {!onlyView && (
                    <Grid item xs={12} className={classes.submitContainer}>
                      <LoadingButton
                        style={{
                          fontFamily: 'Inter',
                          marginLeft: '0px',
                          backgroundColor:
                            themeMode.mode === 'light'
                              ? `${theme.palette.primary.main}`
                              : `${theme.palette.secondary.main}`,
                          color:
                            themeMode.mode === 'light'
                              ? `${theme.palette.text.white}`
                              : `${theme.palette.text.black}`
                        }}
                        sx={{
                          textTransform: 'none!important'
                        }}
                        variant="contained"
                        onClick={toggleModal}
                      >
                        {translate('page.business.list.action.add')}
                      </LoadingButton>
                    </Grid>
                  )}
                </Box>

                {business?.detail?.users?.length === 0 && (
                  <p
                    className={classes.description}
                    style={{
                      textAlign: 'center',
                      marginTop: '20px',
                      marginBottom: '20px'
                    }}
                  >
                    {translate('page.business.list.notAdded')}
                  </p>
                )}
              </div>

              {business?.detail?.users?.map((item: any, index: number) => (
                <div
                  style={{
                    paddingLeft: '25px',
                    paddingRight: '25px',
                    paddingTop: '10px',
                    paddingBottom: '10px'
                  }}
                  key={index}
                >
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    flexDirection={'row'}
                    width={'100%'}
                  >
                    <div>
                      <p className={classes.description}>
                        <b>{translate('page.business.list.name')}: </b>{' '}
                        {item.name}
                      </p>

                      <p className={classes.description}>
                        <b>{translate('page.business.list.email')}: </b>{' '}
                        {item.email}
                      </p>

                      {item.lastAccess && (
                        <p className={classes.description}>
                          <b>{translate('page.business.list.lastAccess')}: </b>{' '}
                          {formatDate(item.lastAccess, language)}
                        </p>
                      )}
                    </div>

                    <div>
                      {item.main && (
                        <Chip
                          label={translate('page.business.list.badge.owner')}
                          color={
                            themeMode.mode === 'light' ? 'primary' : 'secondary'
                          }
                          // size="small"
                          style={{
                            background:
                              themeMode.mode === 'light'
                                ? 'rgb(124 36 250 / 80%)'
                                : '#8CFFE1'
                          }}
                        />
                      )}

                      {!item?.main && item.status === 'active' && (
                        <Chip
                          label={translate('page.business.list.badge.active')}
                          color={
                            themeMode.mode === 'light' ? 'primary' : 'secondary'
                          }
                          style={{
                            background:
                              themeMode.mode === 'light'
                                ? 'rgba(70, 189, 159, 1)'
                                : 'rgba(70, 189, 159, 1)'
                          }}
                        />
                      )}

                      {!item?.main && item.status === 'pending' && (
                        <Chip
                          label={translate('page.business.list.badge.pending')}
                          color={
                            themeMode.mode === 'light' ? 'primary' : 'secondary'
                          }
                          style={{
                            background:
                              themeMode.mode === 'light'
                                ? 'rgba(255, 191, 73, 1)'
                                : 'rgba(255, 191, 73, 1)'
                          }}
                        />
                      )}

                      {!item.main && !onlyView && (
                        <Button
                          color={
                            themeMode.mode === 'light' ? 'primary' : 'secondary'
                          }
                          size={'small'}
                          variant="outlined"
                          className={classes.enBtn}
                          onClick={() => onDelete(item._id)}
                          sx={{
                            textTransform: 'none!important',
                            fontFamily: 'Inter',
                            marginLeft: '10px'
                          }}
                        >
                          {translate('page.business.list.action.delete')}
                        </Button>
                      )}
                    </div>
                  </Box>
                  {index != business?.detail?.users?.length && (
                    <Divider className={classes.dividerTable} />
                  )}
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BusinessUsers;

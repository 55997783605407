/* eslint-disable no-undef */
import {
  BACKOFFICE_BUSINESS_CREATE,
  BACKOFFICE_BUSINESS_DELETE,
  BACKOFFICE_BUSINESS_GET_ALL,
  BACKOFFICE_BUSINESS_UPDATE
} from '../actions/actionTypes';

const initialState: any = {
  loaded: false,
  detail: null,
  businesses: []
};

const backofficeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case BACKOFFICE_BUSINESS_GET_ALL:
      state = {
        ...state,
        businesses: action.payload,
        loaded: true
      };
      break;
    case BACKOFFICE_BUSINESS_CREATE:
      state = {
        ...state,
        businesses: [...state.businesses, action.payload]
      };
      break;
    case BACKOFFICE_BUSINESS_UPDATE:
      state = {
        ...state,
        businesses: state.businesses.map((business: any) =>
          business._id === action.payload._id ? action.payload : business
        )
      };
      break;
    case BACKOFFICE_BUSINESS_DELETE:
      state = {
        ...state,
        businesses: state.businesses.filter(
          (business: any) => business._id !== action.payload._id
        )
      };
      break;
    default:
      return state;
  }

  return state;
};

export default backofficeReducer;

/* eslint-disable no-undef */
import {
  BUSINESS_CREATE,
  BUSINESS_GET,
  BUSINESS_UPDATE,
  BUSINESS_ADD_USER,
  BUSINESS_DELETE_USER
} from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';
import notify from '../../utils/notify';
import RequestErrorHelper from '../../utils/helper/RequestErrorHelper';
import analytics from '../../utils/function/analytics';
import mapping from '../../utils/function/mapping';
import BusinessRequest from '../repositories/business';
import { Dispatch } from 'react';
import { BasicDispatchParam } from '../../models/dispatchTypes';
import AuthRequests from '../repositories/auth';

export const createBusiness =
  (business: any, cb?: any) => async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('user', 'create-business');

      const payload: any = await BusinessRequest.createBusiness(business);

      dispatch({
        payload,
        type: BUSINESS_CREATE
      });

      if (cb && cb.success) {
        cb.success();
      }

      await mapping.track('Create Business');
    } catch (e) {
      if (e instanceof Error) {
        if (cb && cb.error) {
          cb.error();
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const updateBusiness =
  (business: any, cb?: any) => async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('user', 'update-business');

      const payload: any = await BusinessRequest.updateBusiness(business);

      dispatch({
        payload,
        type: BUSINESS_UPDATE
      });

      if (cb && cb.success) {
        cb.success();
      }

      await mapping.track('Update Business');
    } catch (e) {
      if (e instanceof Error) {
        if (cb && cb.error) {
          cb.error();
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const businessAddUser =
  (business: any, cb?: any) => async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('user', 'add-user');

      const payload: any = await BusinessRequest.businessAddUser(business);

      dispatch({
        payload,
        type: BUSINESS_ADD_USER
      });

      if (cb && cb.success) {
        cb.success();
      }

      await mapping.track('Business Add User');
    } catch (e) {
      if (e instanceof Error) {
        if (cb && cb.error) {
          cb.error();
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const getBusiness =
  (cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('business', 'get-business');

      await mapping.track('Get Business');

      const payload: any = await BusinessRequest.getBusiness();

      dispatch({
        payload,
        type: BUSINESS_GET
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (err) {
      if (err instanceof Error) {
        // console.log(err.message);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const businessDeleteUser =
  (_id: string, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('business', 'delete-user');

      await mapping.track('Delete User');

      await BusinessRequest.businessDeleteUser(_id);

      dispatch({
        payload: { _id },
        type: BUSINESS_DELETE_USER
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (err) {
      if (err instanceof Error) {
        // console.log(err.message);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const businessInviteConfirmation =
  ({ code }: any, cb?: any) =>
  async (dispatch: any) => {
    dispatch(increaseLoading());

    try {
      await analytics.logEvent('business', 'invite-confirmation');

      const payload: any = await BusinessRequest.inviteConfirmation(code);

      if (cb && cb.success) {
        cb.success();
      }

      await notify.success('Convite confirmado com sucesso!');

      await mapping.track('Business Invite Confirmation');
    } catch (e) {
      if (e) {
        if (cb && cb.error) {
          cb.error();
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

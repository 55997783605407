import React, { useContext, useState } from 'react';
import { PromptsContainerInterface } from './interface';
import { Chip, useMediaQuery } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';

const PromptsContainer = ({ onSelectPrompt }: PromptsContainerInterface) => {
  const themeMode = useContext(ThemeContext);
  const { translate }: TranslateInterface = useLanguage();

  /*  const [expanded, setExpanded] = React.useState<string | false>(false); */

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const prompts = [
    translate('page.chat.prompts.options.one'),
    translate('page.chat.prompts.options.two'),
    translate('page.chat.prompts.options.three'),
    translate('page.chat.prompts.options.four'),
    translate('page.chat.prompts.options.five'),
    translate('page.chat.prompts.options.six'),
    translate('page.chat.prompts.options.seven'),
    translate('page.chat.prompts.options.eight')
  ];

  const [expanded, setExpanded] = useState(!isMobile);

  return (
    <div style={{ marginTop: '20px', marginBottom: '10px' }}>
      <Accordion style={{ boxShadow: 'none' }} expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            backgroundColor:
              themeMode.mode === 'light'
                ? '#f3f3f3!important'
                : '#3a3541!important',
            borderRadius: '15px'
          }}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Inter'
            }}
          >
            {translate('page.chat.prompts.title')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ marginTop: '10px', marginBottom: '0px' }}>
          {prompts.map((prompt: string, index: number) => {
            return (
              <Chip
                key={index}
                label={prompt + '...'}
                color="default"
                size="small"
                onClick={() => onSelectPrompt(prompt)}
                clickable
                style={{
                  marginRight: '10px',
                  marginBottom: '15px',
                  borderRadius: '4px',
                  height: '32px'
                }}
              />
            );
          })}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default PromptsContainer;

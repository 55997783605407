import React, { useContext } from 'react';
import { BannerNewsInterface } from './interface';
import { useMediaQuery, useTheme } from '@mui/material';
import { ThemeContext } from '../../../../utils/context/ThemeContext';
import Root from './components/Root';
import useStyles from './style';
import { useReduxState } from '../../../../hooks/useReduxState';
import { STATE_TEMPLATE } from '../../pages/templates/const/STATE_TEMPLATE';
import { TranslateInterface } from '../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../utils/hooks/useLanguage';
import ToolCard from './components/ToolCard';
import { STATE_TOOLS } from './const/STATE_TOOLS';
import { useNavigate } from 'react-router-dom';
import { createNewDocument } from '../../../../stories/actions/documents';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';

const Tools = ({}: BannerNewsInterface) => {
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();
  const classes = useStyles();
  const { translate }: TranslateInterface = useLanguage();

  const categories = {
    all: 'all',
    ads: 'ads',
    blog: 'blog',
    ecommerce: 'ecommerce',
    email: 'email',
    frameworks: 'frameworks',
    google: 'google',
    marketing: 'marketing',
    seo: 'seo',
    socialMedia: 'socialMedia',
    videos: 'videos',
    website: 'website',
    tools: 'tools',
    others: 'others'
  };

  const options = [
    categories.all,
    categories.ads,
    categories.blog,
    categories.ecommerce,
    categories.email,
    categories.frameworks,
    categories.google,
    categories.marketing,
    categories.seo,
    categories.socialMedia,
    categories.videos,
    categories.website,
    categories.tools,
    categories.others
  ];

  const { user } = useReduxState();

  const tools: any =
    STATE_TOOLS(themeMode, categories, translate, user)?.tools || [];

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const onSelected = (url: string) => {
    navigate(url);
  };

  return (
    <Root>
      {tools.map((template, index) => (
        <ToolCard
          key={template.id + index}
          id={template.id}
          title={template.title}
          description={template.description}
          type={template.type}
          onSelect={() => {
            if (template.id === 'review') {
              const cb = {
                success: (_id: any) => {
                  navigate(`/edit-text/${_id}`);
                },
                error: () => {}
              };

              dispatch(createNewDocument(cb));
            }

            if (!template.redirect) {
              return;
            }

            onSelected(template.redirect);
          }}
          imageUrl={template.imageUrl}
          notReady={template.notReady}
          favorite={template.favorite || false}
          onToggleFavorite={() => null}
        />
      ))}
    </Root>
  );
};

export default Tools;

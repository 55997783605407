/* eslint-disable no-undef */
import instance from '../../utils/axios/instance';
import withAuth from '../../utils/axios/withAuth';
import { updateTextualGenre } from '../actions/editor';
import {
  DOCUMENTS_REPLACE_WORD,
  DOCUMENTS_UPDATE_CORRECTION,
  FEEDBACK
} from '../actions/actionTypes';
import waiting from '../../utils/function/waiting';

const EditorApi = {
  postProcessText: async (text: models.Editor) => {
    const { data } = await instance().post(`/brain/check`, {
      text: text.text,
      title: text.title,
      textId: text.textId,
      rawEditorState: {
        blocks: text.rawEditorState?.blocks,
        entityMap: text.rawEditorState?.entityMap
      },
      actionType: text.type || null
    });

    const spelling: any = [];

    data.corrections.spellingAndGrammar.forEach((item: any) => {
      delete item.score;

      spelling.push({
        ...item,
        type: 'correcao',
        spellingAndGrammar: true,
        score: {
          correcao: 2
        }
      });
    });

    data.corrections.deviations = [...data.corrections.deviations, ...spelling];

    data.corrections.spellingAndGrammar = [];

    data.corrections.doubts = [];

    data.corrections.deviations.sort(
      (a: any, b: any) => a.spans[0].offset - b.spans[0].offset
    );

    return data;
  },
  setTitle: async (textId: string, title: string) => {
    const { data } = await withAuth().patch(`/texts/title`, {
      textId,
      title
    });
    return data;
  },
  postSaveText: async (text: models.Editor) => {
    const { data } = await instance().post(`/texts/save`, {
      text: text.text,
      title: text.title,
      textId: text.textId,
      rawEditorState: {
        blocks: text.rawEditorState?.blocks,
        entityMap: text.rawEditorState?.entityMap
      }
    });

    const spelling: any = [];

    data.corrections.spellingAndGrammar.forEach((item: any) => {
      delete item.score;

      spelling.push({
        ...item,
        type: 'correcao',
        spellingAndGrammar: true,
        score: {
          correcao: 2
        }
      });
    });

    data.corrections.deviations = [...data.corrections.deviations, ...spelling];

    data.corrections.spellingAndGrammar = [];

    data.corrections.doubts = [];

    data.corrections.deviations.sort(
      (a: any, b: any) => a.spans[0].offset - b.spans[0].offset
    );

    return data;
  },
  postProcessReadability: async (text: models.Editor) => {
    const { data } = await instance().post(`/brain/check/readability`, {
      text: text.text,
      title: text.title,
      textId: text.textId,
      rawEditorState: {
        blocks: text.rawEditorState?.blocks,
        entityMap: text.rawEditorState?.entityMap
      }
    });
    return data;
  },
  updateTextualGenre: async (text: models.Editor, genre: string) => {
    const { data } = await instance().post(`/brain/genre/update`, {
      textId: text.textId,
      genre: genre
    });

    return data;
  },
  updateTextualLanguage: async (text: models.Editor, language: string) => {
    const { data } = await instance().post(`/brain/language/update`, {
      textId: text.textId,
      language: language
    });

    return data;
  },
  patchAddToDictionary: async (word: string) => {
    const { data } = await instance().patch(`/users/add-to-dictionary`, {
      word: word
    });
    return data;
  },
  putSendFeedback: async (feedback: models.Feedback) => {
    const { data } = await instance().put(`/texts/feedback`, {
      type: feedback.type,
      textId: feedback.textId,
      correctionId: feedback.correctionId,
      message: feedback.message,
      correctionType: feedback.correctionType
    });
    return data;
  },
  putSendFeedbackFixAll: async (feedback: any) => {
    const { data } = await instance().put(`/texts/fix-all/feedback`, {
      type: feedback.type,
      textId: feedback.textId,
      corrections: feedback.corrections,
      message: feedback.message,
      correctionType: feedback.correctionType
    });

    return data;
  }
};
export default EditorApi;

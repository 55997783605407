import { getPlatformVersion } from '../../../../../utils/function/getPlatformVersion';

export const STATE_TOOLS = (
  themeMode: any,
  options?: any,
  translate?: any,
  user?: any
) => {
  const baseURL = `/icons/template/${
    themeMode.mode === 'dark' ? 'dark' : 'light'
  }/`;

  if (!translate) {
    return {
      tools: []
    };
  }

  const platform = getPlatformVersion(user);

  return {
    tools: [
      {
        id: 'review',
        title: translate('page.tools.list.review.title'),
        description: translate('page.tools.list.review.description'),
        type: [options.marketing, options.socialMedia],
        // redirect: '/templates/rewrite-text',
        imageUrl: baseURL + 'writer.svg'
      },
      {
        id: 'rewrite-text',
        title: translate('page.tools.list.rewriteText.title'),
        description: translate('page.tools.list.rewriteText.description'),
        type: [options.marketing, options.socialMedia],
        redirect: '/templates/rewrite-text',
        imageUrl: baseURL + 'content-improver.svg'
      },
      {
        id: 'content-summarizer',
        title: translate('page.tools.list.contentSummarizer.title'),
        description: translate('page.tools.list.contentSummarizer.description'),
        type: [options.tools],
        redirect: '/templates/content-summarizer',
        imageUrl: baseURL + 'pencil.svg'
      },
      {
        id: 'translate',
        title: translate('page.tools.list.translate.title'),
        description: translate('page.tools.list.translate.description'),
        type: [options.tools],
        redirect: '/tools/translate',
        imageUrl: baseURL + 'energy.svg'
      },
      {
        id: 'aiDetect',
        title: translate('page.tools.list.aiDetect.title'),
        description: translate('page.tools.list.aiDetect.description'),
        type: [options.tools],
        redirect: '/tools/ai-detect',
        imageUrl: baseURL + 'target.svg'
      },
      {
        id: 'plagiarism',
        title: translate('page.tools.list.plagiarism.title'),
        description: translate('page.tools.list.plagiarism.description'),
        type: [options.tools],
        // redirect: '/tools/plagiarism',
        imageUrl: baseURL + 'global.svg',
        notReady: true
      }
    ]
  };
};

import React, { useContext, useState } from 'react';
import { ActionInterface } from './interface';
import { Box, Button, useTheme } from '@mui/material';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import useStyles from './style';
import analytics from '../../../../../../utils/function/analytics';
import { GlobalContext } from '../../../../../../utils/context/GlobalContext';
import { useReduxState } from '../../../../../../hooks/useReduxState';
import mapping from '../../../../../../utils/function/mapping';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import { openPortal } from '../../../../../../stories/actions/payment';

const Action = ({}: ActionInterface) => {
  const navigate = useNavigate();

  const ctx = useContext(GlobalContext);

  const classes = useStyles();

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  // @ts-ignore
  const { open, close, toggle, refresh } = window.tf.createPopup('O7gcHAmh');

  const { user }: any = useReduxState();

  const dispatch = useAppDispatch();

  const [trigger, setTrigger] = useState(0);

  const [isLoadingPortal, setIsLoadingPortal] = useState(false);

  const onOpenPortal = (cb: any) => {
    dispatch(openPortal(cb));
  };

  const handleOpenPortal = () => {
    setIsLoadingPortal(true);
    onOpenPortal(() => setIsLoadingPortal(false));
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{
          boxShadow: '0px 21px 27px -10px rgba(96, 60, 255, 0.48)',
          cursor: 'pointer!important',
          fontFamily: 'League Spartan',
          background: 'linear-gradient(59deg, #7C24FB 21.86%, #D19DFB 92.03%)',
          textTransform: 'none',
          padding: '8px 24px 8px 24px',
          gap: '10px',
          borderRadius: '45px',
          height: '55px',
          width: '180px',
          fontSize: '14px',
          fontWeight: '800',
          lineHeight: '16px',
          '&:hover': {
            // backgroundColor: `${theme.palette.secondary.dark}`
          },
          [theme.breakpoints.down('sm')]: {
            // width: '100%'
          },
          color: '#fff'
        }}
        onClick={() => {
          analytics.logEvent('CTA', 'black-friday-secret-view-plans');

          mapping.track('CTA | black-friday-secret-view-plans');

          if (user.isSubscriber) {
            handleOpenPortal();

            return;
          }

          navigate('/plans');
        }}
      >
        {user.isSubscriber ? 'Faça um upgrade' : 'VER PLANO'}
      </Button>
    </>
  );
};

export default Action;

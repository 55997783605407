/* eslint-disable no-undef */
import { Dispatch } from 'react';
import { BasicDispatchParam } from '../../models/dispatchTypes';
import ProjectsRequests from '../repositories/projects';

import {
  PROJECTS_CREATE,
  PROJECTS_ACTIVE,
  PROJECTS_DETAIL,
  PROJECTS_GET_ALL,
  PROJECTS_DELETE,
  PROJECTS_UPDATE
} from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';
import notify from '../../utils/notify';
import RequestErrorHelper from '../../utils/helper/RequestErrorHelper';
import analytics from '../../utils/function/analytics';
import mapping from '../../utils/function/mapping';

export const createProject =
  (title: string, description: string, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('project', 'create');

      await mapping.track('Project Create');

      const payload: models.ChatResponse = await ProjectsRequests.createProject(
        title,
        description
      );

      dispatch({
        payload,
        type: PROJECTS_CREATE
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (e) {
      if (e instanceof Error) {
        if (cb && cb.error) {
          cb.error();
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const updateProject =
  (form: any, cb?: any) =>
  async (
    dispatch: Dispatch<BasicDispatchParam<models.CompletionByTemplateResponse>>
  ) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('project', 'update');

      await mapping.track('Update Project');

      const payload: any = await ProjectsRequests.update(form);

      dispatch({
        payload,
        type: PROJECTS_UPDATE
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error();
        }

        throw new Error(e.response.data.message);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const getProjects =
  () => async (dispatch: Dispatch<BasicDispatchParam<models.Project>>) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('project', 'project-all');

      await mapping.track('Get All Projects');

      const payload: models.Project = await ProjectsRequests.getProjects();

      dispatch({
        payload,
        type: PROJECTS_GET_ALL
      });
    } catch (err) {
      if (err instanceof Error) {
        // console.log(err.message);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const setProjectActive =
  (project: models.Project) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.Project>>) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('project', 'project-active');

      await mapping.track('Set Project Active');

      dispatch({
        payload: project,
        type: PROJECTS_ACTIVE
      });
    } catch (err) {
      if (err instanceof Error) {
        // console.log(err.message);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const clearProjectsDetails = () => (dispatch: any) => {
  dispatch({
    payable: null,
    type: PROJECTS_DETAIL
  });
};

export const deleteProject =
  (id: string, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.Document[]>>) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('project', 'delete');

      await mapping.track('Delete Project');

      const payload = await ProjectsRequests.delete(id);

      dispatch({
        payload,
        type: PROJECTS_DELETE
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (e) {
      if (e instanceof Error) {
        if (cb && cb.error) {
          cb.error(e);
        }

        await RequestErrorHelper(e);

        // notify.error(
        //   'Ocorreu algum erro ao excluir seu projeto, tente novamente.'
        // );
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  root: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',

    [theme.breakpoints.down(600)]: {
      marginBottom: '24px'
    }
  },
  title: {
    color: theme.palette.mode === 'light' ? '#FFF' : '#fff',
    fontFamily: 'League Spartan',
    fontSize: '35px',
    fontWeight: '800',
    fontStyle: 'normal',
    lineHeight: '100%',

    flex: 'none',
    order: 0,
    flexGrow: 0,
    marginBottom: '0.5rem',
    [theme.breakpoints.down(600)]: {
      textAlign: 'center',
      width: '100%',
      fontSize: '19px'
    }
  },
  description: {
    textAlign: 'center',
    color: theme.palette.mode === 'light' ? '#D7FF32' : '#fff',
    fontFamily: 'Inter',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '140%',
    width: '30rem',
    marginBlockStart: '0',
    marginBlockEnd: '0',
    [theme.breakpoints.down(600)]: {
      textAlign: 'center',
      width: '100%',
      fontSize: '14px'
    }
  }
}));

export default useStyles;

import React, { useContext } from 'react';
import { BannerNewsInterface } from './interface';
import { useMediaQuery, useTheme } from '@mui/material';
import { ThemeContext } from '../../../../utils/context/ThemeContext';
import Root from './components/Root';
import Logo from './components/Logo';
import Info from './components/Info';
import Action from './components/Action';
import useStyles from './style';
import { useReduxState } from '../../../../hooks/useReduxState';

const BannerBlackFriday = ({}: BannerNewsInterface) => {
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();
  const classes = useStyles();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const { user }: any = useReduxState();

  return (
    <Root>
      <Logo />

      <Info />

      <Action />
    </Root>
  );
};

export default BannerBlackFriday;

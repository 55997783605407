import { TemplateActionsInterface } from './interface';
import useStyles from './style';
import IconButton from '@mui/material/IconButton';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import GradeIcon from '@mui/icons-material/Grade';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import DescriptionIcon from '@mui/icons-material/Description';
import React, { useContext } from 'react';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';

const TemplateActions = ({
  output,
  onSelectAction
}: TemplateActionsInterface) => {
  const classes: any = useStyles();

  const themeMode = useContext(ThemeContext);

  const { translate }: TranslateInterface = useLanguage();

  const theme = useTheme();

  const actions = [
    // {
    //   type: 'hide',
    //   icon:
    //     output.status && output.status.hide ? (
    //       <VisibilityOffIcon fontSize="inherit" />
    //     ) : (
    //       <VisibilityIcon fontSize="inherit" />
    //     ),
    //   tooltip:
    //     output.status && output.status.hide
    //       ? 'Mostrar em Clarice.ai'
    //       : 'Ocultar em Clarice.ai',
    //   // active: false
    //   loading: false
    // },
    {
      type: 'favorite',
      icon:
        output.status && output.status.favorite ? (
          <GradeIcon />
        ) : (
          <StarOutlineIcon />
        ),
      tooltip:
        output.status && output.status.favorite
          ? translate('page.templates.action.unfavorite')
          : translate('page.templates.action.favorite'),
      active: output.status && output.status.favorite,
      loading: false
    },
    {
      type: 'copy',
      icon: <ContentCopyIcon fontSize="inherit" />,
      tooltip: translate('page.templates.action.copy.tooltip'),
      // active: false,
      loading: false
    },
    {
      type: 'open-document',
      // icon: <DescriptionIcon fontSize="inherit" />,
      button: (onClick: any) => (
        <LoadingButton
          variant="contained"
          onClick={onClick}
          style={{
            boxShadow: 'unset',
            width: '106px',
            padding: '8px 16px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            borderRadius: '4px',
            textTransform: 'none',
            fontFamily: 'Inter',
            marginLeft: '8px',
            backgroundColor:
              themeMode.mode === 'light'
                ? `#09B286`
                : `${theme.palette.secondary.main}`,
            color:
              themeMode.mode === 'light'
                ? `#FFF`
                : `${theme.palette.text.black}`,
            textAlign: 'center',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '100%'
          }}
        >
          {translate('page.templates.action.review')}
        </LoadingButton>
      ),
      // tooltip: 'Abrir em um documento ',
      // active: false,
      loading: false
    }
    // {
    //   type: 'like',
    //   icon: <ThumbUpOffAltIcon fontSize="inherit" />,
    //   tooltip: 'Boa resposta!',
    //   active: output.status && output.status.like,
    //   loading: false
    // },
    // {
    //   type: 'dislike',
    //   icon: <ThumbDownOffAltIcon fontSize="inherit" />,
    //   tooltip: 'Mensagem ruim!',
    //   active: output.status && output.status.dislike,
    //   loading: false
    // }
  ];

  const color = themeMode.mode === 'light' ? 'primary' : 'secondary';

  return (
    <div className={classes.root}>
      {actions.map((action: any, index: number) => {
        if (action.button) {
          return action.button(() =>
            !action.loading &&
            (action.type !== 'favorite' ? !action.active : true)
              ? onSelectAction(output._id, 'output', action.type, output)
              : null
          );
        }

        return (
          <Tooltip title={action.tooltip} key={index}>
            <IconButton
              size="small"
              color={action.active ? color : 'default'}
              className={classes.button}
              onClick={() =>
                !action.loading &&
                (action.type !== 'favorite' ? !action.active : true)
                  ? onSelectAction(output._id, 'output', action.type, output)
                  : null
              }
            >
              {action.icon}
            </IconButton>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default TemplateActions;

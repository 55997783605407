import React, { useState } from 'react';
import useStyles from './style';
import ContainerAuth from '../../components/ContainerAuth';
import LeftContainerFlow from '../../components/LeftContainerFlow';
import HeadAuth from '../../components/HeadAuth';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useReduxState } from '../../../../hooks/useReduxState';
import {
  forgotPassword,
  mailConfirmation
} from '../../../../stories/actions/auth';
import { useNavigate } from 'react-router-dom';
import notify from '../../../../utils/notify';
import { TranslateInterface } from '../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../utils/hooks/useLanguage';

type Props = {};

const ForgotPassword = (props: Props) => {
  const classes = useStyles();

  const { translate }: TranslateInterface = useLanguage();

  const [email, setEmail] = useState('');
  const [captcha, setCaptcha] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { auth } = useReduxState();

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (email === '') {
      return notify.error(
        translate('page.forgotPassword.notify.required.email')
      );
    }

    if (!loading && captcha) {
      setLoading(true);

      const cb = {
        success: () => {
          navigate(`/sign-in`);

          setEmail('');
          setCaptcha(false);
          setLoading(false);
        },
        error: () => {
          setLoading(false);
        }
      };

      dispatch(forgotPassword({ email }, cb));
    }
  };

  return (
    <>
      <div className={classes.root}>
        <HeadAuth
          title={'Clarice.ai | ' + translate('page.forgotPassword.title')}
          description={translate('page.forgotPassword.description')}
        />
        <ContainerAuth>
          <LeftContainerFlow
            description={translate(
              'page.forgotPassword.leftContainerFlow.description'
            )}
          />

          <ForgotPasswordForm
            email={email}
            setEmail={setEmail}
            captcha={captcha}
            setCaptcha={setCaptcha}
            loading={loading}
            onSubmit={onSubmit}
          />
        </ContainerAuth>
      </div>
    </>
  );
};

export default ForgotPassword;

import React, { useContext, useEffect, useState } from 'react';
import { Box, TextField, Button, useTheme } from '@mui/material';
import { ThemeContext } from '../../../../../../../utils/context/ThemeContext';
import { GlobalContext } from '../../../../../../../utils/context/GlobalContext';
import { useAppDispatch } from '../../../../../../../hooks/useAppDispatch';
import LoadingButton from '@mui/lab/LoadingButton';
import { updateProject } from '../../../../../../../stories/actions/projects';
import ModalCustom from '../../../../../components/Modal/ModalCustom';
import useStyles from './style';
import { useReduxState } from '../../../../../../../hooks/useReduxState';
import { TranslateInterface } from '../../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../../utils/hooks/useLanguage';
import notify from '../../../../../../../utils/notify';

type Props = {
  id: string;
  open: boolean;
  toggle: (id: string) => void;
};

const ModalUpdateProject = ({ id, open, toggle }: Props) => {
  const classes = useStyles();

  const { translate }: TranslateInterface = useLanguage();

  const themeMode = useContext(ThemeContext);

  const theme = useTheme();

  const ctx = useContext(GlobalContext);

  const [form, setForm] = useState<any>({
    _id: null,
    title: '',
    description: ''
  });

  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    setForm({ ...form, [type]: e.target.value });
  };

  const onClose = async () => {
    setForm({ _id: null, title: '', description: '' });

    setLoading(false);

    toggle('');
  };

  const onSubmit = async (e: any) => {
    if (e) e.preventDefault();

    setLoading(true);

    const cb = {
      success: () => {
        setLoading(false);

        setForm({ _id: null, title: '', description: '' });

        notify.success(translate('notify.project.update.success'));

        toggle('');
      },
      error: () => {
        setLoading(false);
      }
    };

    dispatch(updateProject(form, cb));
  };

  const { projects } = useReduxState();

  const { allProjects } = projects;

  useEffect(() => {
    if (!id) {
      setForm({ title: '', description: '' });
    }

    if (id) {
      const project = allProjects?.find((project: any) => project._id === id);

      if (project && !loading) {
        setForm({
          _id: project._id,
          title: project.title,
          description: project.description
        });
      }
    }
  }, [id]);

  return (
    <ModalCustom open={open} toggle={onClose} containerClass={classes.root}>
      <form onSubmit={onSubmit} noValidate autoComplete="off">
        <Box
          sx={{
            // width: 400,
            backgroundColor:
              themeMode.mode === 'light'
                ? `${theme.palette.background.paper}`
                : `${theme.palette.background.default}`,
            borderRadius: '15px',
            padding: '0rem 1rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem'
          }}
        >
          <div>
            <h2 id="parent-modal-title">
              {translate('page.project.modal.update.title')}
            </h2>
            <p id="parent-modal-description">
              {translate('page.project.modal.update.description')}
            </p>
          </div>
          <TextField
            id="outlined-basic"
            label={translate('page.project.modal.update.form.name.label')}
            variant="outlined"
            color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
            value={form.title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
              handleChange(e, 'title')
            }
          />
          <TextField
            id="outlined-multiline-static"
            label={translate(
              'page.project.modal.update.form.description.label'
            )}
            multiline
            rows={4}
            color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
            value={form.description}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
              handleChange(e, 'description')
            }
          />
          <div
            style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}
          >
            <Button
              onClick={onClose}
              variant="outlined"
              color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
              sx={{
                textTransform: 'none!important',
                fontFamily: 'Inter'
              }}
            >
              {translate('page.project.modal.update.action.cancel')}
            </Button>
            <LoadingButton
              loading={loading}
              onClick={onSubmit}
              variant="contained"
              style={{
                backgroundColor:
                  themeMode.mode === 'light'
                    ? `${theme.palette.primary.main}`
                    : `${theme.palette.secondary.main}`,
                color: loading
                  ? themeMode.mode === 'light'
                    ? `${theme.palette.primary.main}`
                    : `${theme.palette.secondary.main}`
                  : themeMode.mode === 'light'
                  ? `${theme.palette.text.white}`
                  : `${theme.palette.text.black}`
              }}
              sx={{
                textTransform: 'none!important',
                fontFamily: 'Inter'
              }}
            >
              {translate('page.project.modal.update.action.confirm')}
            </LoadingButton>
          </div>
        </Box>
      </form>
    </ModalCustom>
  );
};

export default ModalUpdateProject;

const formatDate: any = (date?: any, language?: string, type = 'onlyDate') => {
  if (!date) {
    return '';
  }

  const newDate = date instanceof Date ? date : new Date(date);

  if (language === 'pt') {
    return type === 'onlyDate'
      ? newDate.toLocaleDateString('pt-BR')
      : newDate.toLocaleString('pt-BR');
  }

  if (language === 'en') {
    return type === 'onlyDate'
      ? newDate.toLocaleDateString('en-US')
      : newDate.toLocaleString('en-US');
  }

  if (language === 'es') {
    return type === 'onlyDate'
      ? newDate.toLocaleDateString('es-ES')
      : newDate.toLocaleString('es-ES');
  }

  return type === 'onlyDate'
    ? newDate.toLocaleDateString('pt-BR')
    : newDate.toLocaleString('pt-BR');
};

export default formatDate;

import React, { useContext, useState } from 'react';
import useStyle from './style';
import { ThemeContext } from '../../../../../../../../../../utils/context/ThemeContext';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { GlobalContext } from '../../../../../../../../../../utils/context/GlobalContext';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import TooltipContainer from '../../../../../../../../components/TooltipContainer';
import { isBetaUser } from '../../../../../../../../../../utils/function/isBetaUser';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    border: '0.5px solid #ff0000ad',
    textAlign: 'left',
    backgroundColor:
      theme.palette.mode === 'light' ? '#ff0000ad!important' : 'white',
    color: theme.palette.mode === 'light' ? '#fce4e4' : '#9b1b1b8a',
    minWidth: '25px',
    height: '25px',
    borderRadius: '14px',
    cursor: 'pointer'
  }
}));

const DismissAllContainer = ({
  correction,
  corrections,
  onDismissAll,
  textId,
  category
}: any) => {
  const themeMode = useContext(ThemeContext);

  const theme = useTheme();

  const classes = useStyle();

  const ctx = useContext(GlobalContext);

  const isMobile = useMediaQuery(() => theme.breakpoints.down('md'));

  const [open, setOpen] = useState(false);

  // console.log('FIX ALL CONTAINER');

  const handleMouseEnter = () => {
    setOpen(false);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  const response = corrections.filter(el => {
    if (
      [
        'ESPACO_DUPLO',
        'WHITESPACE_RULE',
        'COMMA_PARENTHESIS_WHITESPACE',
        'SPACE_BEFORE_PUNCTUATION2'
      ].includes(correction?.rule?.id) &&
      !el.hide &&
      el?.suggestions?.length <= 1
    ) {
      return true;
    }

    return (
      el?.rule?.id === correction?.rule?.id &&
      !el.hide &&
      correction?.spans[0]?.content === el?.spans[0]?.content
      // el?.suggestions?.length <= 1
    );
  });

  const quantity: number = response.length;

  if (quantity <= 1) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        flexDirection={'row'}
        textAlign={'center'}
        mt={'25px'}
        mb={'10px'}
        style={{ width: '100%' }}
      >
        <StyledBadge
          // badgeContent={quantity}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          badgeContent={
            <>
              <TooltipContainer
                title={'Dispensar todos os itens do mesmo tipo'}
                placement="right"
              >
                <span
                  style={{
                    cursor: 'auto'
                  }}
                >
                  {quantity}
                </span>
              </TooltipContainer>
            </>
          }
        >
          <div
            className={classes.button}
            style={{
              textTransform: 'unset',
              fontSize: '13.5px',
              letterSpacing: '0.12px'
            }}
            onClick={(e: any) =>
              onDismissAll(textId, 'disagree', category, response)
            }
          >
            <CloseIcon
              color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
              className={classes.icon}
              fontSize="small"
            />
            Dispensar todos
          </div>
        </StyledBadge>
      </Box>
    </div>
  );
};

export default DismissAllContainer;

import React, { useContext } from 'react';
import { ActionInterface } from './interface';
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { ThemeContext } from '../../../../../../../utils/context/ThemeContext';
import useStyles from './style';
import { GlobalContext } from '../../../../../../../utils/context/GlobalContext';
import { TranslateInterface } from '../../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../../utils/hooks/useLanguage';

const Title = ({}: ActionInterface) => {
  const ctx = useContext(GlobalContext);

  const classes = useStyles();

  const { translate }: TranslateInterface = useLanguage();

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return (
    <Box style={{ width: '100%' }}>
      <Typography
        style={{
          fontFamily: 'Inter',
          fontSize: isMobile ? '22px' : '21px',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: 'normal',
          color:
            themeMode.mode === 'dark' ? theme.palette.text.primary : '#39274B',
          marginBottom: '20px'
        }}
      >
        {translate('modal.acceptedTerms.title')}
      </Typography>
    </Box>
  );
};

export default Title;

import React from 'react';
import { LeftContainerFlowInterface } from './interface';
import useStyles from './style';
import FeaturedImage from '../../../../assets/images/auth/featured-image-flow.png';
import IconData from '../../../../assets/images/auth/icon-data.svg';
import { TranslateInterface } from '../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../utils/hooks/useLanguage';

const LeftContainerFlow = ({ description }: LeftContainerFlowInterface) => {
  const classes: any = useStyles();

  const { translate }: TranslateInterface = useLanguage();

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.image}>
          <img src={FeaturedImage} alt="" />
        </div>
        <div className={classes.text}>
          <img src={IconData} alt="" />
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default LeftContainerFlow;

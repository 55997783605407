import React, { useContext, useEffect, useMemo, useState } from 'react';
import useStyles from './style';
import { Box, Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../../../../utils/context/ThemeContext';
import DocumentCard from '../../documents/components/DocumentsCard';
import SearchBar from './components/SearchBar';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import { useReduxState } from '../../../../../hooks/useReduxState';
import { getAllDocument } from '../../../../../stories/actions/documents';
import BrandNotFound from './components/BrandNotFound';
import BrandsCard from './components/BrandsCard';
import { getBrands } from '../../../../../stories/actions/brands';
import { TranslateInterface } from '../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../utils/hooks/useLanguage';
const ListBrand = () => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();

  const { translate }: TranslateInterface = useLanguage();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const params = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { brands, user } = useReduxState();

  useEffect(() => {
    if (!brands.loaded) {
      const cb = {
        success: () => {
          // setLoaded(true);
        },
        error: () => {
          // setLoaded(false);
        }
      };

      dispatch(getBrands(cb));
    }
  }, [brands.loaded]);

  const [search, setSearch] = useState('');

  const [filteredByProject, setFilteredByProject] = useState<any>([]);

  const { options }: any = user;

  useEffect(() => {
    const filtered = brands.all;

    // const filtered = brands.all?.filter((brand: any) => {
    //   if (options?.project?._id && brand?.project?._id) {
    //     return String(brand.project._id) === String(options.project._id);
    //   }
    //
    //   return false;
    // });

    if (options && options?.project?._id) {
      setFilteredByProject(filtered);
    }
  }, [options, brands]);

  const brandsFilter = useMemo(() => {
    const lowerSearch = search.toLowerCase();

    return filteredByProject.filter(brand => {
      return brand.name.toLowerCase().includes(lowerSearch);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, brands]);

  return (
    <div className={classes.root}>
      {!params.type && (
        <>
          <Box>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'transparent',
                flexDirection: 'column',
                width: '100%'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  // alignItems: 'center',
                  // justifyContent: 'center',
                  width: '949px',
                  // height: '148px',
                  // padding: '10px 20px',
                  gap: '2rem',
                  [theme.breakpoints.down(1250)]: {
                    width: '700px',
                    gap: '0.5rem'
                  },
                  [theme.breakpoints.down(1000)]: {
                    height: 'max-content',
                    width: '100%',
                    flexDirection: 'column',
                    gap: '0.5rem'
                  }
                }}
              >
                <p className={classes.title}>
                  {translate('page.brand.list.title')}
                </p>
              </Box>
              <SearchBar value={search} setValue={setSearch}></SearchBar>
              <Box className={classes.templates}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={10}>
                  <Grid
                    container
                    spacing={{ xs: 0, sm: 0, md: 2, lg: 2, xl: 2 }}
                  >
                    {search !== '' && brandsFilter.length === 0 && (
                      <>
                        <BrandNotFound>
                          {translate('page.brand.list.notFound')}
                        </BrandNotFound>
                      </>
                    )}

                    {search === '' && filteredByProject.length === 0 && (
                      <>
                        <BrandNotFound>
                          {brands.loaded
                            ? translate('page.brand.list.notAdded')
                            : translate('page.brand.list.loading')}
                        </BrandNotFound>
                      </>
                    )}
                  </Grid>
                </Grid>

                <Box
                  sx={{
                    display: 'grid',
                    gap: '1.5rem',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    [theme.breakpoints.down(1250)]: {
                      gridTemplateColumns: '1fr 1fr'
                    },
                    [theme.breakpoints.down(900)]: {
                      gridTemplateColumns: '1fr',
                      gap: '0'
                    }
                  }}
                >
                  {search !== '' &&
                    brandsFilter.length > 0 &&
                    brandsFilter?.map(brand => (
                      <BrandsCard
                        key={brand._id}
                        id={brand._id}
                        name={brand.name}
                        image={brand?.image || ''}
                        shared={brand?.shared}
                        description={brand.description}
                        date={brand.updatedAt || ''}
                        onSelect={() => {}}
                      />
                    ))}

                  {search === '' &&
                    filteredByProject?.length > 0 &&
                    filteredByProject?.map(brand => (
                      <BrandsCard
                        key={brand._id}
                        id={brand._id}
                        name={brand.name}
                        image={brand?.image || ''}
                        shared={brand?.shared}
                        description={brand.description}
                        date={brand.updatedAt || ''}
                        onSelect={() => {}}
                      />
                    ))}
                </Box>
              </Box>
            </div>
          </Box>
        </>
      )}
    </div>
  );
};

export default ListBrand;

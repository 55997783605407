import React, { useContext, useEffect, useMemo, useState } from 'react';
import { DropdownSynonymsInterface } from './interface';
import useStyles from './style';
import { useTheme } from '@mui/material';
import { ThemeContext } from '../../../../../../../../utils/context/ThemeContext';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import withAuth from '../../../../../../../../utils/axios/withAuth';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { useAppDispatch } from '../../../../../../../../hooks/useAppDispatch';
import { getSynonyms } from '../../../../../../../../stories/actions/user';

const DropdownSynonyms = ({
  synonyms,
  setSynonyms,
  synonymsRef,
  toggleSynonyms,
  onReplaceForSynonyms
}: DropdownSynonymsInterface): any => {
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();
  const classes = useStyles(themeMode);

  const [loading, setLoading] = useState(false);

  const [options, setOptions] = useState([]);

  const [word, setWord] = useState(null);

  const baseURL = `/icons/template/${
    themeMode.mode === 'dark' ? 'dark' : 'light'
  }/`;

  const dispatch = useAppDispatch();

  const position: any = () => {
    const co = synonyms.position;

    if (!co) return {};
    return {
      top: co.y,
      left: co.x,
      position: 'absolute',
      marginTop: '1.65em',
      zIndex: 999999
    };
  };

  const { sentence } = synonyms;

  useEffect(() => {
    if (
      synonyms.word &&
      synonyms.word !== '' &&
      synonyms.word !== word &&
      synonyms?.open &&
      !loading
    ) {
      setLoading(true);

      setWord(synonyms.word);

      const cb = {
        success: terms => {
          const replacement: any = [];

          if (terms && terms.length > 0) {
            terms.map(term => {
              const formatted: any = formattedReplacement(synonyms.word, term);

              replacement.push(formatted);

              return term;
            });
          }

          setOptions(replacement);

          setTimeout(() => {
            setLoading(false);
          }, 200);
        },
        error: () => {
          setOptions([]);

          setLoading(false);

          setSynonyms({ open: false });
        }
      };

      dispatch(getSynonyms(synonyms.word, sentence, cb));
    }
  }, [synonyms.word]);

  const formattedReplacement = (word, replacement) => {
    const letter = word.substring(0, 1);

    // TODAS AS LETRAS MAIÚSCULAS
    if (word === word.toUpperCase()) {
      replacement = replacement.toUpperCase();
    }

    // TODAS AS LETRAS MINÚSCULAS
    if (word === word.toLowerCase()) {
      replacement = replacement.toLowerCase();
    }

    // PRIMEIRA LETRA MAIÚSCULA
    if (letter === letter.toUpperCase()) {
      replacement =
        replacement.substring(0, 1).toUpperCase() +
        replacement.substring(1, replacement.length);
    }

    // PRIMEIRA LETRA MINÚSCULA
    if (letter === letter.toLowerCase()) {
      replacement = replacement.toLowerCase();
    }

    return replacement;
  };

  const toggle = () => {
    setOptions([]);

    setLoading(false);

    setSynonyms({ open: false });
  };

  if (!synonyms?.open) {
    return null;
  }

  return (
    <div className={classes.container} ref={synonymsRef} style={position()}>
      {loading && (
        <div className={classes.list} key="ia-writing">
          <img className={classes.icon} src={baseURL + 'logo.svg'} alt="" />

          <div className={classes.descriptionLoading}>
            Buscando sinônimos...
          </div>
        </div>
      )}

      {!loading && options.length === 0 && (
        <div className={classes.list} key="ia-writing">
          <img className={classes.icon} src={baseURL + 'logo.svg'} alt="" />

          <div className={classes.descriptionLoading}>
            Nenhum sinônimo encontrado...
          </div>
        </div>
      )}

      {!loading &&
        options.length > 0 &&
        options.map((option: any, index: number) => (
          <div
            style={{ cursor: 'pointer' }}
            className={classes.list}
            key={index}
            onClick={() => {
              if (loading) {
                return;
              }

              onReplaceForSynonyms(synonyms.word, synonyms.range, option);

              // toggle();
            }}
          >
            {/*<img*/}
            {/*  className={classes.icon}*/}
            {/*  src={baseURL + 'synonym.svg'}*/}
            {/*  alt=""*/}
            {/*/>*/}

            <div className={classes.description}>{option}</div>
          </div>
        ))}
    </div>
  );
};

export default DropdownSynonyms;

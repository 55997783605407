import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  List,
  ListItem,
  ListItemText,
  useTheme
} from '@mui/material';
import { AddRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import useStyles from './style';
import { useReduxState } from '../../../../../../../../hooks/useReduxState';
import { GlobalContext } from '../../../../../../../../utils/context/GlobalContext';
import { ThemeContext } from '../../../../../../../../utils/context/ThemeContext';
import { isBetaUser } from '../../../../../../../../utils/function/isBetaUser';
import { TranslateInterface } from '../../../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../../../utils/hooks/useLanguage';
import { getPlatformVersion } from '../../../../../../../../utils/function/getPlatformVersion';

type ModalProjectsProps = {
  activeProject?: any;
  setActiveProject: (_id: string) => void;
  modalVisible: boolean;
  setModalVisible: (_id: boolean) => void;
  toggle?: any;
};

const ModalProjects = ({
  activeProject,
  setActiveProject,
  modalVisible,
  setModalVisible,
  toggle
}: ModalProjectsProps) => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();
  const { translate }: TranslateInterface = useLanguage();

  const navigate = useNavigate();

  const ctx = useContext(GlobalContext);

  const { projects } = useReduxState();

  const { allProjects } = projects;

  const allProjectsActive = useMemo(() => {
    return allProjects.filter(project => !project?.status?.inactive);
  }, [projects]);

  const preventDefault = (event: React.SyntheticEvent) => {
    event.preventDefault();

    setModalVisible(!modalVisible);

    toggle();

    navigate(`/projects`);
  };

  const listRef: any = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setTimeout(() => {
          setModalVisible(false);
        }, 200);
      }
    }

    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [listRef]);

  const { user }: any = useReduxState();

  const topCard = () => {
    if (
      ![
        'Assinatura Mensal | Profissional',
        'Assinatura Anual | Profissional',
        'Assinatura Mensal | Business',
        'Assinatura Anual | Business'
      ].includes(user?.options?.plan?.type) &&
      isBetaUser(user, 'brand') &&
      getPlatformVersion(user) === 'standard'
    ) {
      return '-178px';
    }

    if (
      ![
        'Assinatura Mensal | Profissional',
        'Assinatura Anual | Profissional',
        'Assinatura Mensal | Business',
        'Assinatura Anual | Business'
      ].includes(user?.options?.plan?.type) &&
      !isBetaUser(user, 'brand') &&
      getPlatformVersion(user) === 'standard'
    ) {
      return '-178px';
    }

    if (
      [
        'Assinatura Mensal | Profissional',
        'Assinatura Anual | Profissional',
        'Assinatura Mensal | Business',
        'Assinatura Anual | Business'
      ].includes(user?.options?.plan?.type) &&
      isBetaUser(user, 'brand') &&
      getPlatformVersion(user) === 'standard'
    ) {
      return '-178px';
    }

    if (
      [
        'Assinatura Mensal | Profissional',
        'Assinatura Anual | Profissional',
        'Assinatura Mensal | Business',
        'Assinatura Anual | Business'
      ].includes(user?.options?.plan?.type) &&
      !isBetaUser(user, 'brand') &&
      getPlatformVersion(user) === 'standard'
    ) {
      return '-178px';
    }

    return '-217px';
  };

  return (
    <>
      <Card
        className={classes.root}
        onClick={() => setModalVisible(!modalVisible)}
        ref={listRef}
        sx={{
          fontFamily: 'Inter',
          backgroundColor: themeMode.mode === 'light' ? `#fff` : '#252525',
          boxShadow: 'none',
          top: topCard() || '-217px'
        }}
      >
        <List className={classes.list} subheader={<li />}>
          {allProjectsActive.map((project: any) => (
            <ul key={project._id}>
              <ListItem
                className={
                  String(activeProject) === String(project._id)
                    ? classes.listItemActive
                    : classes.listItem
                }
                style={{
                  cursor: 'pointer',
                  color: themeMode.mode === 'light' ? '#303030' : '#fff'
                }}
                key={`item-${project._id}`}
                onClick={() => setActiveProject(project._id)}
              >
                <p
                  style={{
                    fontFamily: 'Inter!important',
                    margin: '0',
                    fontSize: '15px',
                    fontWeight: '500'
                  }}
                >
                  {project.title}
                </p>
              </ListItem>
            </ul>
          ))}
        </List>
        <Box
          className={classes.listItem}
          onClick={preventDefault}
          style={{
            height: '3rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
            padding: '0 1rem',
            borderTop: `1px solid ${theme.palette.background.darkGrey}`
          }}
        >
          <h2 className={classes.title}>
            {translate('sidebar.projects.action.view')}
          </h2>
          <KeyboardArrowRightRounded
            sx={{
              color: themeMode.mode === 'light' ? '#303030' : '#fff'
            }}
          />
        </Box>
        <Box
          className={classes.listItem}
          onClick={() => {
            ctx.modal.project.create.toggle();
            toggle();
          }}
          style={{
            height: '3rem',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '0.5rem',
            cursor: 'pointer',
            padding: '0 1rem',
            borderTop: `1px solid ${theme.palette.background.darkGrey}`
          }}
        >
          <AddRounded
            sx={{
              color: themeMode.mode === 'light' ? '#303030' : '#fff'
            }}
          />
          <h2 className={classes.title}>
            {translate('sidebar.projects.action.new')}
          </h2>
        </Box>
      </Card>
    </>
  );
};

export default ModalProjects;

import React, { useContext, useEffect } from 'react';
import useStyles from './style';
import clsx from 'clsx';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';
import TooltipContainer from '../../../../components/TooltipContainer';
import {
  updateTextualGenre,
  updateTextualLanguage
} from '../../../../../../stories/actions/editor';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import { useReduxState } from '../../../../../../hooks/useReduxState';

const DropdownLanguage = props => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();
  const { translate }: TranslateInterface = useLanguage();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [selectedLanguage, setSelectedLanguage] = React.useState('pt-br');
  const dispatch = useAppDispatch();
  const { editor } = useReduxState();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const baseURL = `/icons/template/${
    themeMode.mode === 'dark' ? 'dark' : 'light'
  }/flag/`;

  const options = [
    {
      id: 'pt-br',
      name: translate('page.review.language.options.portuguese'),
      icon: baseURL + 'br.svg'
    },
    {
      id: 'en-us',
      name: translate('page.review.language.options.english'),
      icon: baseURL + 'us.svg'
    },
    {
      id: 'es-es',
      name: translate('page.review.language.options.spanish'),
      icon: baseURL + 'es.svg'
    }
  ];

  const handleLanguage = (language: string) => {
    const cb = {
      success: () => {},
      error: () => {}
    };

    setSelectedLanguage(language);

    dispatch(updateTextualLanguage(editor.text, language, cb));
  };

  const formatted: any =
    options.find(option => option.id === selectedLanguage) || options[0];

  useEffect(() => {
    const selected = editor?.text?.language || 'pt-br';

    setSelectedLanguage(selected);
  }, [editor.text]);

  return (
    <div>
      <TooltipContainer
        title={translate('page.review.language.title')}
        small={true}
      >
        <Button
          // variant="outlined"
          id="fade-button"
          aria-controls={open ? 'fade-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{
            marginRight: '20px',
            textTransform: 'none',
            minWidth: '140px',
            borderRadius: '5px!important'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingTop: '2px',
              paddingBottom: '2px'
            }}
          >
            <img
              src={formatted.icon}
              alt={formatted.id}
              style={{ width: '28px', borderRadius: '2px' }}
            ></img>
            <Typography
              variant={'body1'}
              style={{
                color:
                  themeMode.mode === 'light'
                    ? `${theme.palette.text.black}`
                    : `${theme.palette.text.white}`,
                marginLeft: '7px',
                marginBottom: '3px'
              }}
            >
              {formatted.name}
            </Typography>
          </Box>
        </Button>
      </TooltipContainer>

      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {options.map(option => (
          <MenuItem
            key={option.id}
            onClick={() => {
              handleLanguage(option.id);
              handleClose();
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <img
                src={option.icon}
                alt={option.id}
                style={{ width: '24px', borderRadius: '2px' }}
              ></img>
              <Typography
                variant={'body1'}
                style={{
                  color:
                    themeMode.mode === 'light'
                      ? `${theme.palette.text.black}`
                      : `${theme.palette.text.white}`,
                  marginLeft: '7px'
                }}
              >
                {option.name}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default DropdownLanguage;

import React from 'react';
import { HeadFormInterface } from './interface';
import useStyles from './style';
import { Link } from 'react-router-dom';
import Logo from '../../../../assets/images/auth/logo.svg';
import IconUser from '../../../../assets/images/auth/icon-user-profile.svg';
import ArrowBack from '../../../../assets/images/auth/arrow-back.svg';
import { TranslateInterface } from '../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../utils/hooks/useLanguage';

const HeadForm = ({
  title,
  textButton,
  routeButton,
  hasButtonBack = false
}: HeadFormInterface) => {
  const classes: any = useStyles();

  const { translate }: TranslateInterface = useLanguage();

  return (
    <div className={classes.root}>
      <img src={Logo} alt="" />
      <div>
        {hasButtonBack ? (
          <Link to="/" className={classes.buttonBack}>
            <img src={ArrowBack} alt="" /> {translate('standard.button.back')}
          </Link>
        ) : (
          routeButton && (
            <>
              <img src={IconUser} alt="" />
              <div>
                <p>
                  {title} <Link to={routeButton}>{textButton}</Link>
                </p>
              </div>
            </>
          )
        )}
      </div>
    </div>
  );
};

export default HeadForm;

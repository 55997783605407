/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAppDispatch } from '../../../../../../../hooks/useAppDispatch';
import useStyles from './style';
import { ThemeContext } from '../../../../../../../utils/context/ThemeContext';
import notify from '../../../../../../../utils/notify';
import { useNavigate } from 'react-router-dom';
import TooltipContainer from '../../../../../components/TooltipContainer';
import CloseIcon from '@mui/icons-material/Close';
import IMGTemplate from '../../../../../../../assets/images/ilustra-template.jpg';
import { ReactComponent as MagicSVGLight } from '../../../../../../../assets/images/template/light/magic.svg';
import { ReactComponent as MagicSVGDark } from '../../../../../../../assets/images/template/dark/magic.svg';
import { GenerateWithAIInterface } from './interface';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import toast from 'react-hot-toast';
import {
  createBrand,
  generateWithIA
} from '../../../../../../../stories/actions/brands';
import { TranslateInterface } from '../../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../../utils/hooks/useLanguage';

const GenerateWithAI = ({
  form,
  setForm,
  setGenerateWithAI
}: GenerateWithAIInterface) => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();

  const navigate = useNavigate();

  const { translate }: TranslateInterface = useLanguage();

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const [description, setDescription] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setDescription(e.target.value);
  };

  const handleSubmit = () => {
    if (!description || description === '') {
      toast.error(
        translate('notify.brand.generateWithIA.required.description')
      );
      return;
    }

    setLoading(true);

    const cb = {
      success: (data: any) => {
        setForm({
          ...form,
          name: data.name || form.name,
          description: data.description || form.description,
          audience: data.audience || form.audience,
          tone: data.tone || form.tone,
          personality: data.personality || form.personality,
          missionVisionAndValue:
            data.missionVisionAndValue || form.missionVisionAndValue,
          keywords: data.keywords || form.keywords,
          preferredWordsAndPhrases:
            data.preferredWordsAndPhrases || form.preferredWordsAndPhrases,
          wordsAndPhrasesToAvoid:
            data.wordsAndPhrasesToAvoid || form.wordsAndPhrasesToAvoid
        });

        setLoading(false);

        setDescription('');

        setGenerateWithAI(false);
      },
      error: () => {
        setLoading(false);
      }
    };

    return toast.promise(dispatch(generateWithIA(description, cb)), {
      loading: translate('notify.brand.generateWithIA.loading'),
      success: data => {
        setLoading(false);

        return <b>{translate('notify.brand.generateWithIA.success')}</b>;
      },
      error: err => {
        setLoading(false);

        if (err.message) {
          return <b>{err.message}</b>;
        }

        return <b>{translate('notify.brand.generateWithIA.error')}</b>;
      }
    });
  };

  return (
    <>
      <div className={classes.generateWithAIContainer}>
        <Box
          style={{
            width: '100%',
            justifyContent: 'right',
            alignItems: 'right',
            display: 'flex'
          }}
        >
          <TooltipContainer title={'Fechar'} placement="left">
            <CloseIcon
              style={{ cursor: 'pointer' }}
              color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
              onClick={(e: any): void => {
                setGenerateWithAI(false);
              }}
            />
          </TooltipContainer>
        </Box>

        <div className={classes.generateWithAIHeader}>
          <div
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              display: 'flex'
            }}
          >
            <Box display={'flex'} justifyContent={'center'} mt={'10.5px'}>
              {themeMode.mode === 'light' && (
                <>
                  <img
                    src={IMGTemplate}
                    alt="Person"
                    style={{
                      marginTop: '30px',
                      marginBottom: '30px',
                      width: '35%'
                      // height: '340px'
                    }}
                  />
                  {/*<MagicSVGLight*/}
                  {/*    width={'80%'}*/}
                  {/*    height={340}*/}
                  {/*    style={{ marginTop: '40px' }}*/}
                  {/*/>*/}
                </>
              )}

              {themeMode.mode === 'dark' && (
                <>
                  <img
                    src={IMGTemplate}
                    alt="Person"
                    style={{
                      marginTop: '30px',
                      marginBottom: '30px',
                      width: '35%'
                      // height: '340px'
                    }}
                  />
                  {/*<MagicSVGDark*/}
                  {/*    width={'80%'}*/}
                  {/*    height={340}*/}
                  {/*    style={{ marginTop: '40px' }}*/}
                  {/*/>*/}
                </>
              )}
            </Box>

            <Typography
              style={{
                fontSize: '1.2em',
                fontWeight: '700',
                marginTop: '-20px',
                marginBottom: '15px',
                textAlign: 'center'
              }}
            >
              {translate('page.brand.generateWithIA.title')}
            </Typography>

            <Typography
              style={{
                fontSize: '0.975em',
                color: 'rgb(113, 128, 150)',
                textAlign: 'center',
                marginBottom: '5px'
              }}
            >
              {translate('page.brand.generateWithIA.description')}
            </Typography>

            <TextField
              className={classes.input}
              id="outlined-basic"
              label={translate(
                'page.brand.generateWithIA.form.description.label'
              )}
              placeholder={translate(
                'page.brand.generateWithIA.form.description.placeholder'
              )}
              multiline={true}
              rows={5}
              variant="outlined"
              value={description}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                handleChange(e)
              }
              color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
            />

            <LoadingButton
              style={{
                marginLeft: '0px',
                backgroundColor:
                  themeMode.mode === 'light'
                    ? `${theme.palette.primary.main}`
                    : `${theme.palette.secondary.main}`,
                color: loading
                  ? themeMode.mode === 'light'
                    ? `${theme.palette.primary.main}`
                    : `${theme.palette.secondary.main}`
                  : themeMode.mode === 'light'
                  ? `${theme.palette.text.white}`
                  : `${theme.palette.text.black}`
              }}
              loading={loading}
              variant="outlined"
              onClick={handleSubmit}
              startIcon={<AutoFixHighIcon />}
              sx={{
                textTransform: 'none!important',
                fontFamily: ['Inter'].join(',')
              }}
            >
              {translate('page.brand.generateWithIA.action.generate')}
            </LoadingButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default GenerateWithAI;

import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  root: {
    width: '100%'
  },
  title: {
    fontFamily: 'Inter',
    fontSize: '1.875rem',
    lineHeight: '2.25rem',
    fontWeight: '700'
  }
}));

export default Styles;

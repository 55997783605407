import React, { useContext, useState } from 'react';
import { Box, TextField, Modal, Button, useTheme } from '@mui/material';
import { ThemeContext } from '../../../../../utils/context/ThemeContext';
import { GlobalContext } from '../../../../../utils/context/GlobalContext';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import LoadingButton from '@mui/lab/LoadingButton';
import { createProject } from '../../../../../stories/actions/projects';
import { sendSuggestion } from '../../../../../stories/actions/user';
import ModalCustom from '../ModalCustom';
import useStyles from './style';
import notify from '../../../../../utils/notify';
import { TranslateInterface } from '../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../utils/hooks/useLanguage';

type ModalSuggestionProps = {};

const ModalSuggestion = ({}: ModalSuggestionProps) => {
  const classes = useStyles();

  const { translate }: TranslateInterface = useLanguage();

  const themeMode = useContext(ThemeContext);

  const theme = useTheme();

  const ctx = useContext(GlobalContext);

  const [form, setForm] = useState<any>({ subject: '', description: '' });

  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    setForm({ ...form, [type]: e.target.value });
  };

  const onClose = async () => {
    setForm({ subject: '', description: '' });

    setLoading(false);

    ctx.modal.suggestion.toggle();
  };

  const onSubmit = async (e: any) => {
    if (e) e.preventDefault();

    if (form.subject === '' || form.description === '') {
      return notify.error(translate('notify.suggestion.required.all'));
    }

    setLoading(true);

    const cb = {
      success: () => {
        setLoading(false);

        notify.success(translate('notify.suggestion.success'));

        setForm({ subject: '', description: '' });

        ctx.modal.suggestion.toggle();
      },
      error: () => {
        setLoading(false);
      }
    };

    dispatch(sendSuggestion(form.subject, form.description, cb));
  };

  return (
    <ModalCustom
      open={ctx.modal.suggestion.open}
      toggle={onClose}
      containerClass={classes.root}
    >
      <form onSubmit={onSubmit} noValidate autoComplete="off">
        <Box
          sx={{
            // width: 400,
            backgroundColor:
              themeMode.mode === 'light'
                ? `${theme.palette.background.paper}`
                : `${theme.palette.background.default}`,
            borderRadius: '15px',
            padding: '0rem 1rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem'
          }}
        >
          <div>
            <h2 id="parent-modal-title">
              {translate('modal.suggestion.title')}
            </h2>
            <p id="parent-modal-description">
              {translate('modal.suggestion.description')}
            </p>
          </div>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2rem'
            }}
          >
            <TextField
              id="outlined-basic"
              label={translate('modal.suggestion.form.subject.label')}
              variant="outlined"
              color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
              value={form.subject}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                handleChange(e, 'subject')
              }
              sx={{
                fontFamily: 'Inter'
              }}
            />
            <TextField
              id="outlined-multiline-static"
              label={translate('modal.suggestion.form.description.label')}
              multiline
              rows={4}
              color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
              value={form.description}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                handleChange(e, 'description')
              }
              sx={{
                fontFamily: 'Inter!important'
              }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '1rem'
              }}
            >
              <Button
                onClick={onClose}
                variant="outlined"
                color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
                sx={{
                  textTransform: 'none!important',
                  fontFamily: 'Inter'
                }}
              >
                {translate('modal.suggestion.action.cancel')}
              </Button>
              <LoadingButton
                loading={loading}
                onClick={onSubmit}
                variant="contained"
                style={{
                  backgroundColor:
                    themeMode.mode === 'light'
                      ? `${theme.palette.primary.main}`
                      : `${theme.palette.secondary.main}`,
                  color: loading
                    ? themeMode.mode === 'light'
                      ? `${theme.palette.primary.main}`
                      : `${theme.palette.secondary.main}`
                    : themeMode.mode === 'light'
                    ? `${theme.palette.text.white}`
                    : `${theme.palette.text.black}`
                }}
                sx={{
                  textTransform: 'none!important',
                  fontFamily: 'Inter'
                }}
              >
                {translate('modal.suggestion.action.send')}
              </LoadingButton>
            </div>
          </Box>
        </Box>
      </form>
    </ModalCustom>
  );
};

export default ModalSuggestion;

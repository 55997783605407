import React, { useContext, useState } from 'react';
import { Box, Modal, Button, useTheme } from '@mui/material';
import { ThemeContext } from '../../../../../utils/context/ThemeContext';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import LoadingButton from '@mui/lab/LoadingButton';
import { deleteBrand } from '../../../../../stories/actions/brands';
import { TranslateInterface } from '../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../utils/hooks/useLanguage';
import notify from '../../../../../utils/notify';

type ModalDeleteDocumentProps = {
  idBrand?: any;
  open?: any;
  onClose?: any;
};

const ModalDeleteBrand = ({
  idBrand,
  open,
  onClose
}: ModalDeleteDocumentProps) => {
  const themeMode = useContext(ThemeContext);

  const { translate }: TranslateInterface = useLanguage();

  const theme = useTheme();

  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const onSubmit = async (e: any) => {
    if (e) e.preventDefault();

    setLoading(true);

    const cb = {
      success: () => {
        // setLoading(false);

        setTimeout(() => setLoading(false), 1000 * 2);

        notify.success(translate('notify.brand.delete.success'));

        onClose();
      },
      error: () => {
        setLoading(false);

        onClose();
      }
    };

    dispatch(deleteBrand(idBrand, cb));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backdropFilter: 'blur(3px)'
      }}
    >
      <form onSubmit={onSubmit} noValidate autoComplete="off">
        <Box
          sx={{
            width: 400,
            backgroundColor:
              themeMode.mode === 'light'
                ? `${theme.palette.background.paper}`
                : `${theme.palette.background.default}`,
            borderRadius: '15px',
            padding: '1rem 2rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem'
          }}
        >
          <div>
            <h2 id="parent-modal-title">
              {translate('page.brand.modal.delete.title')}
            </h2>
            <p id="parent-modal-description">
              {translate('page.brand.modal.delete.description')}
            </p>
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}
          >
            <Button
              onClick={onClose}
              variant="outlined"
              color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
              sx={{
                textTransform: 'none!important',
                fontFamily: 'Inter'
              }}
            >
              {translate('page.brand.modal.delete.action.cancel')}
            </Button>
            <LoadingButton
              loading={loading}
              onClick={onSubmit}
              variant="contained"
              style={{
                backgroundColor:
                  themeMode.mode === 'light'
                    ? `${theme.palette.primary.main}`
                    : `${theme.palette.secondary.main}`,
                color: loading
                  ? themeMode.mode === 'light'
                    ? `${theme.palette.primary.main}`
                    : `${theme.palette.secondary.main}`
                  : themeMode.mode === 'light'
                  ? `${theme.palette.text.white}`
                  : `${theme.palette.text.black}`
              }}
              sx={{
                textTransform: 'none!important',
                fontFamily: 'Inter'
              }}
            >
              {translate('page.brand.modal.delete.action.confirm')}
            </LoadingButton>
          </div>
        </Box>
      </form>
    </Modal>
  );
};

export default ModalDeleteBrand;

import React, { useContext, useEffect, useState } from 'react';
import { DropdownSuggestionInterface } from './interface';
import useStyles from './style';
import { useTheme } from '@mui/material';
import { ThemeContext } from '../../../../../../../../utils/context/ThemeContext';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import {
  completionByEditor,
  completionByEditorSelector
} from '../../../../../../../../stories/actions/templates';
import { useAppDispatch } from '../../../../../../../../hooks/useAppDispatch';
import Button from '@mui/material/Button';
import { getCredits } from '../../../../../../../../stories/actions/credits';
import { GlobalContext } from '../../../../../../../../utils/context/GlobalContext';
import { TranslateInterface } from '../../../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../../../utils/hooks/useLanguage';

const DropdownSuggestion = ({
  suggestion,
  setSuggestion,
  // options,
  suggestionsRef,
  onInsertForSuggestion
}: DropdownSuggestionInterface): any => {
  const ctx = useContext(GlobalContext);
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const classes = useStyles(themeMode);
  const { translate }: TranslateInterface = useLanguage();

  const values = {
    'blog-post': translate('shortcut.blogPost.option'),
    'create-paragraph': translate('shortcut.createParagraph.option'),
    'brainstorm-ideas': translate('shortcut.brainstorm.option')
  };

  const [option, setOption] = useState('');

  const [text, setText] = useState('');

  const [newGenerate, setNewGenerate] = useState(false);

  const [loading, setLoading] = useState(false);

  const [outputs, setOutputs] = useState([]);

  const baseURL = `/icons/template/${
    themeMode.mode === 'dark' ? 'dark' : 'light'
  }/`;

  const options = [
    {
      id: 'ia-writer',
      description: translate('shortcut.writer.description'),
      icon: <img className={classes.icon} src={baseURL + 'writer.svg'} alt="" />
    },
    {
      id: 'insert-text',
      description: translate('shortcut.complete.description'),
      icon: (
        <img className={classes.icon} src={baseURL + 'complete.svg'} alt="" />
      )
    },
    {
      id: 'create-paragraph',
      description: translate('shortcut.createParagraph.description'),
      icon: <img className={classes.icon} src={baseURL + 'create.svg'} alt="" />
    },
    {
      id: 'brainstorm-ideas',
      description: translate('shortcut.brainstorm.description'),
      icon: (
        <img className={classes.icon} src={baseURL + 'generate.svg'} alt="" />
      )
    }
  ];

  const handleSelectOption = async option => {
    setOption(option.id);

    if (
      ['ia-writer', 'create-paragraph', 'brainstorm-ideas'].includes(
        option.id
      ) &&
      text === ''
    ) {
      setText(values[option.id]);

      return;
    }

    setOutputs([]);

    setLoading(true);

    const cb = {
      success: (outputs: any) => {
        setOutputs(outputs);

        setLoading(false);

        setNewGenerate(false);

        dispatch(getCredits());
      },
      error: (err: any) => {
        if (
          err?.response?.data?.message === 'Você atingiu o limite de créditos!'
        ) {
          if (!ctx.modal.conversion.open) {
            ctx.modal.conversion.toggle();
          }
        }

        setText('');

        setLoading(false);

        setNewGenerate(false);

        setSuggestion({ open: false });
      }
    };

    if (option.id === 'insert-text') {
      await dispatch(
        completionByEditor(
          {
            template: 'command-' + option.id,
            snippet: suggestion.snippet,
            quantity: 2
          },
          cb
        )
      );
    } else {
      await dispatch(
        completionByEditor(
          { template: 'command-' + option.id, command: text, quantity: 2 },
          cb
        )
      );
    }
  };

  const position: any = () => {
    const co = suggestion.position;

    if (!co) return {};
    return {
      top: co.y,
      left: co.x,
      position: 'absolute',
      marginTop: '1.65em',
      zIndex: 999999
    };
  };

  if (suggestion?.position?.x === 0 && suggestion?.position?.y === 0) {
    return null;
  }

  const toggle = () => {
    setOutputs([]);

    setLoading(false);

    setSuggestion({ open: false });
  };

  const descriptionLoading = {
    'ia-writer': translate('shortcut.writer.loading'),
    'insert-text': translate('shortcut.complete.loading'),
    'create-paragraph': translate('shortcut.createParagraph.loading'),
    'brainstorm-ideas': translate('shortcut.brainstorm.loading')
  };

  if (!suggestion?.open) {
    return null;
  }

  return (
    <div
      className={
        !loading &&
        ['ia-writer', 'create-paragraph', 'brainstorm-ideas'].includes(
          option
        ) &&
        outputs.length === 0
          ? classes.containerInput
          : classes.container
      }
      ref={suggestionsRef}
      style={position()}
    >
      {loading && (
        <div className={classes.list} key="ia-writing">
          <img className={classes.icon} src={baseURL + 'logo.svg'} alt="" />

          <div className={classes.descriptionLoading}>
            {newGenerate
              ? translate('shortcut.newGenerate.loading')
              : (option && descriptionLoading[option]) ||
                translate('shortcut.writer.loading')}
          </div>
        </div>
      )}

      {!loading &&
        ['ia-writer', 'create-paragraph', 'brainstorm-ideas'].includes(
          option
        ) &&
        outputs.length === 0 && (
          <Paper
            component="form"
            action=""
            onSubmit={() => {
              const newOption = options.find(item => item.id === option);

              handleSelectOption(newOption);
            }}
            sx={{
              // p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              width: '346px',
              boxShadow:
                'rgb(255 251 251 / 5%) 0px 0px 0px 1px, rgb(255 251 251 / 5%) 0px 1px 0px, rgb(15 15 15 / 20%) 0px 1px 14px',
              padding: '8px',
              gap: '8px',
              borderRadius: '4px',
              backgroundColor:
                themeMode.mode === 'light' ? 'white' : '#3a3541!important'
            }}
          >
            <IconButton
              aria-label="menu"
              sx={{
                paddingRight: '0px'
              }}
            >
              <img
                className={classes.iconInput}
                src={baseURL + 'input.svg'}
                alt=""
              />
            </IconButton>
            <InputBase
              sx={{
                ml: 0,
                flex: 1,
                fontFamily: 'Inter',
                color: themeMode.mode === 'light' ? '#655679' : 'white',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '148%'
              }}
              placeholder={translate('shortcut.fields.text.placeholder')}
              inputProps={{
                'aria-label': translate('shortcut.fields.text.label')
              }}
              onChange={e => setText(e.target.value)}
              value={text}
            />
            {/*<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />*/}
            <IconButton
              // color="primary"
              // sx={{ p: '10px' }}
              aria-label="directions"
              disabled={text === ''}
              type="submit"
            >
              <img
                className={classes.iconInput}
                src={baseURL + 'send.svg'}
                alt=""
              />
            </IconButton>
          </Paper>
          // </div>
        )}

      {!loading &&
        outputs.length === 0 &&
        option === '' &&
        options.length > 0 &&
        options.map(option => (
          <div
            className={classes.list}
            key={option.id}
            onClick={() => handleSelectOption(option)}
          >
            {option.icon}

            <div className={classes.description}>{option.description}</div>
          </div>
        ))}

      {!loading &&
        outputs.length > 0 &&
        outputs.map((output: any) => (
          <div className={classes.output} key={output._id}>
            <div
              className={classes.resultsContainerWait}
              onClick={() => {
                if (loading) {
                  return;
                }

                onInsertForSuggestion(suggestion.range, output.message);

                // toggle();
              }}
            >
              <img
                className={classes.icon}
                style={{ marginLeft: '10px', marginTop: '15px' }}
                src={baseURL + 'logo.svg'}
                alt=""
              />

              <div className={classes.messageContainer}>
                {output.message.split('\n').map((el: any, index: number) => {
                  return (
                    <p key={index} className={classes.descriptionResult}>
                      {el}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        ))}

      {!loading && outputs.length > 0 && (
        <div className={classes.output}>
          <div
            style={{
              textAlign: 'right',
              justifyContent: 'right'
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                if (loading) {
                  return;
                }

                const newOption = options.find(item => item.id === option);

                setNewGenerate(true);

                handleSelectOption(newOption);
              }}
              size="small"
              style={{
                marginLeft: '0px',
                borderColor:
                  themeMode.mode === 'light'
                    ? `#7C24FB`
                    : `${theme.palette.secondary.main}`,
                color:
                  themeMode.mode === 'light'
                    ? `#7C24FB`
                    : `${theme.palette.secondary.main}`
              }}
              sx={{
                textTransform: 'none!important',
                fontFamily: ['Inter'].join(','),
                padding: '4px 16px',
                gap: '10px',
                borderRadius: '4px'
              }}
            >
              {translate('shortcut.newGenerate.action')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownSuggestion;

import React, { useContext, useState } from 'react';

import clsx from 'clsx';

import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Collapse,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import {
  ArrowForward,
  FiberManualRecord,
  KeyboardArrowDown,
  KeyboardArrowUp
} from '@mui/icons-material';
import useStyles from './style';
import { useReduxState } from '../../../../../../../../hooks/useReduxState';
import { useAppDispatch } from '../../../../../../../../hooks/useAppDispatch';
import {
  sendFeedback,
  addToDictionary,
  setActiveCorrectionItem,
  setActiveCorrectionItemClosed
} from '../../../../../../../../stories/actions/editor';
import { ThemeContext } from '../../../../../../../../utils/context/ThemeContext';
import MoreAction from '../MoreAction';
import { disableRule } from '../../../../../../../../stories/actions/user';
import Citation from '../Citation';
import DOMPurify from 'dompurify';
import './styles.css';
import ReplaceWordContainer from './component/ReplaceWordContainer';
import { GlobalContext } from '../../../../../../../../utils/context/GlobalContext';
import useAutoCorrection from '../../../../../../../../hooks/useAutoCorrection';
import AutoCorrectionContainer from './component/AutoCorrectionContainer';
import { isBetaUser } from '../../../../../../../../utils/function/isBetaUser';
import CloseIcon from '@mui/icons-material/Close';
import TooltipContainer from '../../../../../../components/TooltipContainer';
import { sendFeedbackCorrections } from '../../../../../../../../stories/actions/corrections';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import FixAllContainer from './component/FixAllContainer';
import DiffMatchPatch from 'diff-match-patch';
import DismissAllContainer from './component/DismissAllContainer';
import { TranslateInterface } from '../../../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../../../utils/hooks/useLanguage';

type CorrectionItemProps = {
  _id?: any;
  correction?: any;
  key?: any;
  title?: any;
  message?: any;
  issue?: any;
  sugestion?: any;
  more?: any;
  category: any;
  citation?: any;
  onReplaceWord?: any;
  onFixAll?: any;
  onDismissAll?: any;
  onReplaceAutoCorrection?: any;
  getContentForAutoCorrection?: any;
  corrections?: any;
};

const makeDMPCustomTitle = (from, to, semantic = true) => {
  const dmp = new DiffMatchPatch();

  const wordsFrom = from.split(' ');

  if (typeof to !== 'string') {
    to = to[0];
  }

  const wordsTo = to.split(' ');

  const { chars1, chars2, lineArray } = dmp.diff_linesToChars_(
    wordsFrom.join('\n'),
    wordsTo.join('\n')
  );

  const diffs = dmp.diff_main(chars1, chars2);

  if (semantic) dmp.diff_cleanupSemantic(diffs);

  dmp.diff_charsToLines_(diffs, lineArray);

  const newDiffs = diffs.map(([op, data]) => {
    const words = data.split('\n').join(' '); // Converte de volta para palavras
    const text = words
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');

    if (op === DiffMatchPatch.DIFF_INSERT) {
      return { type: 'insert', text };
    } else if (op === DiffMatchPatch.DIFF_DELETE) {
      return { type: 'delete', text };
    } else {
      return { type: 'equal', text };
    }
  });

  return newDiffs;
};

const CorrectionItem = ({
  _id,
  correction,
  key,
  title,
  message,
  issue,
  sugestion,
  more,
  category,
  citation,
  onReplaceWord,
  onFixAll,
  onDismissAll,
  onReplaceAutoCorrection,
  getContentForAutoCorrection,
  corrections
}: CorrectionItemProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const themeMode = useContext(ThemeContext);
  const { translate }: TranslateInterface = useLanguage();

  const ctx = useContext(GlobalContext);

  const isMobile = useMediaQuery(() => theme.breakpoints.down('md'));

  const { editor, user } = useReduxState();
  const [openTips, setOpenTips] = useState(false);
  const [replaceWordList, setReplaceWordList] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [isExpanded, setIsExpanded] = React.useState(false);

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isExpanded = editor.activeItem === _id;

  const dispatch = useAppDispatch();

  const onNextCorrection = (feedbackId: string, closed = false) => {
    const index = corrections.findIndex(item => item._id === feedbackId);

    const process = closed
      ? (_id: any) => setActiveCorrectionItemClosed(_id)
      : (_id: any) => setActiveCorrectionItem(_id);

    if (index !== -1) {
      const nextItem = corrections[index + 1];

      if (nextItem) {
        dispatch(process(nextItem._id));
      } else {
        const correction = corrections[0];

        if (correction) {
          dispatch(process(correction._id));
        }
      }
    } else {
      const correction = corrections[0];

      if (correction) {
        dispatch(process(correction._id));
      }
    }
  };

  const onSendFeedback = (
    textId,
    feedbackId,
    type,
    category,
    closed = false
  ) => {
    dispatch(
      sendFeedback({
        textId,
        correctionId: feedbackId,
        message: '',
        type,
        correctionType: category
      })
    );

    onNextCorrection(feedbackId, closed);
  };

  const onSendFeedbackCorrections = fields => {
    dispatch(sendFeedbackCorrections(fields));
  };

  const onDisableRule = (id: string, cb) => {
    dispatch(disableRule(id, cb));
  };

  const onAddToDictionary = (textId, correction, type, category) => {
    dispatch(addToDictionary(correction.spans[0].content));

    dispatch(
      sendFeedback({
        textId,
        correctionId: correction._id,
        message: '',
        type,
        correctionType: category
      })
    );

    onNextCorrection(correction._id);
  };

  const onSetActiveCorrectionItem = item => {
    dispatch(setActiveCorrectionItem(item));
  };

  const autoCorrection = useAutoCorrection(
    correction,
    getContentForAutoCorrection
  );

  const replacement =
    typeof correction.replacement === 'string'
      ? [correction.replacement]
      : correction.replacement;

  const isViewCorrectButton = !!replacement;

  const isViewCorrectionAutomatic =
    [
      'USO_PALAVRAS_REPETIDAS',
      'SUBSTANTIVOS_RASTEJANTES',
      'USO_FRASE_CLICHE',
      'GLUE_WORDS',
      'ADVERBIOS_MENTE',
      'FRASE_LONGA',
      'VAGUEZA',
      'USO_CADA_VEZ_MAIS',
      'USO_PLEONASMO',
      'USO_FACE_VAR',
      'USO_AO_PASSO_QUE',
      'USO_POSTO_QUE',
      'USO_COMO_SENDO',
      'USO_ISSO_PORQUE',
      'USO_PALAVRAS_SENSIVEIS',
      'USO_PARA_PODER',
      'USO_GROSSO_MODO',
      'USO_UM_DOS_MAIS',
      'USO_FRENTE_VAR',
      'USO_ALEM_VAR',
      'USO_AFIM_VAR',
      'USO_PREZAR_VAR',
      'USO_MEDIANTE_VAR',
      'DOIS_SLOTS',
      'USO_EXPRESSAO_PROLIXA'
    ].includes(correction.id) ||
    (isBetaUser(user) && ['NONE'].includes(correction.id));

  const makeCustomTitle = (title, content, replacement) => {
    const isOpenAI = correction?.id?.includes('OPEN'); // || correction?.id?.includes('VERBOSE');

    if (isOpenAI && replacement) {
      const diffs = makeDMPCustomTitle(content, replacement);

      const inserts = diffs.filter(diff => diff.type === 'insert');
      const deletes = diffs.filter(diff => diff.type === 'delete');
      const equals = diffs.filter(diff => diff.type === 'equal');

      if (deletes.length === 1) {
        return (
          <Typography
            style={{
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: '400',
              color:
                themeMode.mode === 'light'
                  ? theme.palette.text.dark
                  : theme.palette.text.white
            }}
            dangerouslySetInnerHTML={{
              __html: deletes[0].text
            }}
          />
        );
      }

      if (deletes.length >= 1) {
        return (
          <Typography
            style={{
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: '400',
              color:
                themeMode.mode === 'light'
                  ? theme.palette.text.dark
                  : theme.palette.text.white
            }}
            dangerouslySetInnerHTML={{
              __html: deletes[0].text
            }}
          />
        );
      }

      if (inserts.length === 1) {
        return (
          <Typography
            style={{
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: '400',
              color:
                themeMode.mode === 'light'
                  ? theme.palette.text.dark
                  : theme.palette.text.white
            }}
            dangerouslySetInnerHTML={{
              __html: inserts[0].text
            }}
          />
        );
      }

      if (inserts.length >= 1) {
        return (
          <Typography
            style={{
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: '400',
              color:
                themeMode.mode === 'light'
                  ? theme.palette.text.dark
                  : theme.palette.text.white
            }}
            dangerouslySetInnerHTML={{
              __html: inserts[0].text
            }}
          />
        );
      }

      return (
        <Typography
          style={{
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: '500',
            color:
              themeMode.mode === 'light'
                ? theme.palette.text.dark
                : theme.palette.text.white
          }}
          dangerouslySetInnerHTML={{
            __html: title
          }}
        />
      );
    }

    return (
      <Typography
        style={{
          fontFamily: 'Inter',
          fontSize: '14px',
          fontWeight: '500',
          color:
            themeMode.mode === 'light'
              ? theme.palette.text.dark
              : theme.palette.text.white
        }}
        dangerouslySetInnerHTML={{
          __html: title
        }}
      />
    );
  };

  const customTitle: any = makeCustomTitle(
    title && title !== '' ? title : message,
    correction.spans[0].content,
    replacement
  );

  return (
    <Accordion
      elevation={0}
      className={clsx(classes.root, `ITEM_${_id}`, {
        'active-item-card': isExpanded
      })}
      style={{
        transition: 'all 0.8s ease-in-out',
        boxShadow:
          themeMode.mode === 'light'
            ? '0px 18px 56px -12px rgba(227, 227, 238, 0.8)'
            : '0px 18px 56px -12px rgba(227, 227, 238, 0.123)'
      }}
      expanded={isExpanded}
    >
      <AccordionSummary
        style={{
          width: '25rem',
          maxWidth: '25rem',
          padding: '0 18px',
          minHeight: '50px',
          // height: '50px',
          marginBottom: '10px!important'
        }}
        onClick={() => {
          if (!isExpanded) {
            onSetActiveCorrectionItem(_id);
          }
        }}
      >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          flexDirection={'row'}
          width={'100%'}
        >
          <Box display={'flex'}>
            <FiberManualRecord
              style={{
                fontSize: '12px',
                padding: 0,
                margin: 0,
                marginRight: '18px',
                marginTop: '5px',
                color: theme.palette.highlights[category](1)
              }}
            />
            {customTitle}
          </Box>

          {/*{!isExpanded && (*/}
          {/*  <Box*/}
          {/*    display={'flex'}*/}
          {/*    justifyContent={'center'}*/}
          {/*    alignItems={'center'}*/}
          {/*  >*/}
          {/*    <TooltipContainer*/}
          {/*      title={'Dispensar'}*/}
          {/*      small={true}*/}
          {/*      // placement="right"*/}
          {/*    >*/}
          {/*      <CloseIcon*/}
          {/*        className={classes.closeIcon}*/}
          {/*        onClick={() => {*/}
          {/*          onSendFeedback(*/}
          {/*            editor.text?.textId,*/}
          {/*            _id,*/}
          {/*            'disagree',*/}
          {/*            correction?.spellingAndGrammar*/}
          {/*              ? 'spellingAndGrammar'*/}
          {/*              : editor.activeCategory,*/}
          {/*            true*/}
          {/*          );*/}
          {/*        }}*/}
          {/*      />*/}
          {/*    </TooltipContainer>*/}
          {/*  </Box>*/}
          {/*)}*/}
        </Box>
      </AccordionSummary>
      <AccordionDetails
        style={{
          transition: 'all 0.8s ease-in-out',
          paddingTop: 0,
          paddingLeft: '22px',
          paddingRight: '22px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
          alignItems:
            editor.activeCategory === 'spellingAndGrammar' ||
            (editor.activeCategory === 'deviations' && !correction.custom)
              ? 'center'
              : 'flex-start'
        }}
      >
        <Typography
          style={{
            fontFamily: 'Inter',
            fontSize: '13.5px',
            fontWeight: '400',
            textAlign: 'left',
            width: '100%',
            color:
              themeMode.mode === 'light'
                ? theme.palette.text.secondary
                : theme.palette.text.lightInfo,
            letterSpacing: '0.12px'
          }}
          dangerouslySetInnerHTML={{ __html: message }}
        />

        {['deviations', 'doubts'].includes(editor.activeCategory || '') && (
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            flexDirection={'row'}
            textAlign={'center'}
            mt={'10px'}
            style={{ width: '100%' }}
          >
            <ReplaceWordContainer
              textId={editor.text?.textId}
              correction={correction}
              category={editor.activeCategory}
              replaceWord={(
                textId,
                feedbackId,
                type,
                message,
                correctionType,
                word
              ) => {
                onReplaceWord(
                  editor.text?.textId,
                  correction,
                  'agree',
                  correction?.spellingAndGrammar
                    ? 'spellingAndGrammar'
                    : editor.activeCategory,
                  word
                );

                onNextCorrection(correction._id);

                if (isMobile) {
                  ctx.modal.corrections.toggle();
                }
              }}
            />
          </Box>
        )}

        {editor.activeCategory &&
          ['deviations', 'doubts'].includes(editor.activeCategory) &&
          citation && (
            <Citation text={citation.text} author={citation.author} />
          )}

        {editor.activeCategory &&
          ['deviations', 'doubts'].includes(editor.activeCategory) &&
          more && (
            <Box
              mt="10px"
              display={'flex'}
              alignItems={'center'}
              gap={'0.5rem'}
              onClick={() => {
                setOpenTips(!openTips);
              }}
              style={{ cursor: 'pointer' }}
            >
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontSize: '13.5px',
                  letterSpacing: '0.12px',
                  color:
                    themeMode.mode === 'light'
                      ? theme.palette.primary.main
                      : theme.palette.secondary.main
                }}
              >
                Ver mais dicas
              </Typography>
              {openTips ? (
                <KeyboardArrowUp
                  color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
                ></KeyboardArrowUp>
              ) : (
                <KeyboardArrowDown
                  color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
                ></KeyboardArrowDown>
              )}
            </Box>
          )}

        <Collapse
          in={openTips}
          sx={{
            padding: '1rem!important',
            paddingTop: '0.8rem!important',
            paddingBottom: '0.2rem!important',
            letterSpacing: '0.20px!important'
          }}
        >
          <Typography
            style={{
              fontFamily: 'Inter',
              fontSize: '13.5px',
              letterSpacing: '0.20px!important',
              color: theme.palette.text.primary
            }}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(more) }}
          />
        </Collapse>

        {editor.activeCategory &&
          ['deviations', 'doubts'].includes(editor.activeCategory) &&
          correction.custom &&
          more && (
            <Box
              mt="0px"
              mr="10px"
              width="100%"
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              flexDirection={'row'}
              textAlign={'center'}
            >
              <AutoCorrectionContainer
                onReplaceAutoCorrection={onReplaceAutoCorrection}
                onSendFeedback={() => {
                  onSendFeedback(
                    editor.text?.textId,
                    _id,
                    'agree',
                    editor.activeCategory
                  );
                }}
                onSendFeedbackCorrections={onSendFeedbackCorrections}
                correction={correction}
                editor={editor}
                {...autoCorrection}
              />
            </Box>
          )}

        {(editor.activeCategory === 'spellingAndGrammar' ||
          editor.activeCategory === 'deviations') &&
          !correction.custom &&
          isExpanded && (
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              flexDirection={'row'}
              textAlign={'center'}
              mt={'10px'}
              mb={'10px'}
              style={{ width: '100%' }}
            >
              {!['WHITESPACE_RULE', 'ESPACO_DUPLO'].includes(
                correction.rule?.id
              ) &&
                sugestion && (
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    flexDirection={'row'}
                    textAlign={'center'}
                    style={{ width: '100%' }}
                  >
                    {sugestion?.length > 0 && (
                      <div className={classes.incorrectSentenceContainer}>
                        <Typography
                          style={{
                            width: 'auto',
                            maxWidth: '180px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            fontFamily: 'Inter',
                            fontSize: '13.5px',
                            letterSpacing: '0.12px',
                            color: theme.palette.error.main
                          }}
                          dangerouslySetInnerHTML={{
                            __html: correction.id
                              ? correction.spans[0].content
                              : issue
                          }}
                        />
                      </div>
                    )}

                    {sugestion?.length > 0 && (
                      <ArrowForward
                        color={
                          themeMode.mode === 'light' ? 'primary' : 'secondary'
                        }
                        className={classes.arrowIcon}
                        fontSize="small"
                        style={{ marginLeft: '10px', marginRight: '10px' }}
                      />
                    )}

                    {sugestion?.length <= 0 ? (
                      <div
                        className={classes.correctSentenceContainer}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          onReplaceWord(
                            editor.text?.textId,
                            correction,
                            'agree',
                            correction?.spellingAndGrammar
                              ? 'spellingAndGrammar'
                              : editor.activeCategory,
                            ''
                          );

                          onNextCorrection(correction._id);

                          if (isMobile) {
                            ctx.modal.corrections.toggle();
                          }
                        }}
                      >
                        <Typography
                          style={{
                            width: 'auto',
                            maxWidth: '180px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            fontFamily: 'Inter',
                            fontSize: '13.5px',
                            letterSpacing: '0.12px'
                            // color: theme.palette.error.main
                          }}
                        >
                          Remover Palavra
                        </Typography>
                      </div>
                    ) : (
                      <>
                        {sugestion.length === 1 && (
                          <div
                            className={classes.correctSentenceContainer}
                            onClick={() => {
                              onReplaceWord(
                                editor.text?.textId,
                                correction,
                                'agree',
                                correction?.spellingAndGrammar
                                  ? 'spellingAndGrammar'
                                  : editor.activeCategory,
                                sugestion[0]
                              );

                              onNextCorrection(correction._id);

                              if (isMobile) {
                                ctx.modal.corrections.toggle();
                              }
                            }}
                          >
                            <p className={classes.descriptionResult}>
                              {sugestion[0]}
                            </p>
                          </div>
                        )}

                        {sugestion.length > 1 && (
                          <FormControl
                            sx={{ padding: '0px!important', cursor: 'pointer' }}
                            size="small"
                          >
                            <Select
                              variant="outlined"
                              labelId="demo-select-small"
                              id="demo-select-small"
                              IconComponent={() => null}
                              onChange={() => {}}
                              value={sugestion[0]}
                              // color={'success'}
                              className={classes.correctSentenceContainer}
                              style={{
                                padding: '0px!important',
                                border: 'unset!important'
                              }}
                              sx={{
                                borderSize: '0px!important',
                                width: 'max-content',
                                fontFamily: ['Inter'].join(','),
                                fontSize: '15px',
                                maxWidth: '180px',
                                '&.MuiOutlinedInput-root': {
                                  border: '0.5px solid transparent',
                                  borderRadius: '4px',
                                  cursor: 'pointer',
                                  '& fieldset': {
                                    border: 'none',
                                    padding: '0px'
                                  },
                                  '&:hover fieldset': {
                                    border: '0.5px solid rgba(9,178,134,0.55)'
                                  },
                                  '&.Mui-focused fieldset': {
                                    border: '0.5px solid rgba(9,178,134,0.55)'
                                  }
                                },
                                '& .MuiSelect-select': {
                                  padding: '0px!important'
                                }
                              }}
                            >
                              {sugestion.map((item, i) => {
                                return (
                                  <MenuItem
                                    key={i}
                                    value={item}
                                    selected={i === 0 ? true : false}
                                    onClick={() => {
                                      onReplaceWord(
                                        editor.text?.textId,
                                        correction,
                                        'agree',
                                        correction?.spellingAndGrammar
                                          ? 'spellingAndGrammar'
                                          : editor.activeCategory,
                                        item
                                      );

                                      onNextCorrection(correction._id);

                                      if (isMobile) {
                                        ctx.modal.corrections.toggle();
                                      }
                                    }}
                                  >
                                    {item}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        )}
                      </>
                    )}
                  </Box>
                )}

              {['WHITESPACE_RULE', 'ESPACO_DUPLO'].includes(
                correction.rule?.id
              ) && (
                <div
                  className={classes.correctSentenceContainer}
                  style={{
                    textTransform: 'unset',
                    fontSize: '13.5px',
                    // fontWeight: '500',
                    letterSpacing: '0.12px'
                  }}
                  onClick={() => {
                    onReplaceWord(
                      editor.text?.textId,
                      correction,
                      'agree',
                      correction?.spellingAndGrammar
                        ? 'spellingAndGrammar'
                        : editor.activeCategory,
                      correction.suggestions[0]
                    );

                    onNextCorrection(correction._id);

                    if (isMobile) {
                      ctx.modal.corrections.toggle();
                    }
                  }}
                >
                  {['WHITESPACE_RULE', 'ESPACO_DUPLO'].includes(
                    correction.rule?.id
                  )
                    ? 'Remover espaço(s)'
                    : correction.suggestions.length === 0
                    ? 'Remover Palavra'
                    : correction.suggestions[0]}
                </div>
              )}
            </Box>
          )}

        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          flexDirection={'row'}
          width={'100%'}
        >
          {editor.activeCategory === 'deviations' &&
            // correction.type === 'correcao' &&
            correction.spellingAndGrammar && (
              <FixAllContainer
                correction={correction}
                corrections={corrections}
                onFixAll={onFixAll}
                textId={editor.text?.textId}
                category={
                  correction?.spellingAndGrammar
                    ? 'spellingAndGrammar'
                    : editor.activeCategory
                }
              />
            )}

          {editor.activeCategory === 'deviations' &&
            // correction.type === 'correcao' &&
            correction.spellingAndGrammar && (
              <DismissAllContainer
                correction={correction}
                corrections={corrections}
                onDismissAll={onDismissAll}
                textId={editor.text?.textId}
                category={
                  correction?.spellingAndGrammar
                    ? 'spellingAndGrammar'
                    : editor.activeCategory
                }
              />
            )}
        </Box>

        {/*  <Box
          display={'flex'}
          alignItems={'center'}
          flexDirection={'row'}
          mt={'3px'}
          onClick={() => {
            logEvent('hint', openTips ? 'hide' : 'show');
            setOpenTips(!openTips);
          }}
        >
          {collapse &&
            (['deviations', 'doubts'].includes(correctionType) ||
              (correctionType === 'spellingAndGrammar' && correction.id)) && (
              <>
                <Typography className={classes.seeMore}>
                  Ver {openTips ? 'menos' : 'mais'} dicas
                </Typography>
                {openTips ? (
                  <ExpandLess className={classes.expand} color={'primary'} />
                ) : (
                  <ExpandMore className={classes.expand} color={'primary'} />
                )}
              </>
            )}
        </Box>
        {correctionType === 'spellingAndGrammar' && correction.rule && (
          <div className={classes.wrongContainer}>
            {correction.rule.id !== 'WHITESPACE_RULE' && [
              <Typography key={'typo'} className={classes.wrongContent}>
                {correction.spans[0].content}
              </Typography>,
              <ArrowForward key={'icon'} fontSize={'small'} />
            ]}
            <Button
              variant={'outlined'}
              className={clsx(classes.btn, classes.aceitarSAG)}
              aria-controls={hasPopup > 0 ? 'simple-menu' : ''}
              aria-haspopup={hasPopup}
              onClick={
                hasPopup
                  ? handleOpenMenu
                  : () =>
                      sendFeedback(
                        textId,
                        correction._id,
                        'agree',
                        '',
                        correctionType,
                        correction.suggestions[0]
                      )
              }
            >
              {correction.rule.id === 'WHITESPACE_RULE'
                ? 'Remover espaço'
                : correction.suggestions.length === 0
                ? 'Remover Palavra'
                : correction.suggestions[0]}
            </Button>
            {hasPopup && (
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                elevation={0}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {correction.suggestions.map(suggestion => (
                  <MenuItem
                    key={suggestion}
                    onClick={() => {
                      handleClose();
                      sendFeedback(
                        textId,
                        correction._id,
                        'agree',
                        '',
                        correctionType,
                        suggestion
                      );
                    }}
                  >
                    {suggestion}
                  </MenuItem>
                ))}
              </Menu>
            )}
          </div>
        )}*/}
      </AccordionDetails>
      <AccordionActions
        style={{
          padding: 0,
          margin: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: ['WHITESPACE_RULE', 'ESPACO_DUPLO'].includes(
            correction.rule?.id
          )
            ? 'end'
            : (editor.activeCategory === 'spellingAndGrammar' &&
                correction.type !== 'ortografia') ||
              (!isViewCorrectButton && !isViewCorrectionAutomatic)
            ? 'space-between'
            : 'space-between',
          flexDirection: 'row',
          paddingBottom: '20px',
          paddingLeft: '15px',
          paddingRight: '15px'
        }}
      >
        {editor.activeCategory &&
        ['deviations', 'doubts'].includes(editor.activeCategory) &&
        correction.custom ? (
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            flexDirection={'row'}
            width={'100%'}
          >
            <Box>
              {isViewCorrectButton ||
                (!isViewCorrectionAutomatic && (
                  <Button
                    size={'small'}
                    variant={'outlined'}
                    className={classes.btnAceitar}
                    style={{
                      textTransform: 'unset',
                      fontFamily: 'Inter',
                      fontSize: '13.5px',
                      fontWeight: '500',
                      letterSpacing: '0.12px',
                      padding: '5px 12px',
                      margin: '0 3px !important',
                      borderRadius: '7px',
                      color: theme.palette.success.main,
                      borderColor: theme.palette.success.main,
                      background: '#0FEDB208 0% 0% no-repeat padding-box'
                    }}
                    onClick={() =>
                      onSendFeedback(
                        editor.text?.textId,
                        _id,
                        'agree',
                        correction?.spellingAndGrammar
                          ? 'spellingAndGrammar'
                          : editor.activeCategory
                      )
                    }
                  >
                    {translate('page.review.card.accept')}
                  </Button>
                ))}

              {isViewCorrectionAutomatic && (
                <Button
                  size={'small'}
                  variant={'outlined'}
                  className={classes.btnAceitar}
                  style={{
                    textTransform: 'unset',
                    fontFamily: 'Inter',
                    fontSize: '13.5px',
                    fontWeight: '500',
                    letterSpacing: '0.12px',
                    padding: '5px 12px',
                    margin: '0 3px !important',
                    borderRadius: '7px',
                    borderColor:
                      themeMode.mode === 'light'
                        ? `#7C24FB`
                        : `${theme.palette.secondary.main}`,
                    color:
                      themeMode.mode === 'light'
                        ? `#7C24FB`
                        : `${theme.palette.secondary.main}`
                  }}
                  onClick={() => {
                    autoCorrection.onGenerate();
                  }}
                >
                  {autoCorrection.outputs.length > 0
                    ? 'Gerar novas sugestões'
                    : 'Gerar sugestões'}
                </Button>
              )}
            </Box>
            <Box>
              <Button
                size={'small'}
                variant={'outlined'}
                className={classes.btnDispensar}
                onClick={() => {
                  onSendFeedback(
                    editor.text?.textId,
                    _id,
                    'disagree',
                    correction?.spellingAndGrammar
                      ? 'spellingAndGrammar'
                      : editor.activeCategory
                  );
                }}
                style={{
                  fontFamily: 'Inter',
                  textTransform: 'unset',
                  fontSize: '13.5px',
                  fontWeight: '500',
                  letterSpacing: '0.12px',
                  padding: '5px 12px',
                  margin: '0 3px !important',
                  borderRadius: '7px',
                  color: theme.palette.error.main,
                  borderColor: theme.palette.error.main,
                  background:
                    '0% 0% no-repeat padding-box padding-box rgba(255, 89, 137, 0.03)'
                }}
              >
                {translate('page.review.card.dismiss')}
              </Button>

              {editor.activeCategory === 'deviations' &&
                correction.type !== 'correcao' && (
                  <MoreAction
                    id={_id}
                    onSendFeedback={() =>
                      onSendFeedback(
                        editor.text?.textId,
                        _id,
                        'false-positive',
                        editor.activeCategory
                      )
                    }
                    onDisableRule={cb => onDisableRule(correction.id, cb)}
                  />
                )}
            </Box>
          </Box>
        ) : editor.activeCategory === 'spellingAndGrammar' ||
          editor.activeCategory === 'deviations' ? (
          <>
            {(correction.type === 'ortografia' ||
              (correction.score.correcao && !correction.custom)) &&
              !['WHITESPACE_RULE', 'ESPACO_DUPLO'].includes(
                correction.rule?.id
              ) && (
                <Button
                  size={'small'}
                  variant={'outlined'}
                  className={classes.btnAceitar}
                  onClick={() =>
                    onAddToDictionary(
                      editor.text?.textId,
                      correction,
                      'addToDictionary',
                      correction?.spellingAndGrammar
                        ? 'spellingAndGrammar'
                        : editor.activeCategory
                    )
                  }
                  style={{
                    fontFamily: 'Inter',
                    textTransform: 'unset',
                    fontSize: '13.5px',
                    letterSpacing: '0.12px',
                    padding: '5px 12px',
                    margin: '0 3px !important',
                    borderRadius: '7px',
                    color: theme.palette.success.main,
                    borderColor: theme.palette.success.main,
                    background: '#0FEDB208 0% 0% no-repeat padding-box'
                  }}
                >
                  {translate('page.review.card.add')}
                </Button>
              )}
            <Button
              size={'small'}
              variant={'outlined'}
              className={classes.btnDispensar}
              onClick={() =>
                onSendFeedback(
                  editor.text?.textId,
                  _id,
                  'disagree',
                  'spellingAndGrammar'
                )
              }
              style={{
                fontFamily: 'Inter',
                textTransform: 'unset',
                fontSize: '13.5px',
                fontWeight: '500',
                letterSpacing: '0.12px',
                padding: '5px 12px',
                margin: '0 3px !important',
                borderRadius: '7px',
                color: theme.palette.error.main,
                borderColor: theme.palette.error.main,
                background:
                  '0% 0% no-repeat padding-box padding-box rgba(255, 89, 137, 0.03)'
              }}
            >
              {translate('page.review.card.dismiss')}
            </Button>
          </>
        ) : (
          <Box flexGrow={1} />
        )}
      </AccordionActions>
      {/*

        {['deviations', 'doubts'].includes(correctionType) ? (
          <Button
            variant={'outlined'}
            className={clsx(classes.btn, classes.aceitar)}
            onClick={() =>
              sendFeedback(textId, correction._id, 'agree', '', correctionType)
            }
          >
            Corrigido
          </Button>
        ) : correction.type === 'ortografia' ? (
          <TooltipContainer title={'Adicionar ao dicionário'} small={true}>
            <Button
              color={'primary'}
              variant={'outlined'}
              size={'small'}
              onClick={() =>
                addToDictionary(textId, correctionType, correction)
              }
              className={clsx(classes.btn)}
            >
              Adicionar
            </Button>
          </TooltipContainer>
        ) : (
          <Box flexGrow={1} />
        )}
        <Box>
          <Button
            variant={'outlined'}
            className={clsx(classes.btn, classes.rejeitar)}
            onClick={() =>
              sendFeedback(
                textId,
                correction._id,
                'disagree',
                '',
                correctionType
              )
            }
          >
            Dispensar
          </Button>
          {['deviations'].includes(correctionType) && (
            <MoreAction
              id={correction.id}
              sendFeedback={() =>
                sendFeedback(
                  textId,
                  correction._id,
                  'false-positive',
                  '',
                  correctionType
                )
              }
            />
          )}
        </Box>
      </AccordionActions> */}
    </Accordion>
  );
};

export default CorrectionItem;

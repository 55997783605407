/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import {
  useMediaQuery,
  useTheme,
  Typography,
  LinearProgress,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider
} from '@mui/material';

import { ResultInterface } from './interface';
import useStyles from './style';
import { ThemeContext } from '../../../../../../../utils/context/ThemeContext';
import { GlobalContext } from '../../../../../../../utils/context/GlobalContext';
import { TranslateInterface } from '../../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../../utils/hooks/useLanguage';
import CircleIcon from '@mui/icons-material/Circle';

const AiDetectResult = ({ result }: ResultInterface) => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();
  const ctx = useContext(GlobalContext);
  const { translate }: TranslateInterface = useLanguage();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const ai = String(result?.ai || 0).replace('.', ',');

  // const human = String(result?.human || 0).replace('.', ',');

  const human = String(result?.ai ? 100 - result.ai : 0).replace('.', ',');

  const categories = [
    {
      label: translate('page.tools.list.aiDetect.result.ai'),
      color: '#DDA0DD',
      percentage: ai + '%'
    },
    {
      label: translate('page.tools.list.aiDetect.result.human'),
      color: '#87CEFA',
      percentage: human + '%'
    }
  ];

  return (
    <Box className={classes.container}>
      <Typography
        className={classes.percentage}
        sx={{
          color:
            themeMode.mode === 'light'
              ? `${theme.palette.primary.main}`
              : `${theme.palette.secondary.main}`
        }}
      >
        {ai}%
      </Typography>
      <Typography className={classes.label} color="textSecondary" mt={2}>
        {translate('page.tools.list.aiDetect.result.title')}
      </Typography>

      <LinearProgress
        variant="determinate"
        value={0}
        className={classes.progressBar}
      />

      <List>
        {categories.map((category, index) => (
          <React.Fragment key={index}>
            <ListItem className={classes.listItem}>
              <Grid container alignItems="center" mt={2} mb={2}>
                <ListItemIcon>
                  <CircleIcon
                    style={{ color: category.color }}
                    className={classes.circleIcon}
                  />
                </ListItemIcon>
                <ListItemText primary={category.label} />
              </Grid>
              <Typography className={classes.percentageText}>
                {category.percentage}
              </Typography>
            </ListItem>
            {index < categories.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default AiDetectResult;

import React, { useContext } from 'react';
import { SidebarItemInterface } from './interface';
import {
  ListItemButton,
  ListItemIcon,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../stories/redux/store';
import { Link, useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../../../../../utils/context/GlobalContext';
import { styled } from '@mui/material/styles';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { STATE_TEMPLATE } from '../../../../pages/templates/const/STATE_TEMPLATE';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';
import { createNewDocument } from '../../../../../../stories/actions/documents';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';

const SidebarItem = ({ item, isColapse, toggle }: SidebarItemInterface) => {
  const theme = useTheme();

  const { translate }: TranslateInterface = useLanguage();

  const ctx = useContext(GlobalContext);

  const { appState } = useSelector((state: RootState) => state.appState);

  const themeMode = useContext(ThemeContext);

  const activeStyle = themeMode.mode === 'light' ? `#410A85` : '#0fedb2';
  const disableStyle =
    themeMode.mode === 'light'
      ? `${theme.palette.primary.main}`
      : `${theme.palette.secondary.main}`;

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const dispatch = useAppDispatch();

  const actions = {
    news: () => {
      window.open('https://clarice.ai/novidades', '_blank');
    },
    terms: () => {
      window.open('https://clarice.ai/termos', '_blank');
    },
    privacyPolicy: () => {
      window.open('https://clarice.ai/privacidade', '_blank');
    },
    help: () => {
      toggle();

      ctx.modal.suggestion.toggle();
    },
    rewriter: () => {
      toggle();

      navigate('/templates/rewrite-text');
    },
    summarizer: () => {
      toggle();

      navigate('/templates/content-summarizer');
    },
    translate: () => {
      toggle();

      navigate('/tools/translate');
    },
    aiDetect: () => {
      toggle();

      navigate('/tools/ai-detect');
    },
    review: () => {
      toggle();

      const cb = {
        success: (_id: any) => {
          navigate(`/edit-text/${_id}`);
        },
        error: () => {}
      };

      dispatch(createNewDocument(cb));
    },
    dictionary: () => {
      toggle();

      ctx.modal.dictionary.toggle();
    },
    disabledRules: () => {
      toggle();

      ctx.modal.disabledRules.toggle();
    },
    agentCopyWriter: () => {
      toggle();

      navigate('/agents/copywriter');
    },
    agentPhotographer: () => {
      toggle();

      navigate('/agents/photographer');
    },
    agentIllustrator: () => {
      toggle();

      navigate('/agents/illustrator');
    },
    agentMessages: () => {
      toggle();

      navigate('/agents/messages');
    },
    agentService: () => {
      toggle();

      navigate('/agents/service');
    },
    agentRedactor: () => {
      toggle();

      navigate('/agents/redactor');
    }
  };

  const navigate = useNavigate();

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#410A85',
      color: 'white',
      borderRadius: '20px'
    }
  }));

  const quantity =
    STATE_TEMPLATE(themeMode, {}, translate).templates.length || 0;

  if (item.sidebarProps && item.path) {
    return (
      <ListItemButton
        disableRipple
        onClick={() => {
          navigate(String(item.path));

          toggle();
        }}
        // component={Link}
        // to={item.path}
        sx={{
          minWidth: '220px',
          position: 'relative',
          color: appState === item.state ? activeStyle : disableStyle,
          fontWeight: '500',
          fontSize: '14px',
          paddingY: isMobile ? '7px' : '10px',
          paddingX: '0px',
          display: 'flex',
          alignItems: 'center',
          transition: 'none',
          borderRadius: '9px',
          background:
            appState === item.state
              ? themeMode.mode === 'light'
                ? `rgb(236 221 255)`
                : `rgb(72 80 78)`
              : 'transparent',
          '&:hover':
            appState !== item.state
              ? {
                  background:
                    themeMode.mode === 'light'
                      ? 'rgb(241 241 241)'
                      : 'rgb(72 80 78)'
                }
              : {
                  background:
                    themeMode.mode === 'light'
                      ? `rgb(236 221 255)`
                      : `rgb(72 80 78)`
                }
          // '&:hover': {
          //   backgroundColor: 'transparent',
          // }
        }}
      >
        {appState === item.state && (
          <div
            style={{
              zIndex: '-1',
              borderRadius: '7px',
              position: 'absolute',
              top: '0',
              left: '0',
              content: ' ',
              width: '200px',
              height: isMobile ? '38px' : '45px'
            }}
          ></div>
        )}
        <ListItemIcon
          sx={{
            color: appState === item.state ? activeStyle : disableStyle,
            minWidth: '0!important',
            marginLeft: isColapse ? '3rem' : '1rem',
            marginRight: '0.5rem'
          }}
        >
          {item.sidebarProps.icon && item.sidebarProps.icon}{' '}
        </ListItemIcon>
        {translate(item.sidebarProps.displayText)}{' '}
        {item.state === 'templates' && (
          <>
            {/*<StyledBadge*/}
            {/*  badgeContent={60}*/}
            {/*  color="error"*/}
            {/*  style={{ marginLeft: '22px' }}*/}
            {/*/>*/}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <div
                style={{
                  marginLeft: '64px',
                  background:
                    themeMode.mode === 'light'
                      ? '#410A85'
                      : appState === item.state
                      ? 'rgb(14 14 14 / 38%)'
                      : 'rgb(72 80 78)',
                  borderRadius: '1000px',
                  width: '24px',
                  height: '24px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <span
                  style={{
                    color: themeMode.mode === 'light' ? '#ffffff' : '#0fedb2',
                    fontFamily: "'Inter'!important",
                    fontSize: '12px',
                    fontWeight: '600'
                  }}
                >
                  {quantity}
                </span>
              </div>
            </div>
          </>
        )}
      </ListItemButton>
    );
  }

  if (item.sidebarProps && item.onClick) {
    return (
      <ListItemButton
        disableRipple
        onClick={() => {
          return item.onClick
            ? actions[item.state] && actions[item.state]()
            : null;
        }}
        sx={{
          minWidth: '220px',
          position: 'relative',
          color: appState === item.state ? activeStyle : disableStyle,
          fontWeight: '500',
          fontSize: '14px',
          paddingY: '10px',
          paddingX: '0px',
          display: 'flex',
          alignItems: 'center',
          borderRadius: '9px',
          background:
            appState === item.state
              ? themeMode.mode === 'light'
                ? `rgb(236 221 255)`
                : `rgb(72 80 78)`
              : 'transparent',
          '&:hover':
            appState !== item.state
              ? {
                  background:
                    themeMode.mode === 'light'
                      ? 'rgb(241 241 241)'
                      : 'rgb(72 80 78)'
                }
              : {
                  background:
                    themeMode.mode === 'light'
                      ? `rgb(236 221 255)`
                      : `rgb(72 80 78)`
                }
          // '&:hover': {
          //   backgroundColor: 'transparent',
          // }
        }}
      >
        {appState === item.state && (
          <div
            style={{
              zIndex: '-1',
              borderRadius: '10px',
              position: 'absolute',
              top: '0',
              left: '1rem',
              content: ' ',
              width: '220px',
              height: '45px'
              // background: `${theme.palette.primary.main}`
            }}
          ></div>
        )}
        <ListItemIcon
          sx={{
            color: appState === item.state ? activeStyle : disableStyle,
            minWidth: '0!important',
            marginLeft: isColapse ? '3rem' : '1rem',
            marginRight: '0.5rem'
          }}
        >
          {item.sidebarProps.icon && item.sidebarProps.icon}
        </ListItemIcon>
        {translate(item.sidebarProps.displayText)}
      </ListItemButton>
    );
  }

  return null;
};

export default SidebarItem;

const LANGUAGE_EN: any = {
  configurator: {
    language: {
      title: 'Language Settings',
      options: {
        portuguese: 'Portuguese',
        english: 'English',
        spanish: 'Spanish'
      }
    },
    platform: {
      title: 'Platform Settings',
      options: {
        standard: 'Standard',
        education: 'Education',
        agents: 'Agents'
      }
    }
  },
  page: {
    profile: {
      title: 'Profile',
      section: {
        info: {
          description: 'Your Data',
          name: 'How should we call you?',
          email: 'E-mail',
          phone: 'Phone',
          action: {
            edit: {
              label: 'Edit Data'
            }
          },
          uninformed: 'Not Provided'
        },
        general: {
          description: 'My Account',
          firstAccess: 'First Access',
          texts: 'Texts',
          words: 'Words',
          suggestion: {
            title: 'Style Deviations',
            options: {
              clarity: 'Clarity',
              conciseness: 'Conciseness',
              strength: 'Strength',
              originality: 'Originality',
              formatting: 'Formatting',
              correction: 'Correction'
            },
            corrected: 'deviations corrected'
          },
          uninformed: 'Not Provided',
          tag: {
            free: 'FREE',
            paid: 'PRO'
          }
        }
      }
    },
    editProfile: {
      title: 'Edit Profile',
      description: 'User profile settings',
      form: {
        name: {
          label: 'Name',
          placeholder: 'Enter your name...'
        },
        email: {
          label: 'Email',
          placeholder: 'Enter your email...'
        },
        phone: {
          label: 'Phone',
          placeholder: 'Enter your phone number...',
          mask: '(99) 99999-9999'
        }
      },
      action: {
        update: 'Save'
      }
    },
    signIn: {
      title: 'Login',
      description:
        'Your data is safe with us, under the protection of the LGPD. Access the terms of use and privacy policies.',
      leftContainerFlow: {
        description:
          'Your data is safe with us, under the protection of the LGPD. Access the terms of use and privacy policies.'
      },
      form: {
        title: 'Access your account',
        email: {
          label: 'Email',
          placeholder: 'Enter your email'
        },
        password: {
          label: 'Password',
          placeholder: 'Enter your password'
        },
        action: {
          signIn: 'Sign In',
          signUp: 'Create an account',
          forgot: 'Forgot your password?',
          connected: 'Keep Me Signed In',
          firstAccess: 'First Access?',
          signInWithGoogle: 'Sign in with Google'
        },
        notify: {
          required: {
            email: 'The email field is required.',
            password: 'The password field is required.'
          }
        }
      }
    },
    signUp: {
      title: 'Create your account',
      description:
        'Your data is safe with us, under the protection of the LGPD. Access the terms of use and privacy policies.',
      leftContainerFlow: {
        description:
          'Your data is safe with us, under the protection of the LGPD. Access the terms of use and privacy policies.'
      },
      form: {
        title: 'Create your account',
        name: {
          label: 'What should we call you?',
          placeholder: 'Enter your name'
        },
        email: {
          label: 'Email',
          placeholder: 'Enter your email'
        },
        password: {
          label: 'Password',
          placeholder: 'Enter your password'
        },
        action: {
          signIn: 'Sign In',
          signUp: 'Create account',
          haveAnAccount: 'Already have an account?',
          signUpWithGoogle: 'Sign up with Google',
          acceptedTerms: {
            one: 'I have read and accept the',
            two: 'terms of use',
            three: ',',
            four: 'privacy policy',
            five: 'and',
            six: 'payment policy'
          }
        },
        notify: {
          required: {
            name: 'The name field is required.',
            email: 'The email field is required.',
            password: 'The password field is required.',
            captcha: 'Please complete the captcha.',
            accept: 'Please accept the terms.'
          }
        }
      }
    },
    forgotPassword: {
      title: 'Forgot my password',
      description:
        'Your data is safe with us, under the protection of the LGPD. Access the terms of use and privacy policies.',
      leftContainerFlow: {
        description:
          'Your data is safe with us, under the protection of the LGPD. Access the terms of use and privacy policies.'
      },
      form: {
        title: 'Recover your password',
        description:
          'Enter your email to receive instructions on how to reset your password.',
        email: {
          label: 'Email',
          placeholder: 'Enter your email'
        },
        action: {
          sendEmail: 'Send recovery email',
          signUp: 'Create an account',
          firstAccess: 'First Access?'
        },
        notify: {
          required: {
            email: 'The email field is required.'
          }
        }
      }
    },
    passwordRecovery: {
      title: 'New Password',
      description:
        'Your data is safe with us, under the protection of the LGPD. Access the terms of use and privacy policies.',
      leftContainerFlow: {
        description:
          'Your data is safe with us, under the protection of the LGPD. Access the terms of use and privacy policies.'
      },
      form: {
        title: 'Just one more step!',
        description: 'Enter the new password for your access.',
        password: {
          label: 'New password',
          placeholder: 'Enter your new password'
        },
        action: {
          updatePassword: 'Update my password'
        }
      }
    },
    project: {
      list: {
        title: 'Projects',
        description: 'Here you can find all your projects.',
        search: {
          label: 'Search',
          placeholder: 'Search for a project...'
        },
        action: {
          create: 'Create Project',
          update: 'Edit',
          delete: 'Delete'
        },
        notFound: 'No project found...',
        notAdded: 'No project added yet...',
        notTitle: 'No title'
      },
      modal: {
        create: {
          title: 'New Project',
          description: 'Create new projects to organize your work.',
          form: {
            name: {
              label: 'Name',
              placeholder: 'Enter a name...'
            },
            description: {
              label: 'Description',
              placeholder: 'Enter a description...'
            }
          },
          action: {
            cancel: 'Cancel',
            confirm: 'Create'
          }
        },
        update: {
          title: 'Edit Project',
          description: 'Update the name and description of your project.',
          form: {
            name: {
              label: 'Name',
              placeholder: 'Enter a name...'
            },
            description: {
              label: 'Description',
              placeholder: 'Enter a description...'
            }
          },
          action: {
            cancel: 'Cancel',
            confirm: 'Save'
          }
        },
        delete: {
          title: 'Delete Project',
          description: 'Are you sure you want to delete this project?',
          action: {
            cancel: 'Cancel',
            confirm: 'Delete'
          }
        }
      }
    },
    brand: {
      list: {
        title: 'Brand Hub',
        search: {
          label: 'Search',
          placeholder: 'Search for a brand...'
        },
        action: {
          create: 'Create brand',
          update: 'Edit',
          delete: 'Delete'
        },
        notFound: 'No brand found...',
        notAdded: 'No brand added yet...',
        loading: 'Loading...',
        notTitle: 'No title'
      },
      create: {
        title: 'Create new brand',
        description: {
          one: 'Welcome to your brand creation journey! Here, you will share your business communication definitions.',
          two: 'With this valuable information, Clarice.ai will be ready to create a unique and memorable guide aligned with your brand’s goals and values.'
        },
        form: {
          name: {
            label: 'Name',
            placeholder: 'What is your brand’s name? \nExample: Nike'
          },
          description: {
            label: 'Description',
            placeholder:
              'Tell us a little about your brand... \n\nExample: Nike is a global sports apparel and footwear brand known for its innovation and quality.'
          },
          audience: {
            label: 'Audience',
            placeholder:
              'Who is your target audience? \n\nExample: Athletes, sports enthusiasts, young adults interested in fitness.'
          },
          tone: {
            label: 'Tone',
            placeholder:
              'Define the tone of voice for your brand. \n\nExample: Motivational and inspiring.'
          },
          personality: {
            label: 'Personality',
            placeholder:
              'Describe your brand’s personality. \n\nExample: A bold, brave, and inspiring brand, focused on athletes and those who strive to push their limits.'
          },
          missionVisionAndValue: {
            label: 'Mission, Vision, and Values',
            placeholder:
              'Enter your brand’s mission, vision, and values. \n\nMission: To inspire and innovate for every athlete in the world. \nVision: To bring inspiration and innovation to every athlete. \nValues: Determination, innovation, teamwork.'
          },
          keywords: {
            label: 'Keywords',
            placeholder:
              'Enter keywords related to your brand. \n\nExample: Innovation, performance, overcoming.'
          },
          preferredWordsAndPhrases: {
            label: 'Preferred Words and Phrases',
            placeholder:
              'List the words and phrases you prefer to use in your communication. \n\nExample: Just Do It, excellence, champion.'
          },
          wordsAndPhrasesToAvoid: {
            label: 'Words and Phrases to Avoid',
            placeholder:
              'List the words and phrases that should be avoided. \n\nExample: Failure, give up, impossible.'
          },
          image: {
            label: 'Upload your brand’s logo image',
            placeholder: 'Upload your brand’s logo image',
            chosen: 'Chosen:',
            chooseImage: 'Choose image'
          }
        },
        action: {
          generateWithIA: {
            label: 'Generate with AI',
            tooltip:
              'Use our Artificial Intelligence and describe your brand in seconds!'
          },
          cancel: 'Back',
          confirm: 'Save'
        }
      },
      update: {
        title: 'Edit Brand',
        form: {
          name: {
            label: 'Name',
            placeholder: 'What is your brand’s name? \nExample: Nike'
          },
          description: {
            label: 'Description',
            placeholder:
              'Tell us a little about your brand... \n\nExample: Nike is a global sports apparel and footwear brand known for its innovation and quality.'
          },
          audience: {
            label: 'Audience',
            placeholder:
              'Who is your target audience? \n\nExample: Athletes, sports enthusiasts, young adults interested in fitness.'
          },
          tone: {
            label: 'Tone',
            placeholder:
              'Define the tone of voice for your brand. \n\nExample: Motivational and inspiring.'
          },
          personality: {
            label: 'Personality',
            placeholder:
              'Describe your brand’s personality. \n\nExample: A bold, brave, and inspiring brand, focused on athletes and those who strive to push their limits.'
          },
          missionVisionAndValue: {
            label: 'Mission, Vision, and Values',
            placeholder:
              'Enter your brand’s mission, vision, and values. \n\nMission: To inspire and innovate for every athlete in the world. \nVision: To bring inspiration and innovation to every athlete. \nValues: Determination, innovation, teamwork.'
          },
          keywords: {
            label: 'Keywords',
            placeholder:
              'Enter keywords related to your brand. \n\nExample: Innovation, performance, overcoming.'
          },
          preferredWordsAndPhrases: {
            label: 'Preferred Words and Phrases',
            placeholder:
              'List the words and phrases you prefer to use in your communication. \n\nExample: Just Do It, excellence, champion.'
          },
          wordsAndPhrasesToAvoid: {
            label: 'Words and Phrases to Avoid',
            placeholder:
              'List the words and phrases that should be avoided. \n\nExample: Failure, give up, impossible.'
          },
          image: {
            label: 'Upload your brand’s logo image',
            placeholder: 'Upload your brand’s logo image',
            chosen: 'Chosen:',
            chooseImage: 'Choose image'
          }
        },
        action: {
          cancel: 'Back',
          confirm: 'Save'
        }
      },
      generateWithIA: {
        title: 'Fill out the field below and watch the magic happen',
        description:
          'Clarice.ai’s artificial intelligence is ready to create an amazing brand for you.',
        form: {
          description: {
            label: 'Brand description',
            placeholder: 'Describe the main characteristics of your brand...'
          }
        },
        action: {
          generate: 'Generate with AI'
        }
      },
      modal: {
        delete: {
          title: 'Delete Brand',
          description: 'Are you sure you want to delete this brand?',
          action: {
            cancel: 'Cancel',
            confirm: 'Delete'
          }
        }
      }
    },
    business: {
      title: 'My Business',
      form: {
        name: {
          label: 'Name',
          placeholder: 'Enter your business name...'
        }
      },
      action: {
        create: 'Register',
        update: 'Save'
      },
      notPermission: 'You do not have permission to access this page...',
      list: {
        title: 'Users',
        action: {
          add: 'Add User',
          delete: 'Delete'
        },
        name: 'Name',
        email: 'Email',
        lastAccess: 'Last Access',
        notAdded: 'No users added yet...',
        badge: {
          owner: 'Owner',
          active: 'Active',
          pending: 'Pending'
        },
        licenses: {
          of: 'of',
          used: 'licenses used'
        }
      },
      modal: {
        add: {
          title: 'Add User',
          description: 'Add a user to your business right now!',
          form: {
            email: {
              label: 'Enter the user’s email'
            }
          },
          action: {
            cancel: 'Cancel',
            confirm: 'Send Invitation'
          }
        }
      }
    },
    backoffice: {
      title: 'Backoffice',
      notPermission: 'You do not have permission to access this page...',
      business: {
        dashboard: {
          badge: {
            total: 'Total',
            active: 'Active',
            expired: 'Expired'
          }
        },
        list: {
          title: 'Companies',
          action: {
            create: 'Add',
            update: 'Edit',
            delete: 'Delete'
          },
          company: 'Company',
          plan: 'Plan',
          startDate: 'Start Date',
          endDate: 'End Date',
          notAdded: 'No company added yet...',
          loading: 'Loading...',
          badge: {
            total: 'Total',
            active: 'Active',
            expired: 'Expired',
            pending: 'Pending'
          },
          licenses: {
            of: 'of',
            used: 'licenses used'
          }
        },
        modal: {
          add: {
            title: 'Add Company',
            description: 'Create a company and link it to a user right now!',
            form: {
              name: {
                label: 'Company name',
                placeholder: ''
              },
              email: {
                label: 'Owner’s email',
                placeholder: ''
              },
              licenses: {
                label: 'Number of licenses',
                placeholder: ''
              },
              startDate: {
                label: 'Start Date',
                placeholder: ''
              },
              endDate: {
                label: 'End Date',
                placeholder: ''
              },
              sharedBrand: {
                label: 'Brand Manual',
                placeholder: ''
              }
            },
            action: {
              cancel: 'Cancel',
              confirm: 'Create'
            }
          }
        },
        update: {
          title: 'Edit Company',
          form: {
            name: {
              label: 'Company name',
              placeholder: ''
            },
            licenses: {
              label: 'Number of licenses',
              placeholder: ''
            },
            startDate: {
              label: 'Start Date',
              placeholder: ''
            },
            endDate: {
              label: 'End Date',
              placeholder: ''
            },
            sharedBrand: {
              label: 'Brand Manual',
              placeholder: ''
            }
          },
          action: {
            confirm: 'Save'
          },
          user: {
            list: {
              title: 'Users',
              action: {
                add: 'Add User',
                delete: 'Delete'
              },
              name: 'Name',
              email: 'Email',
              lastAccess: 'Last Access',
              notAdded: 'No users added yet...',
              badge: {
                owner: 'Owner',
                active: 'Active',
                pending: 'Pending'
              },
              licenses: {
                of: 'of',
                used: 'licenses used'
              }
            },
            modal: {
              add: {
                title: 'Add User',
                description: 'Add a user to the business right now!',
                form: {
                  email: {
                    label: 'Enter the user’s email'
                  }
                },
                action: {
                  cancel: 'Cancel',
                  confirm: 'Save'
                }
              }
            }
          }
        }
      }
    },
    credits: {
      title: 'Credits and Billing',
      available: 'Available Credits',
      total: 'Total Credits',
      used: 'Credits Used',
      plan: 'Plan Credits',
      bonus: 'Bonus Credits',
      percentage: {
        standard: '% of credits used',
        plan: '% of plan credits used',
        bonus: '% of bonus credits used'
      },
      signature: {
        title: 'Plan',
        badge: {
          free: 'FREE',
          paid: 'PRO',
          active: 'Active',
          expired: 'Expired'
        },
        active: {
          description: 'Upgrade your account to access more bonus credits.'
        },
        canceled: {
          one: 'After this period, you will no longer have access to PRO benefits.',
          two: 'First',
          three: 'Next',
          four: 'charge'
        },
        trial: {
          one: 'Your trial period ends in',
          two: 'days and',
          three: 'hours.',
          four: 'The next renewal is in',
          five: 'days'
        },
        action: {
          cancel: 'Cancel Subscription',
          get: 'Get Plan',
          manage: 'Manage Subscription'
        }
      }
    },
    templates: {
      title: 'Templates',
      options: {
        all: 'All',
        ads: 'Ads',
        blog: 'Blog',
        ecommerce: 'Ecommerce',
        email: 'Email',
        frameworks: 'Frameworks',
        google: 'Google',
        marketing: 'Marketing',
        seo: 'SEO',
        socialMedia: 'Social Media',
        videos: 'Videos',
        website: 'Website',
        tools: 'Tools',
        others: 'Others'
      },
      list: {
        contentSummarizer: {
          title: 'Content Summarizer',
          description: 'Get a summary with the key points of a text.',
          fields: {
            content: {
              label: 'Content',
              placeholder: 'Enter the content to be summarized...'
            },
            count: {
              label: 'Size',
              placeholder: 'How many words would you like to summarize?',
              options: {
                '50': '50 words',
                '100': '100 words',
                '200': '200 words',
                '300': '300 words',
                '400': '400 words'
              }
            }
          }
        },
        facebookAdsDescription: {
          title: 'Facebook Ad',
          description:
            'Create Facebook ads that encourage your customers to click and buy.',
          fields: {
            name: {
              label: 'Company or Product Name',
              placeholder: 'Example: Clarice.ai'
            },
            description: {
              label: 'Tell us about your product or service',
              placeholder: 'Example: Intelligent text editor'
            },
            audience: {
              label: 'Who is your target audience?',
              placeholder: 'Example: Writers'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Casual and creative'
            }
          }
        },
        subtitleInstagram: {
          title: 'Instagram Caption',
          description: 'Write captivating captions for your Instagram posts.',
          fields: {
            description: {
              label: 'What is your post about?',
              placeholder: 'Example: Pet food line'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Casual and creative'
            }
          }
        },
        blogPostIntro: {
          title: 'Blog Post Introduction',
          description: 'Overcome writer’s block with a blog post introduction.',
          fields: {
            title: {
              label: 'Title',
              placeholder:
                'Example: Five new electric car models launching next year.'
            },
            audience: {
              label: 'Audience',
              placeholder: 'Example: People interested in eco-friendly vehicles'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Casual and creative'
            }
          }
        },
        blogPostConclusion: {
          title: 'Blog Post Conclusion',
          description: 'Conclude your blog posts with an engaging paragraph.',
          fields: {
            title: {
              label: 'Title',
              placeholder:
                'Example: Five new electric car models launching next year.'
            },
            audience: {
              label: 'Audience',
              placeholder: 'Example: People interested in eco-friendly vehicles'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Casual and creative'
            }
          }
        },
        blogPostOutline: {
          title: 'Blog Post Outline',
          description:
            'Organize your ideas into detailed outlines before writing.',
          fields: {
            title: {
              label: 'Title',
              placeholder:
                'Example: Five new electric car models launching next year.'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Casual and creative'
            }
          }
        },
        blogPostTopicIdeas: {
          title: 'Blog Post Topic Ideas',
          description: 'Generate new blog topic ideas that engage readers.',
          fields: {
            name: {
              label: 'Company/Product Name',
              placeholder: 'Example: Clarice.ai'
            },
            description: {
              label: 'Company/Product Description',
              placeholder: 'Example: Intelligent text editor'
            },
            audience: {
              label: 'Audience',
              placeholder: 'Example: Marketing professionals'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Casual and creative'
            }
          }
        },
        commands: {
          title: 'Commands',
          description:
            'Tell exactly what you want Clarice.ai to write using commands.',
          fields: {
            description: {
              label: 'Enter your command for Clarice.ai',
              placeholder:
                'Example: Narrate a story about Max traveling to the moon in the style of Morgan Freeman.'
            },
            content: {
              label: 'Do you have any additional information?',
              placeholder:
                'Example: Max was a curious cat who loved adventures.'
            }
          }
        },
        companyBio: {
          title: 'Company Bio',
          description: 'Describe your company’s journey in an engaging way.',
          fields: {
            name: {
              label: 'Company Name',
              placeholder: 'Example: Clarice.ai'
            },
            description: {
              label: 'Company Information',
              placeholder: 'Example: What is your company about?'
            },
            audience: {
              label: 'Audience',
              placeholder: 'Example: Writers'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Casual and creative'
            }
          }
        },
        contentImprover: {
          title: 'Content Improver',
          description: 'Rewrite your content to make it more engaging.',
          fields: {
            content: {
              label: 'Content',
              placeholder:
                'Example: Presenting innovative solutions that simplify business operations...'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Casual and creative'
            }
          }
        },
        rewriteText: {
          title: 'Rewrite Text',
          description:
            'Rewrite sentences and paragraphs without changing the original meaning.',
          fields: {
            content: {
              label: 'Content',
              placeholder:
                'Example: Presenting innovative solutions that simplify business operations...'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Casual and creative'
            }
          }
        },
        emailSubject: {
          title: 'Email Subjects',
          description: 'Write more attractive subject lines for your emails.',
          fields: {
            name: {
              label: 'Company/Product Name',
              placeholder: 'Example: Clarice.ai'
            },
            description: {
              label: 'What is your email about?',
              placeholder: 'Example: Presenting our new AI-powered software...'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Intelligent'
            }
          }
        },
        emailMarketing: {
          title: 'Email Marketing',
          description:
            'Develop email marketing campaigns that convert and retain customers.',
          fields: {
            name: {
              label: 'Subject and Pre-header',
              placeholder: 'Enter up to 50 characters for each...'
            },
            description: {
              label: 'Header Text (Hero)',
              placeholder: 'Enter up to 70 characters...'
            },
            content: {
              label: 'Introduction (After “Hello, [NAME]!”)',
              placeholder: 'Enter up to 3 lines of text...'
            },
            cta: {
              label: 'CTA Button',
              placeholder: 'Enter up to four words, ideally two or three...'
            }
          }
        },
        paragraphsGenerator: {
          title: 'Paragraph Generator',
          description:
            'Let Clarice.ai craft compelling paragraphs to captivate your readers.',
          fields: {
            description: {
              label: 'What is your paragraph about?',
              placeholder: 'Example: What are the best fruits for each season?'
            },
            keywords: {
              label: 'Keywords',
              placeholder: 'Example: Apple, orange'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Informative'
            }
          }
        },
        productDescription: {
          title: 'Product Description',
          description:
            'Clarice.ai can create more detailed descriptions for your products.',
          fields: {
            name: {
              label: 'Product Name',
              placeholder: 'Example: Clarice.ai'
            },
            description: {
              label: 'Tell us about your product',
              placeholder: 'Example: AI-powered text correction platform.'
            },
            audience: {
              label: 'Audience',
              placeholder: 'Example: Writers'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Casual and creative'
            }
          }
        },
        subtitleTikTok: {
          title: 'TikTok Caption',
          description: 'Write captivating captions for your TikTok videos.',
          fields: {
            description: {
              label: 'What is your video about?',
              placeholder:
                'Example: How to create the perfect travel itinerary.'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Casual and creative'
            }
          }
        },
        carouselInstagram: {
          title: 'Instagram Carousel',
          description:
            'Create slide-by-slide content for an Instagram carousel.',
          fields: {
            description: {
              label: 'Main subject',
              placeholder: 'Example: How to raise rabbits.'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Professional'
            }
          }
        },
        titleYouTube: {
          title: 'YouTube Video Title',
          description:
            'Create optimized and engaging titles for your YouTube videos.',
          fields: {
            description: {
              label: 'What is the video about?',
              placeholder: 'Example: The most fantastic animals on the planet.'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Casual and creative'
            }
          }
        },
        tagsYouTube: {
          title: 'YouTube Video Tags',
          description: 'Generate tags for your videos based on keywords.',
          fields: {
            title: {
              label: 'Video topic or title',
              placeholder: 'Example: How to earn extra income...'
            }
          }
        },
        youtubeTopicIdeas: {
          title: 'YouTube Video Topic Ideas',
          description: 'Find interesting topics for your YouTube videos.',
          fields: {
            description: {
              label: 'What is the video about?',
              placeholder: 'Example: The mysteries of Thailand’s elephants.'
            },
            audience: {
              label: 'Target Audience',
              placeholder: 'Example: What is your target audience?'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Casual and creative'
            }
          }
        },
        descriptionYouTube: {
          title: 'YouTube Video Description',
          description: 'Write engaging descriptions for your YouTube videos.',
          fields: {
            title: {
              label: 'Video Title',
              placeholder:
                'Example: Delicious recipes for beginners in cooking.'
            }
          }
        },
        youtubeOutline: {
          title: 'YouTube Script Outline',
          description:
            "Create script outlines for 'List' or 'How-to' style videos.",
          fields: {
            title: {
              label: 'Video title or topic',
              placeholder: 'Example: The 10 greatest football legends...'
            }
          }
        },
        youtubeIntroduction: {
          title: 'YouTube Video Introduction',
          description:
            'Create an introduction that captures attention and keeps viewers watching.',
          fields: {
            title: {
              label: 'Video topic or title',
              placeholder: 'Example: The best free kicks in football history...'
            }
          }
        },
        weeklyContentCalendar: {
          title: 'Weekly Content Calendar',
          description:
            'Generate a weekly content calendar based on keywords or main topics.',
          fields: {
            keywords: {
              label: 'Keywords or main topic',
              placeholder: 'Example: Future technologies.'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Casual and creative'
            }
          }
        },
        commemorativeDate: {
          title: 'Commemorative Dates',
          description:
            'Create personalized messages for celebrations and commemorative dates.',
          fields: {
            description: {
              label: 'Reason for celebration',
              placeholder: 'Example: Mother’s Day.'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Creative'
            }
          }
        },
        explainToAChild: {
          title: 'Explain to a Child',
          description:
            'Rewrite the text to make it easier for children to understand.',
          fields: {
            description: {
              label: 'What do you want to explain?',
              placeholder: 'Example: What is outer space?'
            },
            count: {
              label: 'School Grade',
              placeholder: 'Select the school grade',
              options: {
                '1': '1st grade',
                '2': '2nd grade',
                '3': '3rd grade',
                '5': '5th grade',
                '7': '7th grade',
                '9': '9th grade'
              }
            }
          }
        },
        domainNameGenerator: {
          title: 'Domain Name Generator',
          description: 'Generate domain name ideas based on your niche.',
          fields: {
            description: {
              label: 'What is your niche?',
              placeholder: 'Example: Technology company'
            }
          }
        },
        citation: {
          title: 'Inspirational Quotes',
          description: 'Get inspirational quotes from famous personalities.',
          fields: {
            description: {
              label: 'Theme',
              placeholder: 'Example: The value of friendship...'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Casual and creative'
            }
          }
        },
        companySlogan: {
          title: 'Company Slogan',
          description:
            'The perfect phrase that represents and highlights your business.',
          fields: {
            name: {
              label: 'Company Name',
              placeholder: 'Example: Clarice.ai'
            },
            description: {
              label: 'Company Description',
              placeholder: 'Example: Intelligent text editor'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Casual and creative'
            }
          }
        },
        growthIdeas: {
          title: 'Growth Ideas',
          description: 'Growth tactics to help your company scale.',
          fields: {
            name: {
              label: 'Company or Product Name',
              placeholder: 'Example: Clarice.ai'
            },
            description: {
              label: 'Tell us about your product or service',
              placeholder: 'Example: Intelligent text editor'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Casual and creative'
            }
          }
        },
        companyMission: {
          title: 'Company Mission',
          description:
            'A clear statement of your company’s objectives and purposes.',
          fields: {
            name: {
              label: 'Company or Product Name',
              placeholder: 'Example: Clarice.ai'
            },
            description: {
              label: 'Tell us about the company',
              placeholder: 'Example: Intelligent text editor'
            }
          }
        },
        companyName: {
          title: 'Company or Product Name',
          description:
            'Generate a unique and winning name for your business or product.',
          fields: {
            description: {
              label: 'Tell us more about your business or product',
              placeholder: 'Example: Intelligent text editor'
            },
            keywords: {
              label: 'Keywords',
              placeholder: 'Example: Editor, Artificial Intelligence'
            }
          }
        },
        companyVision: {
          title: 'Company Vision',
          description:
            'A vision that attracts the right people, customers, and employees.',
          fields: {
            name: {
              label: 'Company Name',
              placeholder: 'Example: Clarice.ai'
            },
            description: {
              label: 'Company Description',
              placeholder: 'Example: Intelligent text editor'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Casual and creative'
            }
          }
        },
        facebookAdsTitle: {
          title: 'Facebook Ads Title',
          description:
            'Create high-performing Facebook ad titles to drive more conversions.',
          fields: {
            name: {
              label: 'Company or Product Name',
              placeholder: 'Example: Clarice.ai'
            },
            description: {
              label: 'Tell us about your product or service',
              placeholder: 'Example: Intelligent text editor'
            },
            audience: {
              label: 'Who is your target audience?',
              placeholder: 'Example: Writers'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Casual and creative'
            }
          }
        },
        seoHomepage: {
          title: 'Homepage (SEO)',
          description:
            'Write optimized titles and meta descriptions for your homepage.',
          fields: {
            name: {
              label: 'Company or Product Name',
              placeholder: 'Example: Clarice.ai'
            },
            description: {
              label: 'Product or Service Description',
              placeholder: 'Example: Intelligent text editor'
            },
            keywords: {
              label: 'Keywords',
              placeholder: 'Example: Editor, Artificial Intelligence'
            }
          }
        },
        seoServicePage: {
          title: 'Service Page (SEO)',
          description:
            'Write optimized titles and meta descriptions for your service pages.',
          fields: {
            name: {
              label: 'Company Name',
              placeholder: 'Example: Clarice.ai'
            },
            theme: {
              label: 'Service Name',
              placeholder: 'Example: Clarice.ai Writing Assistant'
            },
            description: {
              label: 'Service Description',
              placeholder: 'Example: Intelligent text editor'
            },
            keywords: {
              label: 'Keywords',
              placeholder: 'Example: Editor, Artificial Intelligence'
            }
          }
        },
        seoProductPage: {
          title: 'Product Page (SEO)',
          description:
            'Write optimized titles and meta descriptions for your product pages.',
          fields: {
            name: {
              label: 'Company Name',
              placeholder: 'Example: Clarice.ai'
            },
            theme: {
              label: 'Product Name',
              placeholder: 'Example: Clarice.ai Writing Assistant'
            },
            description: {
              label: 'Product Description',
              placeholder: 'Example: Intelligent text editor'
            },
            keywords: {
              label: 'Keywords',
              placeholder: 'Example: Editor, Artificial Intelligence'
            }
          }
        },
        seoBlogPost: {
          title: 'Blog Post (SEO)',
          description:
            'Write optimized titles and meta descriptions for your blog posts.',
          fields: {
            name: {
              label: 'Company or Product Name',
              placeholder: 'Example: Clarice.ai'
            },
            title: {
              label: 'Blog Post Title',
              placeholder: 'Example: What is Clarice.ai?'
            },
            description: {
              label: 'Blog Post Description',
              placeholder:
                'Example: Clarice.ai is an intelligent text editor...'
            }
          }
        },
        linkedinAdsTitle: {
          title: 'LinkedIn Ads Title',
          description:
            'Create professional LinkedIn ad titles to get your product noticed.',
          fields: {
            name: {
              label: 'Company or Product Name',
              placeholder: 'Example: Clarice.ai'
            },
            description: {
              label: 'Tell us about your product or service',
              placeholder: 'Example: Intelligent text editor'
            },
            audience: {
              label: 'Who is your target audience?',
              placeholder: 'Example: Writers'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Casual and creative'
            }
          }
        },
        linkedinAdsDescription: {
          title: 'LinkedIn Ads Description',
          description:
            'Create engaging LinkedIn ad descriptions to get your product noticed.',
          fields: {
            name: {
              label: 'Company or Product Name',
              placeholder: 'Example: Clarice.ai'
            },
            description: {
              label: 'Tell us about your product or service',
              placeholder: 'Example: Intelligent text editor'
            },
            audience: {
              label: 'Who is your target audience?',
              placeholder: 'Example: Writers'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Casual and creative'
            }
          }
        },
        linkedinPost: {
          title: 'LinkedIn Post',
          description:
            'Create inspiring and engaging LinkedIn posts to build trust and authority.',
          fields: {
            description: {
              label: 'What is your post about?',
              placeholder: 'Example: How AI will impact jobs.'
            },
            audience: {
              label: 'Who is your target audience?',
              placeholder: 'Example: Writers'
            },
            cta: {
              label: 'Desired Action',
              placeholder: 'Example: Tag a friend in the post'
            }
          }
        },
        twitterPost: {
          title: 'Twitter Post',
          description: 'Generate creative and viral tweets to grow on Twitter.',
          fields: {
            description: {
              label: 'What is your post about?',
              placeholder:
                'Example: New galaxy formation discovered by space telescope...'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Casual and creative'
            }
          }
        },
        googleAdsTitle: {
          title: 'Google Ads Title',
          description: 'Create high-converting titles for your Google ads.',
          fields: {
            name: {
              label: 'Company or Product Name',
              placeholder: 'Example: Clarice.ai'
            },
            description: {
              label: 'Tell us about your product or service',
              placeholder: 'Example: Intelligent text editor'
            },
            audience: {
              label: 'Who is your target audience?',
              placeholder: 'Example: Writers'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Casual and creative'
            }
          }
        },
        googleAdsDescription: {
          title: 'Google Ads Description',
          description:
            'Create high-converting descriptions for your Google ads.',
          fields: {
            name: {
              label: 'Company or Product Name',
              placeholder: 'Example: Clarice.ai'
            },
            description: {
              label: 'Tell us about your product or service',
              placeholder: 'Example: Intelligent text editor'
            },
            audience: {
              label: 'Who is your target audience?',
              placeholder: 'Example: Writers'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Casual and creative'
            }
          }
        },
        resourcesForBenefits: {
          title: 'Features to Benefits',
          description:
            'Turn your product’s features into benefits that motivate action.',
          fields: {
            description: {
              label: 'Product Description',
              placeholder: 'Example: We simplify task automation for agencies.'
            }
          }
        },
        imagePrompt: {
          title: 'Image Prompt',
          description: 'Create image prompts to use with Clarice.ai Art.',
          fields: {
            description: {
              label: 'General idea or theme',
              placeholder: 'Example: A cat wearing yellow boots'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Professional'
            }
          }
        },
        personalBiography: {
          title: 'Personal Biography',
          description:
            'Create a personal biography that highlights your achievements and grabs attention.',
          fields: {
            name: {
              label: 'What is your name?',
              placeholder: 'Example: Felipe'
            },
            description: {
              label: 'Personal Information',
              placeholder:
                'Example: Details about your family, education, and relevant occupations.'
            },
            pointsOfView: {
              label: 'Point of View',
              placeholder: 'Example: First or Third Person'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Professional'
            }
          }
        },
        appNotifications: {
          title: 'App and SMS Notifications',
          description:
            'Create notifications that bring your users back to your app or product.',
          fields: {
            description: {
              label: 'Describe the notification',
              placeholder: 'Example: Reminder to use the features of our app.'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Professional'
            }
          }
        },
        pushNotification: {
          title: 'Push Notification',
          description:
            'Create attractive notifications to keep your users engaged with your product.',
          fields: {
            title: {
              label: 'Title',
              placeholder: 'Enter a title of up to 30 characters...'
            },
            description: {
              label: 'Message',
              placeholder: 'Enter a message of up to 90 characters...'
            }
          }
        },
        aidaStructure: {
          title: 'AIDA Framework',
          description:
            'Use the AIDA marketing framework: Attention, Interest, Desire, Action.',
          fields: {
            name: {
              label: 'Company or Product Name',
              placeholder: 'Example: Clarice.ai'
            },
            description: {
              label: 'Product Description',
              placeholder: 'Example: Intelligent text editor'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Professional'
            }
          }
        },
        pasStructure: {
          title: 'PAS Framework',
          description:
            'A powerful framework for creating effective marketing copy.',
          fields: {
            name: {
              label: 'Company or Product Name',
              placeholder: 'Example: Clarice.ai'
            },
            description: {
              label: 'Product Description',
              placeholder: 'Example: Intelligent text editor'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Professional'
            }
          }
        },
        faqGenerator: {
          title: 'FAQ Generator',
          description:
            'Create frequently asked questions for your posts and pages.',
          fields: {
            description: {
              label: 'Main Subject',
              placeholder: 'Example: Programming course for beginners'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Professional'
            }
          }
        },
        engagingQuestions: {
          title: 'Engaging Questions',
          description:
            'Create creative questions that boost audience engagement.',
          fields: {
            description: {
              label: 'Topic',
              placeholder: 'Example: Cryptocurrency adoption growth'
            },
            audience: {
              label: 'Target Audience',
              placeholder: 'Example: Investors'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Professional'
            }
          }
        },
        jobInterview: {
          title: 'Job Interview',
          description: 'Let Clarice.ai create job interview questions for you.',
          fields: {
            description: {
              label: 'What is the job position?',
              placeholder: 'Example: Developer'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Professional'
            }
          }
        },
        classPlan: {
          title: 'Class Plan',
          description:
            'Create an effective class plan with a clear teaching strategy.',
          fields: {
            content: {
              label: 'Content',
              placeholder: 'Example: Airplane maintenance course'
            },
            audience: {
              label: 'Target Audience',
              placeholder: 'Example: Professional mechanics'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Professional'
            }
          }
        },
        perfectTitle: {
          title: 'Perfect Title',
          description:
            'Create high-converting titles with global copywriting techniques.',
          fields: {
            theme: {
              label: 'Theme',
              placeholder: 'Example: Artificial Intelligence'
            },
            description: {
              label: 'Topic or product description',
              placeholder:
                'Example: How artificial intelligence optimizes your time.'
            },
            audience: {
              label: 'Target Audience',
              placeholder: 'Example: Writers'
            }
          }
        },
        customEmails: {
          title: 'Personalized Cold Emails',
          description:
            'Write personalized cold emails that boost your response rates.',
          fields: {
            name: {
              label: 'Company or Product Name',
              placeholder: 'Example: Clarice.ai'
            },
            description: {
              label: 'Tell us about your company or product',
              placeholder: 'Example: Clarice.ai is an intelligent text editor'
            },
            content: {
              label: 'What is the context or goal of the email?',
              placeholder: 'Example: Schedule a platform demo'
            },
            cta: {
              label: 'Desired Action',
              placeholder:
                'Example: What do you expect the recipient to do after reading the email'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Intelligent'
            }
          }
        },
        googleMyBusiness: {
          title: 'Google My Business',
          description:
            'Maximize your local visibility with a well-optimized Google business profile.',
          fields: {
            location: {
              label: 'Where is it located?',
              placeholder: 'Example: São Paulo'
            },
            name: {
              label: 'Company or Product Name',
              placeholder: 'Example: Clarice.ai'
            },
            description: {
              label: 'What do you sell/offer?',
              placeholder: 'Example: Text correction platform'
            }
          }
        },
        listCreator: {
          title: 'List Creator',
          description:
            'Generate engaging numbered lists based on a central topic.',
          fields: {
            theme: {
              label: 'Theme',
              placeholder: 'Example: Most expensive drinks in the world'
            },
            count: {
              label: 'Number of items',
              placeholder: 'How many items do you want to generate?',
              options: {
                '1': '1',
                '2': '2',
                '3': '3',
                '4': '4',
                '5': '5',
                '6': '6',
                '7': '7',
                '8': '8',
                '9': '9',
                '10': '10'
              }
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Intelligent'
            }
          }
        },
        analogyCreator: {
          title: 'Analogy Creator',
          description:
            'Create unique analogies that make your speech more memorable.',
          fields: {
            theme: {
              label: 'What is the analogy about?',
              placeholder: 'Example: Animals and homes'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Intelligent'
            }
          }
        },
        contentExpander: {
          title: 'Content Expander',
          description:
            'Write the first sentence, and Clarice.ai will continue for you.',
          fields: {
            description: {
              label: 'Content to expand',
              placeholder:
                'Example: Writing is one of the most powerful tools...'
            },
            count: {
              label: 'Size',
              placeholder: 'How many words would you like to expand?',
              options: {
                '50': '50 words',
                '100': '100 words',
                '200': '200 words',
                '300': '300 words',
                '400': '400 words'
              }
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Intelligent'
            }
          }
        },
        smartAnswers: {
          title: 'Smart Answers',
          description: 'Give smart answers to difficult questions.',
          fields: {
            description: {
              label: 'Topic or Question',
              placeholder: 'Example: How to create an efficient server?'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Professional'
            }
          }
        },
        creativeStory: {
          title: 'Creative Story',
          description: 'Create creative stories that captivate your readers.',
          fields: {
            theme: {
              label: 'Plot or theme',
              placeholder: 'Example: Max was a curious and fearless cat...'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Intelligent'
            }
          }
        },
        uniqueValuePropositions: {
          title: 'Unique Value Proposition',
          description:
            'Create a clear statement that highlights your offer’s benefits.',
          fields: {
            theme: {
              label: 'Business description',
              placeholder:
                'Example: Clarice.ai helps simplify content creation.'
            },
            audience: {
              label: 'Target Audience',
              placeholder: 'Example: Writers'
            },
            description: {
              label: 'Problem it solves',
              placeholder:
                'Example: Facilitates professional writing for small businesses.'
            },
            content: {
              label: 'Solution offered',
              placeholder: 'Example: Efficient and quick content creation.'
            }
          }
        },
        linkedinTopicIdeas: {
          title: 'LinkedIn Post Topic Ideas',
          description: 'Find interesting themes for your LinkedIn posts.',
          fields: {
            description: {
              label: 'What is your post about?',
              placeholder: 'Example: How AI is changing the job market.',
              rows: 4
            },
            count: {
              label: 'Number of ideas',
              placeholder: 'How many ideas would you like to generate?',
              options: {
                '1': '1',
                '2': '2',
                '3': '3',
                '4': '4',
                '5': '5',
                '6': '6',
                '7': '7',
                '8': '8',
                '9': '9',
                '10': '10'
              }
            },
            audience: {
              label: 'Target Audience',
              placeholder: 'Example: Marketing professionals'
            },
            cta: {
              label: 'Desired Action',
              placeholder: 'Example: Tag a colleague in the post'
            }
          }
        },
        instagramTopicIdeas: {
          title: 'Instagram Reels Ideas',
          description:
            'Find interesting topics to create engaging Instagram reels.',
          fields: {
            description: {
              label: 'What are your brand’s values?',
              placeholder: 'Example: Innovation, Creativity.'
            },
            count: {
              label: 'Number of ideas',
              placeholder: 'How many ideas would you like to generate?',
              options: {
                '1': '1',
                '2': '2',
                '3': '3',
                '4': '4',
                '5': '5',
                '6': '6',
                '7': '7',
                '8': '8',
                '9': '9',
                '10': '10'
              }
            },
            audience: {
              label: 'Target Audience',
              placeholder: 'Example: Young entrepreneurs'
            },
            cta: {
              label: 'Desired Action',
              placeholder: 'Example: Tag a friend in the post'
            }
          }
        },
        instagramAdsDescription: {
          title: 'Instagram Ad',
          description: 'Create engaging ads to boost conversions on Instagram.',
          fields: {
            theme: {
              label: 'Theme',
              placeholder: 'Example: Artificial Intelligence'
            },
            description: {
              label: 'Product or post description',
              placeholder: 'Example: How AI can help create better content.'
            },
            audience: {
              label: 'Target Audience',
              placeholder: 'Example: Marketing professionals'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Casual and creative'
            }
          }
        },
        adsIdeas: {
          title: 'Ad Ideas',
          description: 'Get ad ideas for your brand, product, or service.',
          fields: {
            name: {
              label: 'Company or Product Name',
              placeholder: 'Example: Clarice.ai'
            },
            description: {
              label: 'Tell us about your product or service',
              placeholder: 'Example: Intelligent text editor'
            },
            audience: {
              label: 'Who is your target audience?',
              placeholder: 'Example: Writers'
            },
            tone: {
              label: 'Tone of Voice',
              placeholder: 'Example: Casual and creative'
            }
          }
        }
      },
      badge: {
        shortly: 'Coming soon'
      },
      action: {
        favorite: 'Mark as favorite',
        unfavorite: 'Unmark as favorite',
        clean: 'Clear',
        generate: 'Generate',
        generateNewOutputs: {
          tooltip: 'Generate New Outputs'
        },
        viewHistory: {
          tooltip: 'View History'
        },
        clear: {
          tooltip: 'Clear Outputs'
        },
        copy: {
          tooltip: 'Copy Message'
        },
        review: 'Review'
      },
      language: {
        title: 'Language Options',
        input: {
          label: 'Input Language',
          options: {
            portuguese: 'Portuguese',
            english: 'English',
            spanish: 'Spanish'
          }
        },
        output: {
          label: 'Output Language',
          options: {
            portuguese: 'Portuguese',
            english: 'English',
            spanish: 'Spanish'
          }
        }
      },
      formality: {
        label: 'Formality',
        options: {
          default: 'Default',
          lessFormal: 'Less Formal',
          moreFormal: 'More Formal'
        }
      },
      quantity: {
        label: 'Quantity',
        placeholder: 'Quantity'
      },
      output: {
        title: 'Fill in the fields and watch the magic happen',
        description: {
          one: 'For the best results',
          two: 'try different inputs of varying sizes.'
        }
      },
      historicNotFound: 'No history found...'
    },
    chat: {
      title: 'Chat',
      description: {
        one: 'Choose a prompt below or write your own to start chatting with <strong>Clarice.ai</strong>.',
        two: 'Use this chat to talk with <strong>Clarice.ai</strong> and ask for writing tips. To speak with support, use the other chat.'
      },
      form: {
        message: {
          label: 'Type a message',
          placeholder: 'Type a message...'
        }
      },
      action: {
        submit: 'Send',
        clean: 'Clear',
        like: {
          tooltip: 'Good response!'
        },
        dislike: {
          tooltip: 'Bad response!'
        },
        refresh: {
          tooltip: 'Try again'
        },
        copy: {
          tooltip: 'Copy message'
        },
        hide: {
          tooltip: 'Hide in Clarice.ai'
        },
        view: {
          tooltip: 'Show in Clarice.ai'
        }
      },
      prompts: {
        title: 'Prompts',
        options: {
          one: 'Create a list of 10 blog post titles',
          two: 'Write a paragraph about',
          three: 'Help me create a story about',
          four: 'Write a song about',
          five: 'Summarize the following text',
          six: 'Write a Facebook ad for the holiday season',
          seven: 'Write a blog post about',
          eight: 'Develop a short story about'
        }
      }
    },
    documents: {
      search: {
        label: 'Search',
        placeholder: 'Search by title...'
      },
      action: {
        create: 'New Text',
        update: 'Edit',
        delete: 'Delete'
      },
      loading: 'Loading...',
      notFound: 'No texts found...',
      notAdded: 'No texts added so far...',
      notAddedText: 'You haven’t added any texts yet. How about starting now?',
      updated: 'Updated',
      untitled: 'Untitled',
      modal: {
        delete: {
          title: 'Delete Document',
          description: 'Are you sure you want to delete this document?',
          action: {
            cancel: 'Cancel',
            confirm: 'Delete'
          }
        }
      },
      limit: {
        free: {
          equal: {
            one: 'You have reached the limit of saved texts. To add new texts',
            two: 'become PRO'
          },
          greater: ''
        },
        paid: {
          equal: {
            one: 'You have reached the limit of saved texts. For now, I can only save',
            two: 'texts in the PRO version.'
          },
          greater: {
            one: 'You have already reached the limit of saved texts. To add new texts,',
            two: 'upgrade your plan'
          }
        }
      }
    },
    review: {
      form: {
        title: {
          label: 'Title',
          placeholder: 'Add a title.'
        },
        editor: {
          label: 'Editor',
          placeholder: 'Paste your text here or press "/" to write with AI.'
        }
      },
      action: {
        viewDetails: 'View Details',
        summary: 'Summarization',
        copy: 'Copy Text',
        process: {
          label: 'Get Suggestions',
          tooltip: 'Process Text',
          loading: 'Loading'
        }
      },
      metrics: {
        words: 'Words',
        characters: 'Characters',
        sentences: 'Sentences',
        paragraphs: 'Paragraphs'
      },
      score: {
        title: 'Text Score'
      },
      suggestion: {
        category: {
          all: {
            name: 'All Suggestions'
          },
          correction: {
            name: 'Correction',
            tooltip: 'Improves spelling, grammar, and punctuation.'
          },
          clarity: {
            name: 'Clarity',
            tooltip: 'Helps make your writing easier to understand.'
          },
          conciseness: {
            name: 'Conciseness',
            tooltip: 'Conveys the message directly and clearly.'
          },
          strength: {
            name: 'Strength',
            tooltip: 'Enhances the impact and persuasion of your text.'
          },
          originality: {
            name: 'Originality',
            tooltip:
              'Helps create a unique and authentic text, offering fresh and creative ideas.'
          },
          premium: {
            name: 'PRO',
            tooltip:
              'Takes your writing to the next level with impeccable correction, exceptional clarity, and impactful results.'
          }
        }
      },
      card: {
        accept: 'Corrected',
        dismiss: 'Dismiss',
        add: 'Add',
        disable: 'Disable Rule',
        falsePositive: 'False Positive'
      },
      language: {
        title: 'Review Language',
        options: {
          portuguese: 'Portuguese',
          english: 'English',
          spanish: 'Spanish'
        }
      }
    },
    tools: {
      title: 'Tools',
      list: {
        review: {
          title: 'Review Text',
          description: 'Review your text and get suggestions for improvement.'
        },
        rewriteText: {
          title: 'Rewrite Text',
          description: 'Rewrite the text without changing its original meaning.'
        },
        contentSummarizer: {
          title: 'Summarize Text',
          description: 'Get a summary with the key points of a text.'
        },
        translate: {
          title: 'Translate Text',
          description: 'Translate texts into different languages.',
          fields: {
            content: {
              label: 'Text',
              placeholder: 'Type or paste a text to translate...'
            },
            language: {
              input: {
                label: 'Input Language',
                options: {
                  detect: 'Detect Language',
                  portuguese: 'Portuguese',
                  english: 'English',
                  spanish: 'Spanish'
                }
              },
              output: {
                label: 'Output Language',
                options: {
                  portuguese: 'Portuguese',
                  english: 'English',
                  spanish: 'Spanish'
                }
              }
            }
          },
          action: {
            generate: 'Translate'
          }
        },
        aiDetect: {
          title: 'AI Detector',
          description: 'Check if a text was generated by AI.',
          fields: {
            content: {
              label: 'Text',
              placeholder: 'Type or paste a text...'
            },
            language: {
              input: {
                label: 'Input Language',
                options: {
                  detect: 'Detect Language',
                  portuguese: 'Portuguese',
                  english: 'English',
                  spanish: 'Spanish'
                }
              },
              output: {
                label: 'Output Language',
                options: {
                  portuguese: 'Portuguese',
                  english: 'English',
                  spanish: 'Spanish'
                }
              }
            }
          },
          action: {
            generate: 'Analyze Text'
          },
          result: {
            title: 'of text is likely AI-generated',
            ai: 'AI-generated',
            human: 'Human-written'
          }
        },
        plagiarism: {
          title: 'Plagiarism Checker',
          description: 'Check if a text is original or has been copied.'
        }
      },
      badge: {
        shortly: 'Coming soon'
      }
    },
    agents: {
      title: 'Agents'
    },
    plans: {
      title: 'Try for free for 7 days',
      description: 'And find the plan that best suits your business needs',
      securePayment: 'Guaranteed and secure payment',
      actions: {
        freeTrial: 'Start free trial ➜',
        buyNow: 'Buy now ➜',
        contact: 'Contact us ➜'
      },
      consult: 'Consult',
      customizedCharges: 'Customized charges',
      loading: 'Loading...',
      toggle: {
        monthly: 'Monthly',
        yearly: 'Yearly'
      },
      bestPlan: 'Best Plan',
      saveMessage: '<strong>Save 3 MONTHS</strong> with the Annual Plan',
      billedAnnually: 'Billed annually',
      recurringPayment: 'Recurring payment',
      perMonth: 'Per month in the <b>Monthly Plan</b>',
      wordLimit: '100,000 words per month',
      wordUnlimited: 'Unlimited words',
      month: 'month',
      currency: '$',
      creative: {
        title: 'Creative',
        subtitle: 'For content creators who need to scale their work',
        description:
          "With Clarice.ai's advanced suggestions, go beyond grammar and ensure clear, concise, strong, and original writing.",
        benefits: {
          one: 'AI Reviewer + AI Editor + AI Writer',
          two: '65+ templates',
          three: 'ChatGPT',
          four: 'Google Docs Plugin',
          five: 'Up to 50 texts',
          six: 'Up to 10 projects',
          seven: 'Email support'
        }
      },
      professional: {
        title: 'Professional',
        subtitle: 'Everything you need to write 10x faster professionally',
        description:
          'With AI Reviewer and AI Writer tools, you have powerful solutions to write and review texts and full content.',
        benefits: {
          one: 'AI Reviewer + AI Editor + AI Writer',
          two: '65+ templates',
          three: 'ChatGPT',
          four: 'Google Docs Plugin',
          five: 'Unlimited texts',
          six: 'Unlimited projects',
          seven: 'Premium support'
        }
      },
      company: {
        title: 'Company',
        subtitle:
          'For companies that need secure, scalable, and tailor-made AI',
        description:
          'Boost your results with Clarice.ai through customized credit/user packages and exclusive support.',
        benefits: {
          one: 'AI Reviewer + AI Editor + AI Writer',
          two: 'Brand Manual',
          three: 'Minimum of 5 members',
          four: 'Centralized Billing',
          five: 'Google Docs Plugin',
          six: 'Team Management',
          seven: 'Training and Premium Support'
        }
      },
      lite: {
        monthly: {
          title: 'Monthly'
        },
        semiAnnual: {
          title: 'Semi-Annual'
        },
        yearly: {
          title: 'Yearly'
        }
      },
      discount: {
        ANUAL40: {
          description:
            'Use the coupon <b>ANUAL40</b> and get 40% off the annual subscription. Enjoy! 🎉'
        }
      }
    }
  },
  standard: {
    channels: {
      one: 'Need help?',
      two: 'Visit our channels'
    },
    button: {
      back: 'Voltar'
    }
  },
  notify: {
    profile: {
      update: {
        success: 'Profile successfully updated!'
      }
    },
    editProfile: {
      success: 'Profile data successfully updated!'
    },
    project: {
      create: {
        success: 'Project successfully created!'
      },
      update: {
        success: 'Project successfully updated!'
      },
      delete: {
        success: 'Project successfully deleted!'
      }
    },
    brand: {
      create: {
        required: {
          all: 'Please fill out all the fields!'
        },
        loading: 'Creating the brand...',
        success: 'Brand successfully created!',
        error: 'An error occurred, please try again'
      },
      update: {
        required: {
          all: 'Please fill out all the fields!'
        },
        loading: 'Editing the brand...',
        success: 'Brand successfully edited!',
        error: 'An error occurred, please try again.'
      },
      delete: {
        success: 'Brand successfully deleted!'
      },
      generateWithIA: {
        required: {
          all: 'Please fill out all the fields!',
          description: 'Please fill out the description field!'
        },
        loading: 'Creating the brand with AI...',
        success: 'Brand successfully created!',
        error: 'An error occurred, please try again'
      }
    },
    rule: {
      enable: {
        success: 'Rule successfully reactivated!'
      }
    },
    dictionary: {
      add: {
        success: 'Word successfully added!'
      },
      delete: {
        success: 'Word successfully deleted!'
      }
    },
    suggestion: {
      required: {
        all: 'Please fill out all fields!'
      },
      success: 'Thank you for the suggestion! :)'
    },
    business: {
      create: {
        success: 'Business successfully created!'
      },
      update: {
        success: 'Business successfully updated!'
      },
      user: {
        add: {
          required: {
            all: 'Please fill out all fields!'
          },
          success: 'Invitation successfully sent!'
        },
        delete: {
          success: 'User successfully removed!'
        }
      }
    },
    backoffice: {
      business: {
        create: {
          required: {
            all: 'Please fill out all fields!'
          },
          success: 'Company successfully created!'
        },
        update: {
          required: {
            all: 'Please fill out all fields!',
            name: 'Please enter the company name!',
            licenses: 'Please enter the number of licenses!',
            startDate: 'Please enter the start date!',
            endDate: 'Please enter the end date!'
          },
          success: 'Company successfully updated!'
        },
        delete: {
          success: 'Company successfully deleted!'
        },
        user: {
          add: {
            required: {
              all: 'Please fill out all fields!'
            },
            success: 'User successfully added!'
          },
          delete: {
            success: 'User successfully removed!'
          }
        }
      }
    },
    templates: {
      create: {
        required: {
          all: 'Fill in all the fields!'
        },
        loading: 'Processing response...',
        success: 'Response generated successfully!',
        error: 'An error occurred, please try again.'
      },
      copyText: 'Text successfully copied!',
      createText: 'Your text has been created!'
    },
    tools: {
      translate: {
        required: {
          all: 'Please fill out all fields!'
        },
        loading: 'Translating text...',
        success: 'Text translated successfully!',
        error: 'An error occurred, please try again.'
      },
      aiDetect: {
        required: {
          all: 'Please fill out all fields!'
        },
        loading: 'Analyzing text...',
        success: 'Text successfully analyzed!',
        error: 'An error occurred, please try again.'
      },
      copyText: 'Text copied successfully!'
    },
    chat: {
      required: {
        message: {
          notEmpty: 'Please enter a message!',
          maxLength: 'Message too long, maximum 2000 characters!'
        }
      },
      limit: 'You have reached the credit limit!'
    },
    documents: {
      delete: {
        success: 'Document successfully deleted!',
        error:
          'An error occurred while deleting your document, please try again.'
      }
    },
    mailConfirmation: {
      success: 'Email successfully confirmed!'
    }
  },
  sidebar: {
    route: {
      home: 'Home',
      plans: 'Plans',
      templates: 'Templates',
      chat: 'Chat',
      editText: 'Text Editing',
      projects: 'Projects',
      brands: 'Brand Hub',
      createBrand: 'Create Brand',
      updateBrand: 'Edit Brand',
      updateBusiness: 'Edit Business',
      backoffice: 'Backoffice',
      review: 'Review Text',
      rewriter: 'Text Rewriter',
      summarizer: 'Text Summarizer',
      translate: 'Text Translator',
      aiDetect: 'AI Detector',
      disabledRules: 'Disabled Rules',
      dictionary: 'Dictionary',
      account: {
        profile: 'Profile',
        update: 'Edit Profile',
        credits: 'Credits and Billing',
        business: 'My Business'
      },
      help: 'Send Feedback',
      documents: 'Documents',
      agents: 'Agents'
    },
    projects: {
      title: 'Projects',
      action: {
        view: 'View Projects',
        new: 'New Project'
      }
    },
    brands: {
      title: 'Brands',
      action: {
        view: 'View Brands',
        new: 'New Brand'
      },
      notSelected: 'Not selected'
    },
    account: {
      profile: 'Profile',
      business: 'My Business',
      backoffice: 'Backoffice',
      credits: 'Credits and Billing',
      logout: 'Logout'
    },
    conversion: {
      generateWords: 'Generated Words',
      paid: 'Upgrade',
      free: 'Become PRO',
      freeDays: '7 Days Free'
    }
  },
  navbar: {
    useHistory: {
      mobile: 'History',
      desktop: 'Use History'
    }
  },
  brand: {
    select: 'Select a brand',
    tag: 'Brand selected for content creation.'
  },
  modal: {
    acceptedTerms: {
      title: 'Terms of Use, Privacy Policy, and Payment Policy',
      info: `<div><p> Hello! We would like to inform you that we have recently updated our Terms of Use, Privacy Policy, and Payment Policy. <br/> <br/> These changes were made to better reflect our services and ensure greater transparency and security for you, our user. </p> <strong>What was updated?</strong> <br/> <br/> <strong>Terms of Use</strong>: We updated our Terms of Use to clarify the rights and responsibilities of all users of our platform. <br/> <br/> <strong>Privacy Policy</strong>: We revised our Privacy Policy to detail how we collect, use, and protect your personal information. <br/> <br/> <strong>Payment Policy</strong>: We made adjustments to our Payment Policy to ensure clarity about billing processes, refunds, and other financial aspects. <br /> <br /> To continue using our services, we ask that you read and agree to the new terms. <br/> <br/> <strong>Next Steps</strong> <br/> <p style='margin-Left: 20px'> 1. Carefully read the new Terms of Use, Privacy Policy, and Payment Policy. <br/> 2. Scroll to the end of this document. <br /> 3. Check the box confirming that you have read and agree to the new terms. <br/> 4. Click the Accept button. </p> If you have any questions or need further information, please contact our customer support. <br/> <br/> Thank you for continuing to use Clarice.ai.</>`,
      checkBox: {
        one: 'I have read and accept the',
        two: 'terms of use',
        three: 'privacy policy',
        four: 'payment policy'
      },
      action: {
        confirm: 'Accept'
      }
    },
    disabledRules: {
      title: 'Disabled Rules',
      notFound: 'No disabled rules at the moment...',
      loading: 'Loading...',
      action: {
        enable: 'Activate'
      }
    },
    dictionary: {
      title: 'My Dictionary',
      notFound: 'No word found...',
      notAdded: 'No word added yet...',
      form: {
        word: {
          placeholder: 'Enter a word...'
        }
      },
      action: {
        add: 'Add',
        delete: 'Delete'
      }
    },
    suggestion: {
      title: 'Suggestion',
      description: 'Write with as many details as you find necessary.',
      form: {
        subject: {
          label: 'What is the subject?'
        },
        description: {
          label: 'Tell me your suggestion in detail :)'
        }
      },
      action: {
        cancel: 'Cancel',
        send: 'Send'
      }
    },
    conversion: {
      free: {
        one: {
          title: 'Your credits are running low!',
          description:
            "But don't worry! With just a few clicks, you can purchase one of our PRO plans and renew your limit. Remember: once your credits run out, you won't be able to generate more words with AI."
        },
        two: {
          title: 'Your credits have run out!',
          description:
            "But don't worry! With just a few clicks, you can purchase one of our PRO plans and renew your limit. Remember: once your credits run out, you won't be able to generate more words with AI."
        },
        three: {
          title: 'Your credits have run out :(',
          description:
            "But don't worry! With just a few clicks, you can purchase one of our PRO plans and renew your limit."
        },
        action: 'Become PRO',
        actionFreeDays: 'Get 7 days free'
      },
      paid: {
        one: {
          title: 'Your credits are running low!',
          description:
            "But don't worry! With just a few clicks, you can upgrade your plan and renew your limit. Remember: once your credits run out, you won't be able to generate more words with AI."
        },
        two: {
          title: 'Your credits are running low!',
          description:
            "But don't worry! With just a few clicks, you can upgrade your plan and renew your limit. Remember: once your credits run out, you won't be able to generate more words with AI."
        }
      },
      three: {
        title: 'Your credits have run out :(',
        description:
          "But don't worry! With just a few clicks, you can upgrade your plan and renew your limit."
      },
      action: 'Upgrade'
    },
    summary: {
      title: 'Summary',
      notText:
        'Please provide a text so that the summarization process can be performed.'
    },
    onboarding: {
      step: {
        one: {
          title: 'Select a word to search for synonyms',
          description:
            'The <strong>Find Synonyms</strong> feature enhances your texts by providing suggestions of words with similar meanings.'
        },
        two: {
          title:
            'Highlight a section of text and get editing and improvement suggestions',
          description:
            'Use <strong>AI Adjustments</strong> to simplify, shorten, improve, and change the tone and voice of your text in a snap.'
        },
        three: {
          title: 'Automatically correct deviations with the help of AI',
          description:
            'With <strong>Auto Correction</strong>, you receive suggestions to fix every deviation in your text with a single click.'
        },
        four: {
          title: 'Type “/” and see how Clarice.ai suggests creative ideas.',
          description:
            'Hit a block? Use <strong>AI Writing</strong> to overcome creative obstacles. Only take a break for coffee ☕.'
        }
      },
      action: {
        close: 'Close',
        next: 'Next'
      }
    },
    score: {
      performance: {
        title: 'Performance',
        description:
          'The score on the side represents the writing quality of this document. You can improve it by following the suggestions from Clarice.ai.'
      },
      metrics: {
        title: 'Text Metrics',
        options: {
          paragraphs: 'Paragraphs',
          sentences: 'Sentences',
          words: 'Words',
          characters: 'Characters',
          readingTime: 'Reading Time',
          speakingTime: 'Speaking Time'
        }
      },
      readability: {
        title: 'Readability',
        options: {
          wordLength: 'Average Word Length',
          sentenceLength: 'Average Words per Sentence',
          readabilityScore: {
            title: 'Score',
            description:
              'The score ranges from 0 to 100. Higher scores indicate that the text is easier to read.'
          }
        }
      },
      legibility: {
        title: 'Legibility',
        options: {
          wordLength: 'Average Word Length',
          sentenceLength: 'Average Words per Sentence',
          complexWord: {
            title: 'Complex Words',
            description:
              'We measure vocabulary depth by identifying words that are not among the 5,000 most commonly used in Portuguese.'
          },
          readabilityScore: {
            title: 'Readability Score',
            description:
              'The score ranges from 0 to 100. Higher scores indicate that the text is easier to read.'
          },
          formality: 'Formality',
          genre: 'Gender',
          sentiment: 'Sentiment'
        }
      },
      time: {
        minutes: 'min',
        seconds: 'sec'
      }
    }
  },
  banner: {
    discount: {
      ANUAL40: {
        title: '40% off! 🎉',
        description:
          'Use the coupon <b>ANUAL40</b> and get 40% off the annual subscription. Don’t miss out!'
      },
      LINGUAPT50OLD: {
        title:
          'On May 5th, we celebrate <b><br />World Portuguese Language Day!</b> 🌍🎉',
        description:
          'Use the coupon <b>LINGUAPT50</b> and get 50% off the annual subscription. Don’t miss out!'
      },
      LINGUAPT50: {
        title: 'National Portuguese Language Week 📚🎉',
        description:
          'Use the coupon <b>LINGUAPT50</b> and get 50% off the annual subscription. Don’t miss out!'
      },
      WS50: {
        title: 'Clarice.ai at Web Summit 🥳',
        description:
          'Use the code <b>WS50</b> to get 50% off the annual subscription. Don’t miss out!'
      },
      action: 'Subscribe now'
    },
    googleDocs: {
      title: 'Clarice on Google Docs!',
      description:
        'Try out our extension for Google Docs! Install it to get real-time feedback from Clarice.ai.',
      action: 'Install for Free'
    }
  },
  shortcut: {
    writer: {
      description: 'Ask Clarice to write...',
      option: '',
      loading: 'Clarice is writing...'
    },
    complete: {
      description: 'Complete text',
      option: '',
      loading: 'Completing the text...'
    },
    createParagraph: {
      description: 'Compose paragraph',
      option: 'Write a paragraph about',
      loading: 'Composing paragraph...'
    },
    brainstorm: {
      description: 'Generate ideas',
      option: 'Generate ideas about',
      loading: 'Generating ideas...'
    },
    blogPost: {
      description: 'Create blog post',
      option: 'Write a blog post about'
    },
    newGenerate: {
      action: 'Generate new suggestions',
      loading: 'Generating new suggestions...'
    },
    fields: {
      text: {
        label: 'Ask the AI to write anything you want...',
        placeholder: 'Ask the AI to write anything you want...'
      }
    },
    simplify: {
      description: 'Simplify',
      loading: 'Simplifying text...'
    },
    shorten: {
      description: 'Shorten',
      loading: 'Shortening text...'
    },
    better: {
      description: 'Improve',
      loading: 'Improving text...'
    },
    tone: {
      description: 'Change tone',
      loading: 'Changing tone...',
      options: {
        back: 'Back',
        friendly: 'Friendly tone',
        assertive: 'Assertive tone',
        constructive: 'Constructive tone',
        descriptive: 'Descriptive tone',
        happy: 'Happy tone',
        modest: 'Modest tone',
        professional: 'Professional tone',
        neutral: 'Neutral tone',
        optimistic: 'Optimistic tone',
        reflective: 'Reflective tone'
      }
    }
  }
};

export default LANGUAGE_EN;

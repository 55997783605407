/* eslint-disable no-undef */
import instance from '../../utils/axios/instance';

const DocumentsApi = {
  getAllDocuments: async () => {
    const { data } = await instance().get(`/texts`);
    return data;
  },
  getDocumentById: async (id: string) => {
    const { data } = await instance().get(`/texts/${id}`);

    const spelling: any = [];

    data.corrections.spellingAndGrammar.forEach((item: any) => {
      delete item.score;

      spelling.push({
        ...item,
        type: 'correcao',
        spellingAndGrammar: true,
        score: {
          correcao: 2
        }
      });
    });

    data.corrections.deviations = [...data.corrections.deviations, ...spelling];

    data.corrections.spellingAndGrammar = [];

    data.corrections.doubts = [];

    data.corrections.deviations.sort(
      (a: any, b: any) => a.spans[0].offset - b.spans[0].offset
    );

    return data;
  },
  getNewDocument: async () => {
    const { data } = await instance().get(`/texts/new`);
    return data;
  },
  deleteDocument: async (id: string) => {
    const { data } = await instance().delete(`/texts/${id}`);
    return data;
  }
};

export default DocumentsApi;

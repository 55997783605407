import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  container: {
    padding: theme.spacing(4),
    textAlign: 'center'
  },
  titleContainer: {
    marginBottom: theme.spacing(2)
  },
  percentage: {
    marginTop: '30px!important',
    fontSize: '24px!important',
    fontWeight: 'bold!important'
  },
  label: {
    fontSize: '1rem',
    color: theme.palette.text.secondary
  },
  progressBar: {
    margin: theme.spacing(2, 0),
    height: '15px',
    borderRadius: '8px',
    backgroundColor: `${
      theme.palette.mode === 'light' ? '#f0f0f0' : theme.palette.secondary.main
    }!important`
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  circleIcon: {
    fontSize: 'small',
    marginRight: theme.spacing(1)
  },
  percentageText: {
    fontWeight: 'bold'
  }
}));

export default useStyles;

import React, { useContext, useEffect } from 'react';
import { ThemeContext } from '../../utils/context/ThemeContext';
import { Box, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';
import GlobalStyle from './components/GlobalStyle';
import { TranslateInterface } from '../../utils/interfaces/TranslateInterface';
import useLanguage from '../../utils/hooks/useLanguage';

const AuthLayout = () => {
  const themeMode = useContext(ThemeContext);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const translation: TranslateInterface = useLanguage();

  useEffect(() => {
    if (location.pathname === '/sign-up') {
      let language = localStorage.getItem('i18nextLng') || '';

      if (location?.search.includes('language=pt')) {
        language = 'pt';
      }

      if (location?.search.includes('language=en')) {
        language = 'en';
      }

      if (location?.search.includes('language=es')) {
        language = 'es';
      }

      localStorage.setItem('i18nextLng', language);

      translation.settings.changeLanguage(language);
    }
  }, [location]);

  return (
    <Box sx={{ display: 'flex', background: '#ffffff' }}>
      <GlobalStyle />
      <Outlet />
    </Box>
  );
};

export default AuthLayout;

/* eslint-disable no-undef */
import React, { useContext } from 'react';
import { Box, Button, useTheme } from '@mui/material';
import useStyles from './style';
import ModalScore from '../CorrectionsOverview/components/ModalScore';
import CircularScore from '../CircularScore';
import { useReduxState } from '../../../../../../hooks/useReduxState';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import { styled } from '@mui/material/styles';
import Badge, { BadgeProps } from '@mui/material/Badge';
import ModalSummary from '../CorrectionsOverview/components/ModalSummary';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: 'rgb(228, 76, 93)',
    color: 'white'
  }
}));

const StyledBadgeDoubts = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: 'rgb(7, 141, 255)!important',
    color: 'white'
  }
}));

const CorrectionsOverviewMobile = ({
  score,
  scoresModalOpen,
  openScoresModal,
  setActiveCategory,
  summaryModalOpen,
  openSummaryModal
}: any) => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { translate }: TranslateInterface = useLanguage();

  const { user, documents, editor } = useReduxState();

  return (
    <>
      <ModalScore
        open={scoresModalOpen}
        toggle={() => {
          openScoresModal();
        }}
      />

      <ModalSummary
        open={summaryModalOpen}
        toggle={() => {
          openSummaryModal();
        }}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          width: '100%',
          marginBottom: '0.5rem'
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          // p={'19px 6px'}
          mb={'0.0rem'}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            mt={'7px'}
          >
            <h2 className={classes.scoreText}>
              {translate('page.review.score.title')}
            </h2>
          </Box>
          <Box
            pt={'10px'}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
          >
            <CircularScore
              size={70}
              value={59}
              score={score.text || 0}
              // score={editor.process?.readability.score.flesh || 0}
              optionalClick={() => {
                /* logEvent('score-details', 'show'); */
                // setScoresModalOpen(!scoresModalOpen);
              }}
            />
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            mt={'7px'}
          >
            <Button
              variant="outlined"
              size="small"
              onClick={() => openScoresModal()}
              style={{
                width: '90px',
                fontFamily: 'Inter',
                fontSize: '10px',
                fontWeight: '600',
                // backgroundColor:
                //   themeMode.mode === 'light' ? '#09B286' : '#3a3541',
                borderColor: themeMode.mode === 'light' ? '#39274B' : '#3a3541',
                color:
                  theme.palette.mode === 'light'
                    ? '#39274B'
                    : theme.palette.text.white,
                letterSpacing: '0.1px',
                cursor: 'pointer',
                textTransform: 'unset',
                marginTop: '5px'
              }}
            >
              {translate('page.review.action.viewDetails')}
            </Button>
          </Box>

          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            mt={'5px'}
          >
            <Button
              variant="outlined"
              size="small"
              onClick={() => openSummaryModal()}
              style={{
                width: '90px',
                fontFamily: 'Inter',
                fontSize: '10px',
                fontWeight: '600',
                // backgroundColor:
                //   themeMode.mode === 'light' ? '#09B286' : '#3a3541',
                borderColor: themeMode.mode === 'light' ? '#410A85' : '#09B286',
                color: themeMode.mode === 'light' ? '#410A85' : '#09B286',
                letterSpacing: '0.1px',
                cursor: 'pointer',
                textTransform: 'unset',
                marginTop: '5px'
              }}
            >
              {translate('page.review.action.summary')}
            </Button>
          </Box>
        </Box>

        {/*<StyledBadge*/}
        {/*  badgeContent={documents.detail?.corrections.deviations.length}*/}
        {/*  color="error"*/}
        {/*>*/}
        {/*  <Button*/}
        {/*    variant={*/}
        {/*      editor.activeCategory === 'deviations' ? 'contained' : 'outlined'*/}
        {/*    }*/}
        {/*    sx={{*/}
        {/*      width: '100%',*/}
        {/*      padding: '0.5rem 0.5rem',*/}
        {/*      textTransform: 'none!important',*/}
        {/*      backgroundColor:*/}
        {/*        editor.activeCategory === 'deviations'*/}
        {/*          ? '#09B286!important'*/}
        {/*          : 'transparent',*/}
        {/*      color:*/}
        {/*        editor.activeCategory === 'deviations' ? 'white' : '#09B286',*/}
        {/*      borderColor: '#09B286'*/}
        {/*    }}*/}
        {/*    onClick={() => {*/}
        {/*      setActiveCategory('deviations');*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    Desvios de estilo*/}
        {/*  </Button>*/}
        {/*</StyledBadge>*/}

        {/*<StyledBadge*/}
        {/*  badgeContent={documents.detail?.corrections.spellingAndGrammar.length}*/}
        {/*  color="error"*/}
        {/*>*/}
        {/*  <Button*/}
        {/*    variant={*/}
        {/*      editor.activeCategory === 'spellingAndGrammar'*/}
        {/*        ? 'contained'*/}
        {/*        : 'outlined'*/}
        {/*    }*/}
        {/*    sx={{*/}
        {/*      width: '100%',*/}
        {/*      padding: '0.5rem 0.5rem',*/}
        {/*      textTransform: 'none!important',*/}
        {/*      backgroundColor:*/}
        {/*        editor.activeCategory === 'spellingAndGrammar'*/}
        {/*          ? '#09B286!important'*/}
        {/*          : 'transparent',*/}
        {/*      color:*/}
        {/*        editor.activeCategory === 'spellingAndGrammar'*/}
        {/*          ? 'white'*/}
        {/*          : '#09B286',*/}
        {/*      borderColor: '#09B286'*/}
        {/*    }}*/}
        {/*    onClick={() => {*/}
        {/*      setActiveCategory('spellingAndGrammar');*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    Gramática e Ortografia*/}
        {/*  </Button>*/}
        {/*</StyledBadge>*/}

        {/*{user.detail?.isSubscriber && (*/}
        {/*  <StyledBadgeDoubts*/}
        {/*    badgeContent={documents.detail?.corrections?.doubts?.length || 0}*/}
        {/*    color="error"*/}
        {/*  >*/}
        {/*    <Button*/}
        {/*      variant={*/}
        {/*        editor.activeCategory === 'doubts' ? 'contained' : 'outlined'*/}
        {/*      }*/}
        {/*      sx={{*/}
        {/*        width: '100%',*/}
        {/*        padding: '0.5rem 0.5rem',*/}
        {/*        textTransform: 'none!important',*/}
        {/*        backgroundColor:*/}
        {/*          editor.activeCategory === 'doubts'*/}
        {/*            ? '#09B286!important'*/}
        {/*            : 'transparent',*/}
        {/*        color: editor.activeCategory === 'doubts' ? 'white' : '#09B286',*/}
        {/*        borderColor: '#09B286'*/}
        {/*      }}*/}
        {/*      onClick={() => {*/}
        {/*        setActiveCategory('doubts');*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      Dúvidas*/}
        {/*    </Button>*/}
        {/*  </StyledBadgeDoubts>*/}
        {/*)}*/}
      </div>
    </>
  );
};
export default CorrectionsOverviewMobile;

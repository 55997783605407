import { BrandTagInterface } from './interface';
import { Grid, Box } from '@mui/material';
import useStyles from './style';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import { useReduxState } from '../../../../../../hooks/useReduxState';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import TooltipContainer from '../../../../components/TooltipContainer';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';

const BrandTag = ({}: BrandTagInterface) => {
  const classes: any = useStyles();

  const { translate }: TranslateInterface = useLanguage();

  const themeMode = useContext(ThemeContext);

  const { user, brands } = useReduxState();

  const [activeBrand, setActiveBrand] = useState<any>('');

  useEffect(() => {
    const active = brands?.all.find(
      el => String(el._id) === String(user?.options?.brand?._id)
    );

    if (active) {
      setActiveBrand(active || '');
    } else {
      setActiveBrand('');
    }
  }, [brands, user]);

  if (activeBrand?.name) {
    return (
      <Box className={classes.container}>
        <TooltipContainer title={translate('brand.tag')} placement="right">
          <div className={classes.description}>
            <TurnedInIcon />
            <span style={{ marginLeft: '2px', paddingBottom: '1px' }}>
              {activeBrand?.name || translate('brand.select')}
            </span>
          </div>
        </TooltipContainer>
      </Box>
    );
  }

  return null;
};

export default BrandTag;

import React, { useContext } from 'react';
import useStyles from './style';
import { useTheme } from '@mui/material';
import { ThemeContext } from '../../../../utils/context/ThemeContext';
import ChatContainer from './components/ChatContainer';
import { TranslateInterface } from '../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../utils/hooks/useLanguage';

const ChatPage = () => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();
  const { translate }: TranslateInterface = useLanguage();

  return (
    <>
      <div className={classes.root}>
        <p className={classes.title}>{translate('page.chat.title')}</p>

        <ChatContainer />
      </div>
    </>
  );
};

export default ChatPage;

export const getPlatformVersion = (user: any) => {
  if (user?.options?.platformVersion === 'education') {
    return 'education';
  }

  if (user?.options?.platformVersion === 'agents') {
    return 'agents';
  }

  return 'standard';
};

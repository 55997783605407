import React, { useContext, useState } from 'react';
import { DocumentCardInterface } from './interface';
import useStyles from './style';
import {
  Box,
  CardActionArea,
  Grid,
  useMediaQuery,
  useTheme
} from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import Card from '@mui/material/Card';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import {
  deleteDocumentById,
  getDocumentById
} from '../../../../../../stories/actions/documents';
import { useNavigate } from 'react-router-dom';
import { getProcessReadability } from '../../../../../../stories/actions/editor';
import ModalDeleteDocument from '../../../../components/Modal/ModalDeleteDocument';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';
import formatDate from '../../../../../../utils/function/formatDate';
import { GlobalContext } from '../../../../../../utils/context/GlobalContext';

const DocumentCard = ({
  id,
  title,
  description,
  onSelect,
  date,
  diversion
}: DocumentCardInterface) => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();

  const [modal, setModal] = useState(false);
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const { translate }: TranslateInterface = useLanguage();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleEditDocument = (id: string) => {
    // dispatch(getDocumentById(id, navigate));

    return navigate(`/edit-text/${id}`);
  };

  const ctx = useContext(GlobalContext);
  const language = ctx.language;

  return (
    <>
      <Box className={classes.root}>
        <Box
          style={{
            maxWidth: '100%',
            marginTop: '1rem',
            cursor: 'default',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '0px',
            width: '300px',
            height: '260px',
            background: themeMode.mode === 'light' ? '#FFFFFF' : '#303030',
            border: themeMode.mode === 'light' ? '1px solid #E5E3E8' : 'none',
            borderRadius: '13px',
            flex: 'none',
            order: '0',
            flexGrow: '1'
          }}
          onClick={onSelect}
        >
          <Box className={classes.content} style={{ maxWidth: '100%' }}>
            <Typography
              gutterBottom
              style={{
                color: themeMode.mode === 'light' ? '#39274B' : '#fff',
                fontFamily: 'Inter',
                lineHeight: '125%',
                fontSize: '20px',
                fontWeight: '700',
                flex: 'none',
                order: 0,
                flexGrow: 1,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                maxWidth: '100%'
              }}
            >
              {title === '' ? translate('page.documents.untitled') : title}
            </Typography>
            <Typography
              style={{
                color:
                  themeMode.mode === 'light'
                    ? '#4F3D66'
                    : theme.palette.text.secondary,
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '150%',
                textAlign: 'left',
                marginTop: '5px',
                marginBottom: '10px',
                minHeight: '100px'
              }}
            >
              {description || translate('page.documents.notAddedText')}
            </Typography>
            <Typography
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '0.5rem',
                color:
                  themeMode.mode === 'light'
                    ? '#4F3D66'
                    : theme.palette.text.secondary,
                fontSize: '12px',
                fontFamily: 'Inter',
                fontWeight: '400'
              }}
            >
              <AccessTimeRoundedIcon
                color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
              />
              {`${translate('page.documents.updated')} ${formatDate(
                date,
                language,
                'dateAndHour'
              )}`}
            </Typography>
          </Box>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              borderTop:
                themeMode.mode === 'light'
                  ? '1px solid #E5E3E8'
                  : '1px solid #757575',
              color:
                themeMode.mode === 'light'
                  ? theme.palette.primary.main
                  : theme.palette.secondary.main
            }}
          >
            <Box
              sx={{
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: '600',
                width: '100%',
                height: '3rem',
                display: 'flex',
                justifyContent: 'center',
                borderRight:
                  themeMode.mode === 'light'
                    ? '1px solid #E5E3E8'
                    : '1px solid #757575',
                alignItems: 'center',
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor:
                    themeMode.mode === 'light' ? '#E5E3E8' : '#414141ba',
                  borderRadius: '0px 0px 0px 15px'
                }
              }}
              onClick={() => handleEditDocument(id)}
            >
              {translate('page.documents.action.update')}
            </Box>
            {/*<Box*/}
            {/*  sx={{*/}
            {/*    width: '100%',*/}
            {/*    height: '3rem',*/}
            {/*    fontFamily: 'Inter',*/}
            {/*    fontSize: '14px',*/}
            {/*    fontWeight: '600',*/}
            {/*    borderLeft:*/}
            {/*      themeMode.mode === 'light'*/}
            {/*        ? '1px solid #E5E3E8'*/}
            {/*        : '1px solid #757575',*/}
            {/*    borderRight:*/}
            {/*      themeMode.mode === 'light'*/}
            {/*        ? '1px solid #E5E3E8'*/}
            {/*        : '1px solid #757575',*/}
            {/*    display: 'flex',*/}
            {/*    justifyContent: 'center',*/}
            {/*    alignItems: 'center',*/}
            {/*    '&:hover': {*/}
            {/*      cursor: 'pointer',*/}
            {/*      backgroundColor:*/}
            {/*        themeMode.mode === 'light' ? '#E5E3E8' : '#414141ba'*/}
            {/*    }*/}
            {/*  }}*/}
            {/*  onClick={() => handleEditDocument(id)}*/}
            {/*>{`${diversion} ${diversion === 1 ? 'desvio' : 'desvios'}`}</Box>*/}
            <Box
              sx={{
                width: '100%',
                height: '3rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: '600',
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor:
                    themeMode.mode === 'light' ? '#E5E3E8' : '#414141ba',
                  borderRadius: '0px 0px 15px 0px'
                }
              }}
              onClick={() => setModal(true)}
            >
              {translate('page.documents.action.delete')}
            </Box>
          </div>
        </Box>
      </Box>
      <ModalDeleteDocument
        idDocument={id}
        open={modal}
        onClose={() => setModal(false)}
      ></ModalDeleteDocument>
    </>
  );
};

export default DocumentCard;

import React, { useContext, useEffect, useState } from 'react';
import useStyles from './style';
import { Card, useTheme } from '@mui/material';
import { UnfoldMoreRounded } from '@mui/icons-material';
import ModalBrands from './components/ModalBrands';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import { useReduxState } from '../../../../../../hooks/useReduxState';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import { updateBrandActive } from '../../../../../../stories/actions/user';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';

const Brands = ({ toggle }: any) => {
  const themeMode = useContext(ThemeContext);
  const classes = useStyles(themeMode);

  const { translate }: TranslateInterface = useLanguage();

  const theme = useTheme();

  const [modalVisible, setModalVisible] = useState(false);

  const [activeBrand, setActiveBrand] = useState<any>('');

  const { brands, user } = useReduxState();

  const dispatch = useAppDispatch();

  const { options }: any = user;

  const { all } = brands;

  useEffect(() => {
    const active = all.find(
      el => String(el._id) === String(options?.brand?._id)
    );

    if (!active && all.length > 0) {
      // const newActive = all[0];
      //
      // if (newActive) {
      //   setActiveBrand(newActive);
      //
      //   dispatch(updateBrandActive(newActive._id));
      // } else {
      //   setActiveBrand(active || '');
      // }
    } else {
      setActiveBrand(active || '');
    }
  }, [brands, user]);

  return (
    <div style={{ position: 'relative' }}>
      <Card
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0.5rem 1rem',
          width: '210px',
          height: '60px',
          borderRadius: '10px!important',
          transition: '500ms easy-in',
          backgroundColor: theme.palette.mode === 'light' ? `#fff` : '#303030',
          border:
            theme.palette.mode === 'light'
              ? ' 1.5px solid #E7EBF0!important'
              : 'none',
          backgroundImage: 'none',
          boxShadow: 'none',
          '&:hover': {
            cursor: 'pointer'
          },
          marginTop: '15px',
          marginBottom: '15px'
        }}
        onClick={() => setModalVisible(!modalVisible)}
      >
        <div>
          <h5
            className={classes.title}
            style={{
              fontSize: '14px!important',
              marginBottom: '2px!important',
              fontWeight: '400',
              color: `${theme.palette.text.lightInfo}`
            }}
          >
            {translate('sidebar.brands.title')}
          </h5>

          <h2
            style={{
              fontSize: '16px',
              marginBottom: '2px!important',
              fontWeight: '600',
              color: themeMode.mode === 'light' ? '#303030' : '#fff'
            }}
          >
            {activeBrand && activeBrand?.name
              ? activeBrand.name
              : translate('sidebar.brands.notSelected')}
          </h2>
        </div>
        <UnfoldMoreRounded
          sx={{ color: themeMode.mode === 'light' ? '#303030' : '#fff' }}
        />
      </Card>
      {modalVisible && (
        <ModalBrands
          activeBrand={activeBrand?._id || 'no_selected'}
          setActiveBrand={(_id: string) => {
            const active = all.find(el => String(el._id) === String(_id));

            if (_id !== activeBrand?._id) {
              setActiveBrand(active);

              dispatch(updateBrandActive(_id));
            }

            setModalVisible(false);
          }}
          setModalVisible={setModalVisible}
          modalVisible={modalVisible}
          toggle={toggle}
        ></ModalBrands>
      )}
    </div>
  );
};

export default Brands;

/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Drawer, useTheme } from '@mui/material';
import useStyles from './style';
import ModalScore from './components/ModalScore';
import CircularScore from '../CircularScore';
import CategoryCollapse from './components/CategoryCollapse';
import { useReduxState } from '../../../../../../hooks/useReduxState';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import { getProcessReadability } from '../../../../../../stories/actions/editor';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import { getScore } from '../../function/getScore';
import TextMetrics from './components/TextMetrics';
import ModalSummary from './components/ModalSummary';
import { isBetaUser } from '../../../../../../utils/function/isBetaUser';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';

const CorrectionsOverview = () => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { translate }: TranslateInterface = useLanguage();

  const { user, documents, editor } = useReduxState();

  const [scoresModalOpen, setScoresModalOpen] = useState(false);

  const [summaryModalOpen, setSummaryModalOpen] = useState(false);

  const openScoresModal = () => {
    setScoresModalOpen(!scoresModalOpen);
  };

  const openSummaryModal = () => {
    setSummaryModalOpen(!summaryModalOpen);
  };

  const [innerHeight, setInnerHeight] = useState<any>();

  const { score } = getScore(documents.detail, user);

  useEffect(() => {
    setInnerHeight(window.innerHeight);
  }, [window.innerHeight]);

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={true}
      PaperProps={{ className: classes.drawerPaper }}
      /* sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          '&::-webkit-scrollbar': {
            width: '5px'
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: themeMode.mode === 'light' ? '#E5E3E8' : '#757575',
            borderRadius: '20px',
            border: '3px solid transparent'
          }
        }
      }} */
    >
      <ModalScore
        open={scoresModalOpen}
        toggle={() => {
          openScoresModal();
        }}
      />

      <ModalSummary
        open={summaryModalOpen}
        toggle={() => {
          openSummaryModal();
        }}
      />

      <Box
        display={'flex'}
        flexDirection={'column'}
        p={innerHeight < 750 ? '8px 6px' : '19px 15px'}
        // mb={'0.5rem'}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          mt={innerHeight < 750 ? '3px' : '7px'}
        >
          <h2 className={classes.scoreText}>
            {translate('page.review.score.title')}
          </h2>
        </Box>
        <Box
          pt={innerHeight < 750 ? '5px' : '17px'}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'center'}
        >
          <CircularScore
            size={innerHeight < 750 ? 50 : 70}
            value={59}
            score={score.text || 0}
            // score={editor.process?.readability.score.flesh || 0}
            optionalClick={() => {
              /* logEvent('score-details', 'show'); */
              setScoresModalOpen(!scoresModalOpen);
            }}
          />
        </Box>
        <Button
          variant="contained"
          onClick={() => openScoresModal()}
          style={{
            width: '100%',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontWeight: '600',
            lineHeight: '160%',
            backgroundColor: themeMode.mode === 'light' ? '#410A85' : '#09B286',
            color: 'white',
            letterSpacing: '0.1px',
            cursor: 'pointer',
            textTransform: 'unset',
            marginTop: '12px',
            borderRadius: '8px',
            padding: '8px 40px',
            gap: '10px'
          }}
        >
          {translate('page.review.action.viewDetails')}
        </Button>

        {/*{isBetaUser(user) && (*/}
        <Button
          variant="outlined"
          onClick={() => openSummaryModal()}
          sx={{
            width: '100%',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontWeight: '600',
            lineHeight: '160%',
            borderColor: themeMode.mode === 'light' ? '#410A85' : '#09B286',
            color: themeMode.mode === 'light' ? '#410A85' : '#09B286',
            letterSpacing: '0.1px',
            cursor: 'pointer',
            textTransform: 'unset',
            marginTop: '12px',
            borderRadius: '8px',
            padding: '6px 40px',
            gap: '10px',
            '&:hover': {
              backgroundColor:
                themeMode.mode === 'light' ? '#410A85' : '#09B286',
              color: 'white'
            }
          }}
        >
          {translate('page.review.action.summary')}
        </Button>
        {/*)}*/}
      </Box>

      <CategoryCollapse
        title={'Desvios de Estilo'}
        items={documents.detail?.corrections.deviations}
        category={'deviations'}
        itemsCount={documents.detail?.corrections.deviations.length}
      />

      {/*<CategoryCollapse*/}
      {/*  title={'Ortografia e Gramática'}*/}
      {/*  items={documents.detail?.corrections.spellingAndGrammar}*/}
      {/*  category={'spellingAndGrammar'}*/}
      {/*  itemsCount={documents.detail?.corrections.spellingAndGrammar.length}*/}
      {/*/>*/}

      {/*{user.detail?.isSubscriber && (*/}
      {/*  <CategoryCollapse*/}
      {/*    title={'Dúvidas'}*/}
      {/*    items={documents.detail?.corrections.doubts}*/}
      {/*    category={'doubts'}*/}
      {/*    itemsCount={documents.detail?.corrections.doubts.length}*/}
      {/*  />*/}
      {/*)}*/}

      {/*<Box display={'flex'} flexDirection={'column'} p={'19px'} pb={'0px'}>*/}
      {/*  <TextMetrics />*/}
      {/*</Box>*/}
    </Drawer>
  );
};
export default CorrectionsOverview;

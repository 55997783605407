import { makeStyles } from '@mui/styles';

const Styles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  title: {
    fontSize: '1.875rem',
    lineHeight: '2.25rem',
    fontWeight: '700'
  },
  options: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '2rem'
  },
  templates: {
    padding: '20px',
    marginTop: '20px!important'
  }
}));

export default Styles;

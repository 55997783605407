import React, { useContext } from 'react';
import { InfoInterface } from './interface';
import { Box, useTheme } from '@mui/material';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import { GlobalContext } from '../../../../../../utils/context/GlobalContext';
import useStyles from './style';
import { useReduxState } from '../../../../../../hooks/useReduxState';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';

const Info = ({}: InfoInterface) => {
  const classes = useStyles();

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  const ctx = useContext(GlobalContext);

  const { user }: any = useReduxState();

  const { translate }: TranslateInterface = useLanguage();

  // if (user.isSubscriber) {
  //   return (
  //     <div className={classes.root}>
  //       <h1 className={classes.title}>Ganhe 20.000 Palavras de graça!</h1>
  //       <p className={classes.description}>
  //         Participe de uma breve pesquisa de 3 minutos e receba 20.000 palavras
  //         de bônus em sua conta.
  //       </p>
  //     </div>
  //   );
  // }

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>{translate('banner.googleDocs.title')}</h1>
      <p className={classes.description}>
        {translate('banner.googleDocs.description')}
      </p>
    </div>
  );

  // return (
  //   <div className={classes.root}>
  //     <h1 className={classes.title}>Ganhe um cupom da Clarice!</h1>
  //     <p className={classes.description}>
  //       Participe da nossa pesquisa de 3 minutos e receba um cupom ao terminar.
  //     </p>
  //   </div>
  // );
};

export default Info;

import React, { useContext, useEffect, useState } from 'react';
import { RemoveCircleOutline, Report, MoreVert } from '@mui/icons-material';
import useStyle from './style';
import { IconButton, Menu, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import { TranslateInterface } from '../../../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../../../utils/hooks/useLanguage';

type MoreActionProps = {
  id?: any;
  onDisableRule?: any;
  onSendFeedback?: any;
  deviations?: any;
};

const MoreAction = ({
  id,
  onDisableRule,
  onSendFeedback,
  deviations
}: MoreActionProps) => {
  const classes = useStyle();

  const [menu, setMenu] = useState(null);
  const [selected, setSelected] = useState(0);
  const { translate }: TranslateInterface = useLanguage();

  const options: any = [
    {
      key: 'DESATIVAR_DESVIO',
      name: translate('page.review.card.disable'),
      icon: <RemoveCircleOutline className={classes.itemIcon} />
    },
    {
      key: 'FALSO_POSITIVO',
      name: translate('page.review.card.falsePositive'),
      icon: <Report className={classes.itemIcon} />
    }
  ];

  const handleClickMenu = event => {
    setMenu(event.currentTarget);
  };

  const handleClick = (event, key) => {
    if (key === 'DESATIVAR_DESVIO') {
      onDisableRule({
        error: () => {
          setMenu(null);
        },
        success: () => {
          setMenu(null);
        }
      });
    } else if (key === 'FALSO_POSITIVO') {
      onSendFeedback();
    }

    setMenu(null);
    setSelected(key);
  };

  const handleClose = () => {
    setMenu(null);
  };

  useEffect(() => {
    if (menu) {
      document.body.style.overflow = 'unset';
      document.body.style.paddingRight = '0px';
    }
  }, [menu]);

  return (
    <>
      <Button
        size={'small'}
        variant={'outlined'}
        className={classes.moreButton}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClickMenu}
      >
        <MoreVert className={classes.moreIcon} />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={menu}
        keepMounted
        open={Boolean(menu)}
        onClose={handleClose}
      >
        {options.map(option => (
          <MenuItem
            key={option.name}
            selected={option.key === selected}
            onClick={event => handleClick(event, option.key)}
          >
            {option.icon} {option.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MoreAction;

import React, { useContext, useEffect, useState } from 'react';
import useStyles from './style';
import { Box, useMediaQuery } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../../../utils/context/ThemeContext';
import { useReduxState } from '../../../../hooks/useReduxState';
import { TranslateInterface } from '../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../utils/hooks/useLanguage';
import Root from './components/Root';

type Props = {};

const AgentsPage = (props: Props) => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  const { translate }: TranslateInterface = useLanguage();

  const params = useParams();

  const navigate = useNavigate();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const { user } = useReduxState();

  const [agent, setAgent] = useState<any>(null);

  useEffect(() => {
    setAgent(params.type);
  }, [params]);

  const agents = {
    copywriter: {
      title: 'Copywriter',
      link: 'https://udify.app/chatbot/SSFutpr8f2mNZnnR'
    },
    photographer: {
      title: 'Photographer',
      link: 'https://udify.app/chatbot/SRtGLGHSi4chMoDu'
    }
  };

  return (
    <Root>
      <p className={classes.title}>{translate('page.agents.title')}</p>

      {agent && (
        <iframe
          src={agents[agent].link}
          title={agents[agent].title}
          width="3000"
          height="700"
          style={{ border: 'none' }}
        />
      )}
    </Root>
  );
};

export default AgentsPage;

import { DisabledRuleNotFoundInterface } from './interface';
import useStyles from './style';
import { useContext } from 'react';
import { ThemeContext } from '../../../../../../../utils/context/ThemeContext';
import { TranslateInterface } from '../../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../../utils/hooks/useLanguage';

const DisabledRuleNotFound = ({}: DisabledRuleNotFoundInterface) => {
  const classes: any = useStyles();

  const { translate }: TranslateInterface = useLanguage();

  const themeMode = useContext(ThemeContext);

  return (
    <p className={classes.description}>
      {translate('modal.disabledRules.loading')}
    </p>
  );
};

export default DisabledRuleNotFound;

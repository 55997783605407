import React, { useContext } from 'react';
import { ActionInterface } from './interface';
import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import { ThemeContext } from '../../../../../../../utils/context/ThemeContext';
import useStyles from './style';
import { GlobalContext } from '../../../../../../../utils/context/GlobalContext';
import { FiberManualRecord } from '@mui/icons-material';
import { TranslateInterface } from '../../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../../utils/hooks/useLanguage';

const Action = ({ onNext, active, last }: ActionInterface) => {
  const ctx = useContext(GlobalContext);

  const classes = useStyles();

  const { translate }: TranslateInterface = useLanguage();

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return (
    <Box
      style={{
        width: '100%',
        // marginBottom: isMobile ? '24px' : '0px',
        gap: '8px'
      }}
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      flexDirection={'row'}
      width={'100%'}
    >
      <Box>
        <Button
          variant="contained"
          sx={{
            minWidth: '111px',
            cursor: 'pointer!important',
            fontFamily: 'Inter',
            backgroundColor: '#09B286',
            textTransform: 'none',
            padding: '8px 24px 8px 24px',
            gap: '10px',
            borderRadius: '6px',
            fontSize: '16px',
            '&:hover': {
              backgroundColor: `${theme.palette.secondary.dark}`
            },
            [theme.breakpoints.down('sm')]: {
              // width: '100%'
            },
            fontWeight: '600',
            color: '#fff'
          }}
          onClick={() => {
            onNext();
          }}
        >
          {last
            ? translate('modal.onboarding.action.close')
            : translate('modal.onboarding.action.next')}
        </Button>
      </Box>

      <Box
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          justifyItems: 'center',
          gap: '16px'
        }}
      >
        <FiberManualRecord
          style={{
            fontSize: '12px',
            color: active === 0 ? '#09B286' : '#9389A1'
          }}
        />

        <FiberManualRecord
          style={{
            fontSize: '12px',
            color: active === 1 ? '#09B286' : '#9389A1'
          }}
        />

        <FiberManualRecord
          style={{
            fontSize: '12px',
            color: active === 2 ? '#09B286' : '#9389A1'
          }}
        />

        <FiberManualRecord
          style={{
            fontSize: '12px',
            color: active === 3 ? '#09B286' : '#9389A1'
          }}
        />
      </Box>
    </Box>
  );
};

export default Action;

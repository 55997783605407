import React, { useContext } from 'react';
import {
  Box,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import ScoreSubitem from '../ScoreSubitem';
import CircularScore from '../../../../CircularScore';
import { useReduxState } from '../../../../../../../../../hooks/useReduxState';
import { getScore } from '../../../../../function/getScore';
import ScoreSubitemGenre from '../ScoreSubitemGenre';
import { ThemeContext } from '../../../../../../../../../utils/context/ThemeContext';
import { TranslateInterface } from '../../../../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../../../../utils/hooks/useLanguage';

type ScoreItemProps = {
  title?: any;
  subtitle?: any;
  description?: any;
  type?: any;
  loading?: any;
  characters?: any;
  words?: any;
  paragraphs?: any;
  readingTime?: any;
  speakingTime?: any;
  sentences?: any;
  uniqueWords?: any;
  rareWords?: any;
  wordLength?: any;
  sentenceLength?: any;
  readabilityScore?: any;
  legibility?: any;
  sentiment?: any;
  genre?: any;
  style?: any;
  complexWord?: any;
  summary?: any;
};

const ScoreItem = ({
  title,
  subtitle,
  description,
  type,
  loading,
  characters,
  words,
  paragraphs,
  readingTime,
  speakingTime,
  sentences,
  uniqueWords,
  rareWords,
  wordLength,
  sentenceLength,
  readabilityScore,
  legibility,
  sentiment,
  genre,
  style,
  complexWord,
  summary
}: ScoreItemProps) => {
  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  const { translate }: TranslateInterface = useLanguage();

  const { documents, user } = useReduxState();

  const { score } = getScore(documents.detail, user);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const types = {
    performance() {
      return (
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'}>
            <Typography
              style={{
                textAlign: 'justify',
                color: theme.palette.text.primary,
                fontSize: isMobile ? '14px' : '16px',
                paddingTop: isMobile ? '5px' : '20px',
                lineHeight: '22px'
              }}
            >
              {description}
            </Typography>
          </Box>
          <Box
            display={'flex'}
            style={{
              paddingTop: isMobile ? '0px' : '20px',
              paddingLeft: '20px'
            }}
          >
            <CircularScore
              size={55}
              score={score.text || 0}
              // score={editor.process?.readability.score.flesh || 0}
            />
          </Box>
        </Box>
      );
    },
    summary() {
      return (
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box
            display={'flex'}
            style={{
              width: '100%'
            }}
          >
            {loading && (
              <Skeleton
                variant="text"
                width="100%!important"
                height={100}
                style={{
                  width: '100%',
                  paddingTop: '15px',
                  lineHeight: isMobile ? '16px' : '23.68px'
                }}
              />
            )}

            {!loading && (
              <Typography
                style={{
                  // textAlign: 'justify',
                  color:
                    themeMode.mode === 'dark'
                      ? theme.palette.text.primary
                      : '#39274B',
                  fontSize: isMobile ? '12px' : '14px',
                  // fontStyle: 'italic',
                  paddingTop: '15px',
                  lineHeight: isMobile ? '16px' : '23.68px'
                }}
              >
                {summary}
              </Typography>
            )}
          </Box>
        </Box>
      );
    },
    wordCount() {
      return (
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          width={'100%'}
        >
          <Box width={'100%'} sx={{ marginRight: '15px' }}>
            {isMobile && (
              <ScoreSubitem
                description={translate(
                  'modal.score.metrics.options.paragraphs'
                )}
                total={paragraphs}
              />
            )}
            <ScoreSubitem
              description={translate('modal.score.metrics.options.characters')}
              total={characters}
            />
            <ScoreSubitem
              description={translate('modal.score.metrics.options.words')}
              total={words}
            />
            <ScoreSubitem
              description={translate('modal.score.metrics.options.sentences')}
              total={sentences}
            />
          </Box>
          <Box width={'100%'} sx={{ marginLeft: '15px' }}>
            {!isMobile && (
              <ScoreSubitem
                description={translate(
                  'modal.score.metrics.options.paragraphs'
                )}
                total={paragraphs}
              />
            )}
            <ScoreSubitem
              description={translate('modal.score.metrics.options.readingTime')}
              total={readingTime}
              time={isMobile}
            />
            <ScoreSubitem
              description={translate(
                'modal.score.metrics.options.speakingTime'
              )}
              total={speakingTime}
              time={isMobile}
            />
          </Box>
        </Box>
      );
    },
    readability() {
      return (
        <Box alignItems={'center'} width={'100%'}>
          <ScoreSubitem
            description={translate(
              'modal.score.readability.options.wordLength'
            )}
            loading={loading}
            total={wordLength}
          />

          <ScoreSubitem
            description={translate(
              'modal.score.readability.options.sentenceLength'
            )}
            loading={loading}
            total={sentenceLength}
          />

          <ScoreSubitem
            description={translate(
              'modal.score.readability.options.readabilityScore.title'
            )}
            loading={loading}
            total={readabilityScore}
            info={translate(
              'modal.score.readability.options.readabilityScore.description'
            )}
          />

          <Typography
            style={{
              color:
                themeMode.mode === 'dark'
                  ? theme.palette.text.primary
                  : '#39274B',
              fontSize: isMobile ? '12px' : '14px',
              // fontStyle: 'italic',
              paddingTop: '15px',
              lineHeight: isMobile ? '16px' : '23.68px'
            }}
          >
            {description}
          </Typography>
        </Box>
      );
    },
    legibility() {
      return (
        <Box width={'100%'}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            // alignItems={'center'}
            width={'100%'}
          >
            <Box width={'100%'} sx={{ marginRight: '15px' }}>
              <ScoreSubitem
                description={translate(
                  'modal.score.legibility.options.wordLength'
                )}
                loading={loading}
                total={wordLength}
              />

              <ScoreSubitem
                description={translate(
                  'modal.score.legibility.options.sentenceLength'
                )}
                loading={loading}
                total={sentenceLength}
              />

              <ScoreSubitem
                description={translate(
                  'modal.score.legibility.options.complexWord.title'
                )}
                loading={loading}
                total={String(complexWord) + '%'}
                info={translate(
                  'modal.score.legibility.options.complexWord.description'
                )}
              />

              <ScoreSubitem
                description={translate(
                  'modal.score.legibility.options.readabilityScore.title'
                )}
                loading={loading}
                total={legibility.score.string}
                info={translate(
                  'modal.score.legibility.options.readabilityScore.description'
                )}
                mt={2}
              />
            </Box>
            <Box width={'100%'} sx={{ marginLeft: '15px' }}>
              <ScoreSubitem
                description={translate(
                  'modal.score.legibility.options.formality'
                )}
                type="legibility"
                loading={loading}
                total={style}
              />

              <ScoreSubitemGenre
                description={translate('modal.score.legibility.options.genre')}
                type="legibility"
                loading={loading}
                total={genre}
              />

              <ScoreSubitem
                description={translate(
                  'modal.score.legibility.options.sentiment'
                )}
                type="legibility"
                loading={loading}
                total={sentiment}
              />
            </Box>
          </Box>

          <Box alignItems={'center'} width={'100%'}>
            <Typography
              style={{
                color:
                  themeMode.mode === 'dark'
                    ? theme.palette.text.primary
                    : '#39274B',
                fontSize: isMobile ? '12px' : '15px',
                // fontStyle: 'italic',
                paddingTop: '15px',
                lineHeight: isMobile ? '16px' : '23.68px'
              }}
            >
              {legibility.label}
            </Typography>
          </Box>
        </Box>
      );
    },
    vocabulary() {
      return (
        <Box alignItems={'center'} width={'100%'}>
          <ScoreSubitem
            description="Palavras únicas"
            total={uniqueWords}
            info="Measures vocabulary diversity by calculating the number of unique words"
          />
          <ScoreSubitem
            description="Palavras raras"
            total={rareWords}
            info="Measures depth of vocabulary by identifying words that are not among the 5,000 most commom English words."
          />
        </Box>
      );
    }
  };

  return (
    <Box style={{ width: '100%', paddingTop: '10px', paddingBottom: '15px' }}>
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        style={{
          width: '100%',
          borderBottom: '1px solid #e4e6f2',
          paddingTop: '5px',
          paddingBottom: '5px'
        }}
      >
        <Typography
          style={{
            textAlign: 'left',
            color: `${theme.palette.text.primary}`,
            fontSize: isMobile ? '18px' : '22px',
            fontWeight: '700',
            paddingBottom: '5px'
          }}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography
            style={{
              textAlign: 'left',
              color: `${theme.palette.text.primary}`,
              fontSize: '12px',
              fontWeight: '300',
              paddingBottom: '5px',
              lineHeight: '16px'
            }}
          >
            {subtitle}
          </Typography>
        )}
      </Box>
      {types[type] && types[type]()}
    </Box>
  );
};

export default ScoreItem;

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  tools: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px',
    marginTop: '50px!important'
  }
}));

export default useStyles;

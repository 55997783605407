/* eslint-disable no-undef */
import { Dispatch } from 'react';
import { BasicDispatchParam } from '../../models/dispatchTypes';
import { decreaseLoading, increaseLoading } from './loading';
import analytics from '../../utils/function/analytics';
import mapping from '../../utils/function/mapping';
import ToolsRequests from '../repositories/tools';
import { ToolAiDetect, ToolAiDetectResponse } from '../../models/module';

export const toolTranslate =
  (fields: models.ToolTranslate, cb?: any) =>
  async (
    dispatch: Dispatch<BasicDispatchParam<models.ToolTranslateResponse>>
  ) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('tools', 'translate');

      await mapping.track('Tool Translate');

      const payload: models.ToolTranslateResponse =
        await ToolsRequests.translate(fields);

      if (cb && cb.success) {
        cb.success(payload);
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error();
        }

        throw new Error(e.response.data.message);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const toolAiDetect =
  (fields: models.ToolAiDetect, cb?: any) =>
  async (
    dispatch: Dispatch<BasicDispatchParam<models.ToolAiDetectResponse>>
  ) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('tools', 'ai-detect');

      await mapping.track('Tool AI Detect');

      const payload: models.ToolAiDetectResponse = await ToolsRequests.aiDetect(
        fields
      );

      if (cb && cb.success) {
        cb.success(payload);
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error();
        }

        throw new Error(e.response.data.message);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

/* eslint-disable no-undef */
import React, { useContext } from 'react';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import useStyles from './style';
import { RootInterface } from './interface';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';

const Root = ({ children }: RootInterface) => {
  const classes = useStyles();
  const theme = useTheme();
  const themeMode = useContext(ThemeContext);

  return (
    <div className={classes.root}>
      <Box>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          {children}
        </div>
      </Box>
    </div>
  );
};

export default Root;

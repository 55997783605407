import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  container: {
    width: '100%',
    marginTop: '0px',
    marginBottom: '5px',
    // position: 'absolute',
    // padding: '11px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '8px',
    // zIndex: '1',
    borderRadius: '4px',
    backgroundColor:
      theme.palette.mode === 'light' ? '#FFF' : '#3a3541!important'
    // boxShadow:
    //   'rgb(255 251 251 / 5%) 0px 0px 0px 1px, rgb(255 251 251 / 5%) 0px 1px 0px, rgb(15 15 15 / 16%) 0px 1px 14px'
    // maxWidth: 'calc(100vw - 24px)'
  },
  button: {
    width: 'auto',
    paddingLeft: '0.6rem',
    paddingRight: '0.8rem',
    fontSize: '13.4px',
    letterSpacing: 'calc(0px * var(--rem))',
    lineHeight: 'calc(1.3125px * var(--rem))',
    textAlign: 'left',
    color: theme.palette.mode === 'light' ? '#ff0000ad!important' : 'white',
    backgroundColor: theme.palette.mode === 'light' ? '#fce4e4' : '#9b1b1b8a',
    paddingTop: '10px',
    paddingBottom: '10px',
    borderRadius: '5px',
    // fontWeight: '500',
    '& > p': {
      marginTop: '0px',
      marginBottom: '0px',
      marginBlockStart: '0em!important',
      marginBlockEnd: '0em!important'
    },
    display: 'flex',
    padding: '6px',
    // alignItems: 'center!important',
    justifyContent: 'start',
    gap: '6px',
    // marginTop: '15px',
    cursor: 'pointer',
    border: '0.5px solid transparent',
    '&:hover': {
      border: '0.5px solid #ff0000ad'
    }
  },
  icon: {
    color: theme.palette.mode === 'light' ? '#ff0000ad!important' : 'white'
  }
}));

export default useStyles;

import { HistoricNotFoundInterface } from './interface';
import { Grid, Box } from '@mui/material';
import useStyles from './style';
import { useContext } from 'react';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';

const HistoricNotFound = ({}: HistoricNotFoundInterface) => {
  const classes: any = useStyles();

  const themeMode = useContext(ThemeContext);

  const { translate }: TranslateInterface = useLanguage();

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Box className={classes.container}>
        <p className={classes.description}>
          {translate('page.templates.historicNotFound')}
        </p>
      </Box>
    </Grid>
  );
};

export default HistoricNotFound;

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      marginBottom: '40px'
    },
    divider: {
      marginTop: '30px!important',
      width: '100%',
      backgroundColor:
        theme.palette.mode === 'light'
          ? '1px solid rgb(0 0 0 / 20%)'
          : '1px solid #3a3541'
    },
    title: {
      fontSize: '30px',
      lineHeight: '1.75rem',
      fontWeight: '700',
      marginBlockStart: '5px',
      marginBlockEnd: '15px'
    },
    description: {
      fontSize: '16px',
      lineHeight: '1.25rem',
      color: 'rgb(100 116 139)',
      marginTop: '0.8rem',
      marginBottom: '1.0rem'
    },
    contentContainer: {
      padding: '1rem 1rem',
      Height: 'max-content',
      borderRadius: '10px',
      border:
        theme.palette.mode === 'light'
          ? '1.5px solid #E7EBF0!important'
          : '1px solid #3a3541',

      backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#303030',
      boxShadow:
        theme.palette.mode === 'light'
          ? '0px 18px 56px -12px rgba(227, 227, 238, 0.8)'
          : '0px 18px 56px -12px #303030',
      transition: '500ms easy-in'
    },
    actionsContainer: {
      paddingTop: '1.3rem',
      paddingBottom: '1rem',
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
      border: '1px solid #E7EBF0!important',
      marginBottom: '10px',
      minHeight: '75px'
    },
    formContainer: {
      paddingTop: '1rem',
      paddingBottom: '1rem',
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem'
    },
    input: {
      marginBottom: '40px!important',
      '&:first-child': {
        marginTop: '1rem'
      }
    },
    submitContainer: {
      textAlign: 'right',
      justifyContent: 'right'
    },
    dividerTable: {
      width: '100%',
      color: theme.palette.grey + '!important',
      height: '1px',
      margin: '8px 0px 8px'
    }
  };
});

export default useStyles;

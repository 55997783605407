import React, { useContext } from 'react';
import { RootInterface } from './interface';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import { GlobalContext } from '../../../../../../utils/context/GlobalContext';
import useStyles from './style';

const Root = ({ children }: RootInterface) => {
  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  const ctx = useContext(GlobalContext);

  const classes = useStyles();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return (
    <Box className={classes.tools}>
      <Grid
        container
        spacing={isMobile ? 0 : 3}
        xs={12}
        sm={12}
        md={12}
        lg={7}
        xl={7}
        justifyContent="center"
        alignItems="center"
      >
        {children}
      </Grid>
    </Box>
  );
};

export default Root;

import { SearchRounded } from '@mui/icons-material';
import { Button, Grid, InputBase, Paper, Box, useTheme } from '@mui/material';
import { useContext, useState } from 'react';
import { useAppDispatch } from '../../../../../../../hooks/useAppDispatch';
import { createNewDocument } from '../../../../../../../stories/actions/documents';
import { ThemeContext } from '../../../../../../../utils/context/ThemeContext';
import useStyles from './style';
import { useNavigate } from 'react-router-dom';
import { useReduxState } from '../../../../../../../hooks/useReduxState';
import TooltipContainer from '../../../../../components/TooltipContainer';
import LoadingButton from '@mui/lab/LoadingButton';
import analytics from '../../../../../../../utils/function/analytics';
import mapping from '../../../../../../../utils/function/mapping';
import { GlobalContext } from '../../../../../../../utils/context/GlobalContext';
import { TranslateInterface } from '../../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../../utils/hooks/useLanguage';

const SearchBar = ({ value, setValue }) => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const ctx = useContext(GlobalContext);
  const { translate }: TranslateInterface = useLanguage();

  return (
    <Box className={classes.container}>
      <LoadingButton
        loading={false}
        variant={'contained'}
        disableElevation
        disabled={false}
        sx={{
          minWidth: '170px',
          cursor: 'pointer!important',
          fontFamily: 'Inter',
          backgroundColor: '#09B286',
          textTransform: 'none',
          padding: '8px 24px 8px 24px',
          fontWeight: '600',
          fontSize: '16px',
          '&:hover': {
            backgroundColor: `${theme.palette.secondary.dark}`
          },
          [theme.breakpoints.down('sm')]: {
            width: '100%'
          }
        }}
        onClick={ctx.modal.project.create.toggle}
      >
        {translate('page.project.list.action.create')}
      </LoadingButton>
      <div className={classes.root}>
        <Paper
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: themeMode.mode === 'light' ? '#efefef' : '#303030',
            textTransform: 'none',
            padding: '7px 12px 7px 12px',
            borderRadius: '10px',
            boxShadow:
              themeMode.mode === 'light'
                ? '0px 18px 56px -12px rgba(227, 227, 238, 0.8)'
                : '0px 18px 56px -12px rgba(38, 38, 39, 0.8)',
            [theme.breakpoints.down('sm')]: {
              padding: '7px 20px 7px 20px'
            }
          }}
        >
          <InputBase
            sx={{
              flexGrow: 1,
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: '500',
              color: theme.palette.text.primary,
              [theme.breakpoints.down('sm')]: {
                width: '100%'
              }
            }}
            placeholder={translate('page.project.list.search.placeholder')}
            value={value}
            onChange={ev => setValue(ev.target.value)}
            spellCheck={false}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#09B286',
              borderRadius: '5px',
              padding: '0.3rem'
            }}
          >
            <SearchRounded
              sx={{
                height: '1rem',
                width: '1rem',
                color: '#fff'
              }}
            />
          </div>
        </Paper>
      </div>
    </Box>
  );
};

export default SearchBar;

/* eslint-disable no-undef */
import UserRequests from '../repositories/user';
import {
  USER_DETAIL,
  USER_STATS,
  USER_UPDATE_PROJECT_ACTIVE,
  USER_UPDATE_THEME,
  USER_ADD_WORD_FROM_DICTIONARY,
  USER_DELETE_WORD_FROM_DICTIONARY,
  USER_ENABLE_RULE,
  USER_DISABLE_RULE,
  USER_UPDATE_PROFILE,
  USER_UPDATE_CHAT_HISTORIC,
  USER_UPDATE_ACCEPTED_TERMS,
  USER_UPDATE_PLATFORM
} from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';
import notify from '../../utils/notify';
import RequestErrorHelper from '../../utils/helper/RequestErrorHelper';
import analytics from '../../utils/function/analytics';
import mapping from '../../utils/function/mapping';

export const getUserById = (_id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.User = await UserRequests.getUserById(_id);
    dispatch({
      payload,
      type: USER_DETAIL
    });
  } catch (err) {
    if (err instanceof Error) {
      // console.log(err.message);
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getUserStats = (_id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.User = await UserRequests.getUserById(_id);
    dispatch({
      payload,
      type: USER_STATS
    });
  } catch (err) {
    if (err instanceof Error) {
      // console.log(err.message);
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const updateUser =
  (user: models.User, cb?: any) => async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('user', 'update-profile');

      const payload: any = await UserRequests.updateUser(user._id, user);

      dispatch({
        payload: {
          _id: payload._id,
          name: payload.name,
          picture: payload.picture || '',
          email: payload.email,
          phone: payload.phone,
          options: payload.options
        },
        type: USER_UPDATE_PROFILE
      });

      if (cb && cb.success) {
        cb.success();
      }

      await mapping.track('Update Profile');
    } catch (e) {
      if (e instanceof Error) {
        if (cb && cb.error) {
          cb.error();
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const updateTheme = (theme: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await analytics.logEvent('user', 'toggle-theme');

    const payload: any = await UserRequests.updateTheme(theme);

    dispatch({
      payload: {
        options: payload.options
      },
      type: USER_UPDATE_THEME
    });

    localStorage.setItem('theme', theme);

    await mapping.track('Update Theme');
  } catch (err) {
    if (err instanceof Error) {
      // console.log(err.message);
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const updateLanguage = (language: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await analytics.logEvent('user', 'toggle-language');

    const payload: any = await UserRequests.updateLanguage(language);

    dispatch({
      payload: {
        options: payload.options
      },
      type: USER_UPDATE_THEME
    });

    await mapping.track('Update Language');
  } catch (err) {
    if (err instanceof Error) {
      // console.log(err.message);
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const updatePlatform = (platform: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await analytics.logEvent('user', 'toggle-platform');

    const payload: any = await UserRequests.updatePlatform(platform);

    dispatch({
      payload: {
        options: payload.options
      },
      type: USER_UPDATE_PLATFORM
    });

    await mapping.track('Update Platform');
  } catch (err) {
    if (err instanceof Error) {
      // console.log(err.message);
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const updateChatHistoric =
  (chatHistoric: boolean) => async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('user', 'toggle-chat-historic');

      const payload: any = await UserRequests.updateChatHistoric(chatHistoric);

      dispatch({
        payload: {
          options: payload.options
        },
        type: USER_UPDATE_CHAT_HISTORIC
      });

      await mapping.track('Update Chat Historic');
    } catch (err) {
      if (err instanceof Error) {
        // console.log(err.message);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const updateAcceptedTerms =
  (acceptedTerms: boolean) => async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('user', 'toggle-accepted-terms');

      const payload: any = await UserRequests.updateAcceptedTerms(
        acceptedTerms
      );

      dispatch({
        payload: {
          options: payload.options
        },
        type: USER_UPDATE_ACCEPTED_TERMS
      });

      await mapping.track('Update Accepted Terms');
    } catch (err) {
      if (err instanceof Error) {
        // console.log(err.message);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const updateProjectActive = (_id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await analytics.logEvent('user', 'update-project-active');

    const payload: any = await UserRequests.updateProjectActive(_id);

    dispatch({
      payload: {
        options: payload.options
      },
      type: USER_UPDATE_PROJECT_ACTIVE
    });

    await mapping.track('Set Project Active');
  } catch (err) {
    if (err instanceof Error) {
      // console.log(err.message);
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const updateBrandActive = (_id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await analytics.logEvent('user', 'update-brand-active');

    const payload: any = await UserRequests.updateBrandActive(_id);

    dispatch({
      payload: {
        options: payload.options
      },
      type: USER_UPDATE_PROJECT_ACTIVE
    });

    await mapping.track('Set Project Active');
  } catch (err) {
    if (err instanceof Error) {
      // console.log(err.message);
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const sendSuggestion =
  (subject: string, description: string, cb?: any) => async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('suggestion', 'send');

      const payload: any = await UserRequests.sendSuggestion(
        subject,
        description
      );

      if (cb && cb.success) {
        cb.success();
      }
    } catch (e) {
      if (e instanceof Error) {
        if (cb && cb.error) {
          cb.error();
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const addWordFromDictionary =
  (word: string, cb?: any) => async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('dictionary', 'add-word');

      const payload: any = await UserRequests.addWordFromDictionary(word);

      dispatch({
        payload: word,
        type: USER_ADD_WORD_FROM_DICTIONARY
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (e) {
      if (e instanceof Error) {
        if (cb && cb.error) {
          cb.error();
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const deleteWordFromDictionary =
  (word: string, cb?: any) => async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('dictionary', 'delete-word');

      const payload: any = await UserRequests.deleteWordFromDictionary(word);

      dispatch({
        payload: word,
        type: USER_DELETE_WORD_FROM_DICTIONARY
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (e) {
      if (e instanceof Error) {
        if (cb && cb.error) {
          cb.error();
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const enableRule = (id: string, cb?: any) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await analytics.logEvent('toggle-rule', 'enable');

    const payload: any = await UserRequests.enableRule(id);

    dispatch({
      payload: id,
      type: USER_ENABLE_RULE
    });

    if (cb && cb.success) {
      cb.success();
    }
  } catch (e) {
    if (e instanceof Error) {
      if (cb && cb.error) {
        cb.error();
      }

      await RequestErrorHelper(e);
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const disableRule = (id: string, cb?: any) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await analytics.logEvent('toggle-rule', 'disable');

    const payload: any = await UserRequests.disableRule(id);

    // console.log('disableRule: ', payload);

    dispatch({
      payload: id,
      type: USER_DISABLE_RULE
    });

    if (cb && cb.success) {
      cb.success();
    }

    await notify.success('Regra desativada com sucesso!');
  } catch (e) {
    if (e instanceof Error) {
      if (cb && cb.error) {
        cb.error();
      }

      await RequestErrorHelper(e);
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const disabledRulesFormatted =
  (rules: any[], cb?: any) => async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      const payload: any = await UserRequests.disabledRulesFormatted(rules);

      // console.log('disabledRules: ', payload);

      if (cb && cb.success) {
        cb.success(payload || []);
      }
    } catch (e) {
      if (e instanceof Error) {
        if (cb && cb.error) {
          cb.error();
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const getSynonyms =
  (word: string, sentence: string, cb?: any) => async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('user', 'synonyms');

      const payload: any = await UserRequests.getSynonyms(word, sentence);

      const terms = payload || [];

      if (cb && cb.success) {
        cb.success(terms);
      }
    } catch (e) {
      if (e instanceof Error) {
        if (cb && cb.error) {
          cb.error();
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const clearUserDetails = () => (dispatch: any) => {
  dispatch({
    payable: null,
    type: USER_DETAIL
  });
};

import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme
} from '@mui/material';
import { useContext, useState } from 'react';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import SidebarItem from '../SidebarItem';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../stories/redux/store';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import { SidebarItemCollapseInterface } from './interface';
import useStyles from './style';
import clsx from 'clsx';
import { useReduxState } from '../../../../../../hooks/useReduxState';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';

const SidebarItemCollapse = ({
  item,
  toggle
}: SidebarItemCollapseInterface) => {
  const classes = useStyles();
  const theme = useTheme();

  const { translate }: TranslateInterface = useLanguage();

  const [open, setOpen] = useState(false);

  const { appState } = useSelector((state: RootState) => state.appState);

  const themeMode = useContext(ThemeContext);

  const { user }: any = useReduxState();

  const activeStyle =
    themeMode.mode === 'light'
      ? `${theme.palette.secondary.main}`
      : `${theme.palette.primary.main}`;
  const disableStyle = themeMode.mode === 'light' ? '#fff' : '#fff';

  return item.sidebarProps ? (
    <>
      <div
        onClick={() => {
          setOpen(!open);
        }}
        style={{
          position: 'relative',
          color: appState === item.state ? activeStyle : disableStyle,
          fontWeight: '500',
          fontSize: '15px',
          padding: '10px 15px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
      >
        {appState === item.state && (
          <div
            style={{
              borderRadius: '0 10px 10px 0',
              position: 'absolute',
              top: '0',
              left: '0',
              content: ' ',
              width: '5px',
              height: '45px',
              background: appState === item.state ? activeStyle : disableStyle
            }}
          ></div>
        )}
        <ListItemIcon
          sx={{
            color: appState === item.state ? activeStyle : disableStyle,
            minWidth: '0!important',
            marginLeft: '1.5rem',
            marginRight: '0.5rem'
          }}
        >
          {item.sidebarProps.icon && item.sidebarProps.icon}
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={
            <Typography
              style={{
                fontWeight: '500',
                fontSize: '15px',
                color: appState === item.state ? activeStyle : disableStyle
              }}
            >
              {translate(item.sidebarProps.displayText)}

              {item.state === 'account' && (
                <span
                  className={clsx(classes.subscriberBadge, {
                    [classes.badgePro]: user.detail.isSubscriber
                  })}
                >
                  {user.detail.isSubscriber ? 'PRO' : 'FREE'}
                </span>
              )}
            </Typography>
          }
        />
        {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
      </div>
      <Collapse in={open} timeout="auto">
        <List>
          {item.child?.map((route, index) =>
            route.sidebarProps ? (
              route.child ? (
                <SidebarItemCollapse item={route} key={index} toggle={toggle} />
              ) : (
                <SidebarItem
                  isColapse
                  item={route}
                  key={index}
                  toggle={toggle}
                />
              )
            ) : null
          )}
        </List>
      </Collapse>
    </>
  ) : null;
};

export default SidebarItemCollapse;

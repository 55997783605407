import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import appStateSlice from '../redux/features/appStateSlice';
import creditsReducer from './credits';
import loadingReducer from './loading';
import templatesReducer from './templates';
import chatReducer from './chat';
import authReducer from './auth';
import userReducer from './user';
import projectsReducer from './projects';
import documentsReducer from './documents';
import editorReducer from './editor';
import paymentReducer from './payment';
import brandsReducer from './brands';
import businessReducer from './business';
import backofficeReducer from './backoffice';

const appReducer = combineReducers({
  appState: appStateSlice,
  auth: authReducer,
  user: userReducer,
  credits: creditsReducer,
  templates: templatesReducer,
  projects: projectsReducer,
  chat: chatReducer,
  loading: loadingReducer,
  documents: documentsReducer,
  editor: editorReducer,
  payment: paymentReducer,
  brands: brandsReducer,
  business: businessReducer,
  backoffice: backofficeReducer
});

const RootReducer = (state: any, action: any) => {
  return appReducer(state, action);
};

const storeCreator = createStore(
  RootReducer,
  compose(
    applyMiddleware(thunk),
    (window as any).__REDUX_DEVTOOLS_EXTENSION__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION__()
      : (f: any) => f
  )
);

export default storeCreator;

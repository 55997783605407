import React, { useContext, useState } from 'react';
import {
  Box,
  TextField,
  Modal,
  Button,
  Paper,
  useTheme,
  CircularProgress,
  InputBase,
  Grid
} from '@mui/material';
import { ThemeContext } from '../../../../../utils/context/ThemeContext';
import { GlobalContext } from '../../../../../utils/context/GlobalContext';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  addWordFromDictionary,
  deleteWordFromDictionary,
  sendSuggestion
} from '../../../../../stories/actions/user';
import { useReduxState } from '../../../../../hooks/useReduxState';
import useStyles from './style';
import DictionaryItem from './components/DictionaryItem';
import ModalCustom from '../ModalCustom';
import { SearchRounded } from '@mui/icons-material';
import { TranslateInterface } from '../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../utils/hooks/useLanguage';
import notify from '../../../../../utils/notify';

type ModalDictionaryProps = {};

const ModalDictionary = ({}: ModalDictionaryProps) => {
  const classes = useStyles();

  const { translate }: TranslateInterface = useLanguage();

  const themeMode = useContext(ThemeContext);

  const theme = useTheme();

  const ctx = useContext(GlobalContext);

  const dispatch = useAppDispatch();

  const { user } = useReduxState();

  const dictionary: any = user.dictionary;

  const [form, setForm] = useState<any>({ word: '' });

  const [loading, setLoading] = useState(false);

  const filtered = dictionary.filter((el: any) => el.includes(form.word));

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    setForm({ ...form, [type]: e.target.value });
  };

  const onClose = async () => {
    setForm({ word: '' });

    setLoading(false);

    ctx.modal.dictionary.toggle();
  };

  const onAdd = async (word: string) => {
    setLoading(true);

    const cb = {
      success: () => {
        setForm({ word: '' });

        setLoading(false);

        notify.success(translate('notify.dictionary.add.success'));
      },
      error: () => {
        setLoading(false);
      }
    };

    dispatch(addWordFromDictionary(form.word, cb));
  };

  const onDelete = async (word: string) => {
    const cb = {
      success: () => {
        setLoading(false);

        notify.success(translate('notify.dictionary.delete.success'));
      },
      error: () => {
        setLoading(false);
      }
    };

    dispatch(deleteWordFromDictionary(word, cb));
  };

  return (
    <>
      <ModalCustom
        open={ctx.modal.dictionary.open}
        toggle={onClose}
        containerClass={classes.root}
      >
        <h2 className={classes.title}>{translate('modal.dictionary.title')}</h2>

        <Grid
          container
          // xs={12}
          // sm={12}
          // md={12}
          // lg={12}
          // xl={12}
          style={{
            marginBottom: '20px',
            marginTop: '20px'
          }}
        >
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <div className={classes.containerInput}>
              <Paper
                className={classes.inputBar}
                elevation={0}
                style={
                  {
                    // marginRight: '10px'
                  }
                }
              >
                <SearchRounded
                  style={{
                    marginLeft: '10px',
                    marginRight: '10px',
                    color: '#a1a1a1'
                  }}
                />
                <InputBase
                  className={classes.input}
                  placeholder={translate(
                    'modal.dictionary.form.word.placeholder'
                  )}
                  value={form.word}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    handleChange(e, 'word')
                  }
                  spellCheck={false}
                />
              </Paper>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            // style={{ marginBottom: '10px' }}
          >
            <Button
              color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
              variant={'contained'}
              disableElevation
              disabled={loading}
              className={classes.button}
              onClick={() => {
                if (form.word !== '') {
                  if (filtered.find(el => el === form.word)) {
                    return;
                  }

                  return onAdd(form.word);
                }
              }}
              sx={{
                textTransform: 'none!important',
                fontFamily: 'Inter'
              }}
            >
              {loading ? (
                <CircularProgress
                  size={25}
                  style={{
                    marginLeft: '10px'
                  }}
                />
              ) : (
                translate('modal.dictionary.action.add')
              )}
            </Button>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              flexDirection={'column'}
              mt={'8px'}
              width={'100%'}
            >
              {dictionary.length > 0 && filtered.length === 0 && (
                <DictionaryItem
                  alternative={translate('modal.dictionary.notFound')}
                />
              )}

              {dictionary.length <= 0 && (
                <DictionaryItem
                  alternative={translate('modal.dictionary.notAdded')}
                />
              )}

              {filtered.length > 0 &&
                filtered.map(
                  (el, idx) =>
                    el && (
                      <DictionaryItem
                        key={idx}
                        word={el}
                        loading={loading}
                        onDelete={onDelete}
                        divider={filtered.length !== idx + 1}
                      />
                    )
                )}
            </Box>
          </Grid>
        </Grid>
      </ModalCustom>
    </>
  );
};

export default ModalDictionary;

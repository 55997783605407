import React, { useContext, useState } from 'react';
import { CreditCardInterface } from './interface';
import useStyles from './style';
import {
  Button,
  Card,
  CardContent,
  useMediaQuery,
  useTheme
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgressWithLabel from '../LinearProgressWithLabel';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import { useReduxState } from '../../../../../../hooks/useReduxState';
import { ReceiptLongRounded } from '@mui/icons-material';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import LoadingButton from '@mui/lab/LoadingButton';
import { openPortal } from '../../../../../../stories/actions/payment';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import analytics from '../../../../../../utils/function/analytics';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import mapping from '../../../../../../utils/function/mapping';
import { getPlatformVersion } from '../../../../../../utils/function/getPlatformVersion';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';

const CreditCard = ({ percentage, toggle }: CreditCardInterface) => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();

  const dispatch = useAppDispatch();

  const { translate }: TranslateInterface = useLanguage();

  const { credits, user } = useReduxState();

  const navigate = useNavigate();

  const baseURL = `/icons/template/${
    themeMode.mode === 'dark' ? 'dark' : 'light'
  }/`;

  const onOpenPortal = (cb: any) => {
    dispatch(openPortal(cb));
  };

  const handleOpenPortal = () => {
    setIsLoadingPortal(true);
    onOpenPortal(() => setIsLoadingPortal(false));
  };

  const [isLoadingPortal, setIsLoadingPortal] = useState(false);

  const preventDefault = (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (user.isSubscriber) {
      analytics.logEvent('CTA', 'credit-user-pro-upgrade-plan');

      mapping.track('CTA | credit-user-pro-upgrade-plan');

      return handleOpenPortal();
    } else {
      analytics.logEvent('CTA', 'credit-user-free-upgrade-plan');

      mapping.track('CTA | credit-user-free-upgrade-plan');

      navigate(`/plans`);
    }

    toggle();
  };

  const custom = {
    totalCredits: {
      quantity: credits.bonus.words.total + (credits?.plan?.words?.total || 0),
      percentage:
        ((credits.bonus.words.used + (credits?.plan?.words?.used || 0)) * 100) /
        (credits.bonus.words.total + (credits?.plan?.words?.total || 0))
    },
    planCredits: {
      quantity: credits?.plan?.words?.total || 0,
      percentage:
        (credits?.plan?.words?.used * 100) / credits?.plan?.words?.total
    },
    bonusCredits: {
      quantity: credits.bonus.words.total,
      percentage: (credits.bonus.words.used * 100) / credits.bonus.words.total
    }
  };

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const formattedPercentage = percentage => {
    if (percentage) {
      return percentage.toFixed(2).replace('.', ',');
    }

    return '0,00';
  };

  const formattedMessage = () => {
    if (user.isSubscriber) {
      return {
        message: translate('sidebar.conversion.paid'),
        icon: ''
      };
    }

    if (user?.subscription?.current_period_end)
      return {
        message: translate('sidebar.conversion.free'),
        icon: (
          <img
            className={classes.iconButton}
            src={baseURL + 'star.svg'}
            alt=""
          />
        )
      };

    return {
      message: translate('sidebar.conversion.freeDays'),
      icon: (
        <img className={classes.iconButton} src={baseURL + 'star.svg'} alt="" />
      )
    };
  };

  return (
    <Card
      sx={{
        marginTop: isMobile ? '10px' : '10px',
        marginBottom: isMobile ? '10px' : '10px',
        borderRadius: '10px!important',
        width: '210px',
        height:
          getPlatformVersion(user) === 'standard'
            ? isMobile
              ? '112px'
              : '114px'
            : isMobile
            ? '82px'
            : '82px',
        boxShadow: 'none',
        backgroundColor: themeMode.mode === 'light' ? `#fff` : '#303030',
        border:
          theme.palette.mode === 'light'
            ? ' 1.5px solid #E7EBF0!important'
            : 'none',
        backgroundImage: 'none',
        padding: '0!important'
      }}
    >
      <CardContent
        sx={{
          padding: '0.5rem 1rem!important',
          backgroundColor: 'none'
        }}
      >
        {getPlatformVersion(user) === 'standard' && (
          <Box display={'flex'} alignItems={'center'} gap={'0.3rem'}>
            <img className={classes.icon} src={baseURL + 'credit.svg'} alt="" />

            {/*<ReceiptLongRounded*/}
            {/*  sx={{*/}
            {/*    color: themeMode.mode === 'light' ? '#303030' : '#fff'*/}
            {/*  }}*/}
            {/*/>
             */}
            <h2 className={classes.title}>
              {translate('sidebar.conversion.generatedWords')}
            </h2>
          </Box>
        )}
        {getPlatformVersion(user) === 'standard' && (
          <Box
            sx={{
              width: '100%',
              height: '15px',
              marginLeft: '1px',
              marginTop: '2px',
              marginBottom: '2px'
            }}
          >
            <LinearProgressWithLabel
              value={custom.totalCredits.percentage || 0}
            />
          </Box>
        )}

        <Button
          variant="contained"
          onClick={preventDefault}
          style={{
            marginTop: '12px',
            boxShadow: '0px 2px 12px -8px rgba(0, 0, 0, 0.16)',
            // boxShadow: 'unset',
            width: '100%',
            padding: isMobile ? '10px 16px' : '12px 12px',
            justifyContent: 'center',
            alignItems: 'center',

            gap: '10px',
            borderRadius: '6px',
            textTransform: 'none',
            fontFamily: 'Inter',
            backgroundColor:
              themeMode.mode === 'light'
                ? `#09B286`
                : `${theme.palette.secondary.main}`,
            color:
              themeMode.mode === 'light'
                ? `#FFF`
                : `${theme.palette.text.black}`,
            textAlign: 'center',
            fontSize: isMobile ? '13px' : '15px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '100%'
          }}
          sx={{
            '& > MuiButton-startIcon': {
              marginRight: '0px'
            }
          }}
          startIcon={formattedMessage().icon}
        >
          {formattedMessage().message}
        </Button>
      </CardContent>
    </Card>
  );
};

export default CreditCard;

import React, { useContext, useEffect, useRef, useState } from 'react';
import useStyles from './style';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import Container from './components/Container';
import { ThemeContext } from '../../../../../utils/context/ThemeContext';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import { useReduxState } from '../../../../../hooks/useReduxState';
import { GlobalContext } from '../../../../../utils/context/GlobalContext';
import { TranslateInterface } from '../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../utils/hooks/useLanguage';

type Props = {};

const ToolAiDetectPage = (props: Props) => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  const { translate }: TranslateInterface = useLanguage();

  const params = useParams();

  const navigate = useNavigate();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const { user } = useReduxState();

  const ctx = useContext(GlobalContext);

  const [template, setTemplate] = useState<any>({
    id: 'aiDetect',
    title: translate('page.tools.list.aiDetect.title'),
    description: translate('page.tools.list.aiDetect.description'),
    fields: {
      content: {
        label: translate('page.tools.list.aiDetect.fields.content.label'),
        placeholder: translate(
          'page.tools.list.aiDetect.fields.content.placeholder'
        ),
        rows: 14,
        required: true
      }
    }
  });

  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  return (
    <div className={classes.root}>
      <Container
        id={template.id}
        title={template.title}
        description={template.description}
        type={template.type}
        redirect={template.redirect}
        fields={template.fields || {}}
      />
    </div>
  );
};

export default ToolAiDetectPage;

import React, { useContext, useState } from 'react';
import { DropdownSelectorInterface } from './interface';
import useStyles from './style';
import { useTheme } from '@mui/material';
import { ThemeContext } from '../../../../../../../../utils/context/ThemeContext';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { useAppDispatch } from '../../../../../../../../hooks/useAppDispatch';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import { completionByEditorSelector } from '../../../../../../../../stories/actions/templates';
import Button from '@mui/material/Button';
import { getCredits } from '../../../../../../../../stories/actions/credits';
import { GlobalContext } from '../../../../../../../../utils/context/GlobalContext';
import { TranslateInterface } from '../../../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../../../utils/hooks/useLanguage';

const DropdownSelectorAI = ({
  selector,
  setSelector,
  selectorRef,
  toggleSelector,
  onReplaceForSelector
}: DropdownSelectorInterface): any => {
  const ctx = useContext(GlobalContext);
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();
  const classes = useStyles(themeMode);

  const [newGenerate, setNewGenerate] = useState(false);

  const [loading, setLoading] = useState(false);

  const [option, setOption] = useState('');

  const [optionTone, setOptionTone] = useState('');

  const [outputs, setOutputs] = useState([]);

  const baseURL = `/icons/template/${
    themeMode.mode === 'dark' ? 'dark' : 'light'
  }/`;

  const { translate }: TranslateInterface = useLanguage();

  const options = [
    {
      id: 'simplify',
      description: translate('shortcut.simplify.description'),
      icon: (
        <img className={classes.icon} src={baseURL + 'simplify.svg'} alt="" />
      )
    },
    {
      id: 'shorten',
      description: translate('shortcut.shorten.description'),
      icon: (
        <img className={classes.icon} src={baseURL + 'shorten.svg'} alt="" />
      )
    },
    {
      id: 'better',
      description: translate('shortcut.better.description'),
      icon: <img className={classes.icon} src={baseURL + 'better.svg'} alt="" />
    },
    {
      id: 'tone',
      description: translate('shortcut.tone.description'),
      icon: <img className={classes.icon} src={baseURL + 'tone.svg'} alt="" />
    }
  ];

  const optionsTone = [
    {
      id: 'back',
      description: translate('shortcut.tone.options.back'),
      icon: <img className={classes.icon} src={baseURL + 'back.svg'} alt="" />
    },
    {
      id: 'friendly-tone',
      description: translate('shortcut.tone.options.friendly'),
      icon: (
        <img
          className={classes.icon}
          src={baseURL + 'friendly-tone.svg'}
          alt=""
        />
      )
    },
    {
      id: 'assertive-tone',
      description: translate('shortcut.tone.options.assertive'),
      icon: (
        <img
          className={classes.icon}
          src={baseURL + 'assertive-tone.svg'}
          alt=""
        />
      )
    },
    {
      id: 'construtive-tone',
      description: translate('shortcut.tone.options.constructive'),
      icon: (
        <img
          className={classes.icon}
          src={baseURL + 'construtive-tone.svg'}
          alt=""
        />
      )
    },
    {
      id: 'descriptive-tone',
      description: translate('shortcut.tone.options.descriptive'),
      icon: (
        <img
          className={classes.icon}
          src={baseURL + 'descriptive-tone.svg'}
          alt=""
        />
      )
    },
    {
      id: 'happy-tone',
      description: translate('shortcut.tone.options.happy'),
      icon: (
        <img className={classes.icon} src={baseURL + 'happy-tone.svg'} alt="" />
      )
    },
    {
      id: 'modest-tone',
      description: translate('shortcut.tone.options.modest'),
      icon: (
        <img
          className={classes.icon}
          src={baseURL + 'modest-tone.svg'}
          alt=""
        />
      )
    },
    {
      id: 'professional-tone',
      description: translate('shortcut.tone.options.professional'),
      icon: (
        <img
          className={classes.icon}
          src={baseURL + 'professional-tone.svg'}
          alt=""
        />
      )
    },
    {
      id: 'neutral-tone',
      description: translate('shortcut.tone.options.neutral'),
      icon: (
        <img
          className={classes.icon}
          src={baseURL + 'neutral-tone.svg'}
          alt=""
        />
      )
    },
    {
      id: 'optimistic-tone',
      description: translate('shortcut.tone.options.optimistic'),
      icon: (
        <img
          className={classes.icon}
          src={baseURL + 'optimistic-tone.svg'}
          alt=""
        />
      )
    },
    {
      id: 'reflective-tone',
      description: translate('shortcut.tone.options.reflective'),
      icon: (
        <img
          className={classes.icon}
          src={baseURL + 'reflective-tone.svg'}
          alt=""
        />
      )
    }
  ];

  const handleSelectOptionTone = async (optionTone: any) => {
    if (optionTone.id === 'back') {
      setOption('');

      return;
    }

    setOutputs([]);

    setLoading(true);

    setOptionTone(optionTone.id);

    const cb = {
      success: (outputs: any) => {
        setOutputs(outputs);

        setLoading(false);

        setNewGenerate(false);
      },
      error: () => {
        setLoading(false);

        setNewGenerate(false);
      }
    };

    await dispatch(
      completionByEditorSelector(
        {
          template: 'command-' + optionTone.id,
          snippet: selector.word,
          quantity: 2
        },
        cb
      )
    );
  };

  const handleSelectOption = async option => {
    if (option.id === 'tone') {
      setOption(option.id);

      return;
    }

    setOutputs([]);

    setLoading(true);

    setOption(option.id);

    const cb = {
      success: (outputs: any) => {
        setOutputs(outputs);

        setLoading(false);

        setNewGenerate(false);

        dispatch(getCredits());
      },
      error: (err: any) => {
        if (
          err?.response?.data?.message === 'Você atingiu o limite de créditos!'
        ) {
          if (!ctx.modal.conversion.open) {
            ctx.modal.conversion.toggle();
          }
        }

        setLoading(false);

        setNewGenerate(false);

        setSelector({ open: false });
      }
    };

    await dispatch(
      completionByEditorSelector(
        {
          template: 'command-' + option.id,
          snippet: selector.word,
          quantity: 2
        },
        cb
      )
    );
  };

  const dispatch = useAppDispatch();

  const position: any = () => {
    const co = selector.position;

    if (!co) return {};
    return {
      top: co.y,
      left: co.x,
      position: 'absolute',
      marginTop: '1.65em',
      zIndex: 999999
    };
  };

  const toggle = () => {
    setOutputs([]);

    setLoading(false);

    setSelector({ open: false });
  };

  if (selector?.position?.x === 0 && selector?.position?.y === 0) {
    return null;
  }

  if (!selector?.open) {
    return null;
  }

  const descriptionLoading = {
    simplify: translate('shortcut.simplify.loading'),
    shorten: translate('shortcut.shorten.loading'),
    better: translate('shortcut.better.loading')
  };

  return (
    <div className={classes.container} ref={selectorRef} style={position()}>
      {loading && (
        <div className={classes.list} key="ia-writing">
          <img className={classes.icon} src={baseURL + 'logo.svg'} alt="" />

          <div className={classes.descriptionLoading}>
            {newGenerate
              ? translate('shortcut.newGenerate.loading')
              : (option && descriptionLoading[option]) ||
                translate('shortcut.writer.loading')}
          </div>
        </div>
      )}

      {!loading &&
        outputs.length === 0 &&
        option === '' &&
        options.length > 0 &&
        options.map(option => (
          <div
            className={classes.list}
            key={option.id}
            onClick={() => {
              if (loading) {
                return;
              }

              handleSelectOption(option);
            }}
          >
            {option.icon}

            <div className={classes.description}>{option.description}</div>
          </div>
        ))}

      {!loading &&
        option === 'tone' &&
        outputs.length === 0 &&
        optionsTone.length > 0 &&
        optionsTone.map(option => (
          <div
            className={classes.list}
            key={option.id}
            onClick={() => {
              if (loading) {
                return;
              }

              handleSelectOptionTone(option);
            }}
          >
            {option.icon}

            <div className={classes.description}>{option.description}</div>
          </div>
        ))}

      {!loading &&
        outputs.length > 0 &&
        outputs.map((output: any) => (
          <div className={classes.output} key={output._id}>
            <div
              className={classes.resultsContainerWait}
              onClick={() => {
                if (loading) {
                  return;
                }

                onReplaceForSelector(
                  selector.word,
                  selector.range,
                  output.message
                );

                toggle();
              }}
            >
              <img
                className={classes.icon}
                style={{ marginLeft: '10px', marginTop: '15px' }}
                src={baseURL + 'logo.svg'}
                alt=""
              />

              <div className={classes.messageContainer}>
                {output.message.split('\n').map((el: any, index: number) => {
                  return (
                    <p key={index} className={classes.descriptionResult}>
                      {el}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        ))}

      {!loading && outputs.length > 0 && (
        <div className={classes.output}>
          <div
            style={{
              textAlign: 'right',
              justifyContent: 'right'
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                if (loading) {
                  return;
                }

                const newOption =
                  option === 'tone'
                    ? optionsTone.find(item => item.id === optionTone)
                    : options.find(item => item.id === option);

                setNewGenerate(true);

                handleSelectOption(newOption);
              }}
              size="small"
              style={{
                marginLeft: '0px',
                borderColor:
                  themeMode.mode === 'light'
                    ? `#7C24FB`
                    : `${theme.palette.secondary.main}`,
                color:
                  themeMode.mode === 'light'
                    ? `#7C24FB`
                    : `${theme.palette.secondary.main}`
              }}
              sx={{
                textTransform: 'none!important',
                fontFamily: ['Inter'].join(','),
                padding: '4px 16px',
                gap: '10px',
                borderRadius: '4px'
              }}
            >
              {translate('shortcut.newGenerate.action')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownSelectorAI;

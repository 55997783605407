import React, { useContext, useEffect, useState } from 'react';
import useStyles from './style';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { ThemeContext } from '../../../../../utils/context/ThemeContext';
import FullLogo from '../../../../../assets/images/jsx-icons/full-logo';
import { getPlans } from '../../../../../stories/actions/payment';
import { loadStripe } from '@stripe/stripe-js';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import { useReduxState } from '../../../../../hooks/useReduxState';
import withAuth from '../../../../../utils/axios/withAuth';
import { ACTIVE_COUPON } from '../../../../../utils/coupon';
import { useNavigate, useParams } from 'react-router-dom';
import NavBarPayment from '../old/components/NavBar';
import Teste from '../../../../../assets/images/payment/person.svg';
import PaymentCheckoutLoading from './components/Loading';

let stripe;

const keys = {
  live: 'pk_live_51HiMQkCh5qCS77obLnokWCFM1PbNO7GN1lxLyb92SEGel8C56eahoSddx8Le0gh5EZVvpSbBxyRiv0iizx7cmtiH00X15HmHg9',
  test: 'pk_test_51HiMQkCh5qCS77obmsIUxOvGLPittmEG4gsizSct7rqQYskaFIZGl28XoKtRY4ZhBXoRmqzvjREFmfB7XFcBpkQN00p8R5CVrj'
};

const formatPrice = (amount: number) =>
  String((amount / 100).toFixed(2)).replace('.', ',');

function getClientReferenceId() {
  return (
    // @ts-ignore
    (window.Rewardful && window.Rewardful.referral) ||
    'checkout_' + new Date().getTime()
  );
}

const PaymentCheckout = () => {
  const classes = useStyles();

  const themeMode = useContext(ThemeContext);

  const [planType, setPlanType] = useState('month');

  const [plans, setPlans] = useState<any>([]);

  const [word, setWord] = useState<any>('100K');

  const dispatch = useAppDispatch();

  const { payment, user, auth } = useReduxState();

  const navigate = useNavigate();

  const params = useParams();

  useEffect(() => {
    if (user.isSubscriber) {
      return navigate('/account/credits-billings');
    }
  }, [user.isSubscriber]);

  useEffect(() => {
    loadStripe(
      ['app-stg.clarice.ai', 'tests.clarice.ai', 'local-host'].includes(
        window.location.hostname
      )
        ? keys.test
        : keys.live,
      {
        locale: 'pt-BR'
      }
    ).then(s => {
      stripe = s;
    });
    // .catch(e => console.log(e));
  }, []);

  useEffect(() => {
    if (payment.plans && payment.plans.length > 0 && auth.authenticated) {
      // REDIRECIONAR

      localStorage.getItem('inactive');

      const index = params['*'] && params['*'].replace('payment/checkout/', '');

      if (index) {
        const selects = {
          'monthly/revisora': 'Plano Mensal IA Revisora',
          'monthly/escritora/50K':
            'Plano Mensal IA Escritora + IA Revisora | 50.000 palavras',
          'monthly/escritora/100K':
            'Plano Mensal IA Escritora + IA Revisora | 100.000 palavras',
          'monthly/escritora/250K':
            'Plano Mensal IA Escritora + IA Revisora | 250.000 palavras',
          'yearly/revisora': 'Plano Anual IA Revisora (Parcelado em 12 vezes)',
          'yearly/escritora/50K':
            'Plano Anual IA Escritora + IA Revisora | 50.000 palavras (Parcelado em 12 vezes)',
          'yearly/escritora/100K':
            'Plano Anual IA Escritora + IA Revisora | 100.000 palavras (Parcelado em 12 vezes)',
          'yearly/escritora/250K':
            'Plano Anual IA Escritora + IA Revisora | 250.000 palavras (Parcelado em 12 vezes)',
          // 'yearly/revisora': 'Plano Anual IA Revisora',
          // 'yearly/escritora/50K':
          //     'Plano Anual IA Escritora + IA Revisora | 50.000 palavras',
          // 'yearly/escritora/100K':
          //     'Plano Anual IA Escritora + IA Revisora | 100.000 palavras',
          // 'yearly/escritora/250K':
          //     'Plano Anual IA Escritora + IA Revisora | 250.000 palavras'
          'monthly/criativo': 'Assinatura Mensal | Criativo',
          'yearly/criativo': 'Assinatura Anual | Criativo',
          'monthly/profissional': 'Assinatura Mensal | Profissional',
          'yearly/profissional': 'Assinatura Anual | Profissional'
        };

        const selected = index ? selects[index] : undefined;

        if (selected) {
          const plan = payment.plans.find(plan => plan.nickname === selected);

          if (plan) {
            // REDIRECIIONAR

            onRedirectToCheckout(
              plan.id,
              plan.interval === 'month' ? 'monthly' : 'yearly'
            );
          } else {
            if (location?.search.includes('?utm_source=website')) {
              return navigate('/plans?utm_source=website');
            }

            if (location?.search.includes('?utm_source=education')) {
              return navigate('/plans?utm_source=education');
            }

            navigate('/plans');
          }
        } else {
          if (location?.search.includes('?utm_source=website')) {
            return navigate('/plans?utm_source=website');
          }

          if (location?.search.includes('?utm_source=education')) {
            return navigate('/plans?utm_source=education');
          }

          navigate('/plans');
        }
      } else {
        if (location?.search.includes('?utm_source=website')) {
          return navigate('/plans?utm_source=website');
        }

        if (location?.search.includes('?utm_source=education')) {
          return navigate('/plans?utm_source=education');
        }

        navigate('/plans');
      }
    } else {
      // ARMAZENAR VARIÁVEL E REDIRECIONAR PARA SIGN-IN
      // navigate('/plans');
    }
  }, [payment.plans, planType, params]);

  const onRedirectToCheckout = async (priceId: string, type) => {
    // console.log({ priceId, type });

    const res = await withAuth().post(
      '/payments/create-checkout-session',
      ACTIVE_COUPON === 'NO_DISCOUNT' || type !== 'yearly'
        ? {
            priceId,
            client_reference_id: getClientReferenceId()
          }
        : {
            priceId,
            // code: ACTIVE_COUPON,
            client_reference_id: getClientReferenceId()
          }
    );

    await stripe.redirectToCheckout(res.data);
  };

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return <PaymentCheckoutLoading loading={true} />;
};

export default PaymentCheckout;

export const isBetaUser = (user: any, functionality: any = null): boolean => {
  const team =
    user?.email &&
    [
      'felipeiszlaji@gmail.com',
      'eurafaelcamillo@gmail.com',
      'croft.nathalia@gmail.com',
      'murilozs@live.ie',
      // SEM PARAR
      'marina.maschi@corpay.com.br',
      'eliabe.santana@corpay.com.br',
      'michelle.castro@corpay.com.br',
      'jefferson.moraes@squadra.com.br',
      'mrenata.silva@corpay.com.br',
      'fabiana.moreira@corpay.com.br',
      'alyni.nirazawa@corpay.com.br',
      // NOVA CONCURSOS
      'marinara.faria@novaconcursos.com.br',
      'nataly.rafaele@novaconcursos.com.br',
      'camila.custodio@novaconcursos.com.br',
      // OLIVER AGENCY
      'IA-Latam@oliver.agency',
      'felipecamargo@oliver.agency',
      'vanessafofano@oliver.agency',
      'alinebarreto@oliver.agency',
      // 'beatrizprocopio@oliver.agency',
      'nathaliaoliveira@oliver.agency',
      'eduardosilva@oliver.agency',
      'giovannapini@oliver.agency',
      'isabelamartyres@oliver.agency',
      'mariaribeiro@oliver.agency',
      'julianalustosa@oliver.agency',
      'lucianaassis@oliver.agency',
      'mallenasales@oliver.agency',
      'renatababoni@oliver.agency',
      'ruygalvao@oliver.agency',
      'thiagoevaristo@oliver.agency',
      'felipegoncalves@oliver.agency',
      'wellingtonCesar@oliver.agency',
      'guilhermemuto@oliver.agency',
      // FAMÍLIA DO SÍTIO
      'socialmedia@familiadositio.com.br',
      'analista.marketing@familiadositio.com.br',
      'rodrigo@familiadositio.com.br',
      // OTHERS
      'conrado@novi.cc',
      'raissa.mendonca@editorasolucao.com.br'
    ].includes(user.email);

  if (functionality === 'brand') {
    const plan = user?.options?.plan?.type;

    if (
      user.isSubscriber &&
      ['Assinatura Mensal | Business', 'Assinatura Anual | Business'].includes(
        plan
      )
    ) {
      return true;
    }

    const domain: any = user?.email && user.email.match(/@(.+)$/);

    const domains = ['@clarice.ai'];

    return team || (domain && domains.includes(domain[0]));
  }

  if (functionality === 'clarice-collaborators') {
    if (
      user.email &&
      [
        'yc_test@clarice.ai',
        'globo@clarice.ai',
        'plugin@clarice.ai',
        'felipe@clarice.ai'
      ].includes(user.email)
    ) {
      return false;
    }

    const domain: any = user?.email && user.email.match(/@(.+)$/);

    const team =
      user.email &&
      [
        'ola@clarice.ai',
        'admin@clarice.ai',
        'ti@clarice.ai',
        'rafael@clarice.ai',
        'murilo@clarice.ai',
        'felipe@clarice.ai',
        'comercial@clarice.ai',
        'mkt@clarice.ai',
        'nathalia@clarice.ai'
      ].includes(user?.email);

    const domains = ['@clarice.ai'];

    return team || (domain && domains.includes(domain[0]));
  }

  if (functionality === 'score') {
    return user.isSubscriber;
  }

  const domain: any = user?.email && user.email.match(/@(.+)$/);

  const domains = ['@clarice.ai'];

  return team || (domain && domains.includes(domain[0]));
};

import React, { useContext } from 'react';
import { CorrectionComponentInterface } from './interface';
import useStyles from './style';
import { useMediaQuery, useTheme } from '@mui/material';
import clsx from 'clsx';
import { ThemeContext } from '../../../../../../../../../../utils/context/ThemeContext';
import { useReduxState } from '../../../../../../../../../../hooks/useReduxState';
import { setActiveCorrectionItem } from '../../../../../../../../../../stories/actions/editor';
import { useAppDispatch } from '../../../../../../../../../../hooks/useAppDispatch';
import { GlobalContext } from '../../../../../../../../../../utils/context/GlobalContext';

const CorrectionComponent = ({
  contentState,
  entityKey,
  offsetkey,
  children
}: CorrectionComponentInterface) => {
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();
  const classes = useStyles(themeMode);

  const ctx = useContext(GlobalContext);

  const { editor, documents } = useReduxState();

  const item = contentState.getEntity(entityKey).getData();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  // console.log(editor.activeItem);

  const isActive = editor.activeItem === item._id;

  // console.log(props);
  const categoryKey = item.mainScore || item.type || Object.keys(item.score)[0];
  const style: any = {
    borderRadius: '2px',
    background: 'inherit',
    transition: 'all 0.5s ease-in-out',
    transform: isActive ? 'scale(1.05)' : 'scale(1)'
  };

  const dispatch = useAppDispatch();

  const onSetActiveCorrectionItem = item => {
    if (item?.score?.premium) {
      dispatch(setActiveCorrectionItem(item._id, true));
    } else {
      dispatch(setActiveCorrectionItem(item._id));
    }

    if (isMobile) {
      ctx.modal.corrections.toggle();
    }
  };

  if (isActive) {
    style.background = theme.palette.highlights[categoryKey](0.3);
  } else {
    style.boxShadow = `${theme.palette.highlights[categoryKey](
      0.8
    )} 0px -2px 0px inset`;
    style.borderRadius = '0px';
  }

  const isExist =
    documents.detail &&
    documents.detail.corrections[editor.activeCategory || 'deviations'].find(
      el => String(el._id) === String(item._id)
    );

  if (isExist) {
    return (
      <>
        <span
          data-offset-key={offsetkey}
          className={clsx(classes.root, 'TEXT_' + item._id)}
          style={style}
          onClick={() => !isActive && onSetActiveCorrectionItem(item)}
        >
          {children}
        </span>
      </>
    );
  }

  return <span>{children}</span>;
};

export default CorrectionComponent;

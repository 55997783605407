/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import 'draft-js/dist/Draft.css';
import 'draftail/dist/draftail.css';
import { DraftailEditor } from 'draftail';
import { CloseRounded, FileCopyOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  InputBase,
  Modal,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { state } from './const';
import decorators from './decorators';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import clsx from 'clsx';
import useStyles from './styles/style';
import './styles/styles.css';
import DropdownSuggestion from './components/DropdownSuggestion';
import DropdownSynonyms from './components/DropdownSynonyms';
import filteredCorrections from '../../../../../../utils/function/filteredCorrections';
import { useReduxState } from '../../../../../../hooks/useReduxState';
import { GlobalContext } from '../../../../../../utils/context/GlobalContext';
import CorrectionItem from '../Corrections/components/CorrectionItem';
import TextMetrics from '../CorrectionsOverview/components/TextMetrics';
import DropdownSelectorAI from './components/DropdownSelectorAI';
import { setActiveCorrectionItem } from '../../../../../../stories/actions/editor';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';

const Editor = ({
  editorState,
  onChange,
  textProps,
  handleChange,
  handleBlur,
  onCopyToClipboard,
  showSuggestions,
  setShowSuggestions,
  suggestionsRef,
  options,
  onInsertForSuggestion,
  suggestion,
  setSuggestion,
  synonyms,
  setSynonyms,
  toggleSynonyms,
  onReplaceForSynonyms,
  synonymsRef,
  onReplaceWord,
  onFixAll,
  onDismissAll,
  handleKeyDown,
  handleBeforeInput,
  corrections,
  editor,
  selector,
  setSelector,
  selectorRef,
  toggleSelector,
  onReplaceForSelector,
  onReplaceAutoCorrection,
  getContentForAutoCorrection
}) => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();
  const { translate }: TranslateInterface = useLanguage();

  const { user } = useReduxState();

  const isMobile = useMediaQuery(() => theme.breakpoints.down('md'));

  const ctx = useContext(GlobalContext);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target)
      ) {
        setTimeout(() => {
          setSuggestion({ open: false });
        }, 200);
      }
    }

    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [suggestionsRef]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (synonymsRef.current && !synonymsRef.current.contains(event.target)) {
        setTimeout(() => {
          setSynonyms({ open: false });
        }, 200);
      }
    }

    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [synonymsRef]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        setTimeout(() => {
          setSelector({ open: false });
        }, 200);
      }
    }

    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectorRef]);

  const dispatch = useAppDispatch();

  const onSetActiveCorrectionItem = item => {
    dispatch(setActiveCorrectionItem(item));
  };

  useEffect(() => {
    // console.log(editor.activeItem, editor.activeCategory, corrections);

    if (editor.activeCategory === 'deviations') {
      if (!editor.activeItem) {
        const item = corrections.deviations[0];

        if (item) {
          onSetActiveCorrectionItem(item._id);
        }
      } else {
        const index = corrections.deviations.findIndex(
          item => item._id === editor.activeItem
        );

        if (index === -1) {
          const item = corrections.deviations[0];

          if (item) {
            onSetActiveCorrectionItem(item._id);
          }
        }
      }
    }
  }, [editor.activeItem, corrections]);

  return (
    <>
      {isMobile && ctx.modal.corrections.open && (
        <Modal
          open={ctx.modal.corrections.open}
          className={classes.modalCorrection}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            padding={'0 1.5rem'}
          >
            <Box
              width={'100%'}
              display={'flex'}
              justifyContent={'flex-end'}
              alignItems={'center'}
              marginBottom={'1rem'}
            >
              <CloseRounded
                sx={{
                  color: 'white'
                }}
                onClick={() => ctx.modal.corrections.toggle()}
              ></CloseRounded>
            </Box>
            {editor.activeCategory === 'spellingAndGrammar'
              ? corrections.spellingAndGrammar.map((item: any) => {
                  if (item.id && item.custom && item._id === editor.activeItem)
                    return (
                      <CorrectionItem
                        _id={item._id}
                        correction={item}
                        key={item._id}
                        category={
                          item.type ? item.type : Object.keys(item.score)[0]
                        }
                        title={item.custom.title}
                        message={item.custom.feedback}
                        more={item.custom.more}
                        issue={item.replace}
                        sugestion={item.replacement}
                        citation={item.custom?.citation}
                        onReplaceWord={onReplaceWord}
                        onFixAll={onFixAll}
                        onDismissAll={onDismissAll}
                        onReplaceAutoCorrection={onReplaceAutoCorrection}
                        getContentForAutoCorrection={
                          getContentForAutoCorrection
                        }
                      ></CorrectionItem>
                    );
                  if (item._id === editor.activeItem)
                    return (
                      <CorrectionItem
                        _id={item._id}
                        correction={item}
                        key={item._id}
                        category={
                          item.type ? item.type : Object.keys(item.score)[0]
                        }
                        title={item.shortMessage}
                        message={item.message}
                        issue={item.spans[0].content}
                        sugestion={item.suggestions}
                        citation={item.custom?.citation}
                        onReplaceWord={onReplaceWord}
                        onFixAll={onFixAll}
                        onDismissAll={onDismissAll}
                        onReplaceAutoCorrection={onReplaceAutoCorrection}
                        getContentForAutoCorrection={
                          getContentForAutoCorrection
                        }
                      ></CorrectionItem>
                    );
                })
              : editor.activeCategory === 'deviations'
              ? corrections.deviations.map((item: any) => {
                  if (item.id && item.custom && item._id === editor.activeItem)
                    return (
                      <CorrectionItem
                        _id={item._id}
                        correction={item}
                        key={item._id}
                        category={
                          item.type ? item.type : Object.keys(item.score)[0]
                        }
                        title={item.custom.title}
                        message={item.custom.feedback}
                        more={item.custom.more}
                        issue={item.replace}
                        sugestion={item.replacement}
                        citation={item.custom?.citation}
                        onReplaceWord={onReplaceWord}
                        onFixAll={onFixAll}
                        onReplaceAutoCorrection={onReplaceAutoCorrection}
                        getContentForAutoCorrection={
                          getContentForAutoCorrection
                        }
                      ></CorrectionItem>
                    );

                  if (item._id === editor.activeItem)
                    return (
                      <CorrectionItem
                        _id={item._id}
                        correction={item}
                        key={item._id}
                        category={
                          item.type ? item.type : Object.keys(item.score)[0]
                        }
                        title={item.shortMessage}
                        message={item.message}
                        issue={item.spans[0].content}
                        sugestion={item.suggestions}
                        citation={item.custom?.citation}
                        onReplaceWord={onReplaceWord}
                        onFixAll={onFixAll}
                        onDismissAll={onDismissAll}
                        onReplaceAutoCorrection={onReplaceAutoCorrection}
                        getContentForAutoCorrection={
                          getContentForAutoCorrection
                        }
                      ></CorrectionItem>
                    );
                })
              : corrections.doubts.map((item: any) => {
                  if (item._id === editor.activeItem)
                    return (
                      <CorrectionItem
                        _id={item._id}
                        correction={item}
                        key={item._id}
                        category={
                          item.type ? item.type : Object.keys(item.score)[0]
                        }
                        title={item.custom.title}
                        message={item.custom.feedback}
                        more={item.custom.more}
                        issue={item.replace}
                        sugestion={item.replacement}
                        citation={item.custom?.citation}
                        onReplaceWord={onReplaceWord}
                        onFixAll={onFixAll}
                        onDismissAll={onDismissAll}
                        onReplaceAutoCorrection={onReplaceAutoCorrection}
                        getContentForAutoCorrection={
                          getContentForAutoCorrection
                        }
                      ></CorrectionItem>
                    );
                })}
          </Box>
        </Modal>
      )}
      <Box
        flexGrow={1}
        display={'flex'}
        justifyContent={'center'}
        width={'100%'}
      >
        <Box
          sx={{
            marginLeft: '40px',
            [theme.breakpoints.down(1300)]: {
              marginLeft: '0px'
            },
            [theme.breakpoints.down(600)]: {
              marginLeft: '0px'
            }
          }}
          maxWidth={'830px'}
          width={'100%'}
        >
          <InputBase
            className={classes.root}
            value={textProps.title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
              handleChange(e, 'title')
            }
            onBlur={handleBlur}
            style={{
              padding: '12px',
              fontSize: isMobile ? '25px' : '30px',
              fontFamily: 'Inter'
            }}
            spellCheck={false}
            placeholder={translate('page.review.form.title.placeholder')}
            fullWidth
          />
          <div
            className={clsx(
              classes.container,
              themeMode.mode === 'dark' ? 'darkTheme' : '',
              isMobile ? 'isMobile' : '',
              isMobile && themeMode.mode === 'dark' ? 'isMobileDarkTheme' : ''
            )}
          >
            <DraftailEditor
              editorState={editorState}
              onChange={onChange}
              id={state.config.id}
              ariaDescribedBy={state.config.ariaDescribedBy}
              // placeholder={state.config.placeholder}
              placeholder={translate('page.review.form.editor.placeholder')}
              enableHorizontalRule={
                isMobile ? false : state.config.enableHorizontalRule
              }
              spellCheck={state.config.spellCheck}
              stripPastedStyles={state.config.stripPastedStyles}
              entityTypes={state.config.entityTypes}
              blockTypes={
                isMobile
                  ? state.config.blockTypesMobile
                  : state.config.blockTypes
              }
              inlineStyles={state.config.inlineStyles}
              enableLineBreak={state.config.enableLineBreak}
              decorators={decorators}
              handleKeyDown={handleKeyDown}
              handleBeforeInput={handleBeforeInput}
            />

            {!isMobile && suggestion.open && (
              <DropdownSuggestion
                options={options}
                suggestion={suggestion}
                setSuggestion={setSuggestion}
                suggestionsRef={suggestionsRef}
                onInsertForSuggestion={onInsertForSuggestion}
              />
            )}

            {!isMobile && synonyms?.open && (
              <DropdownSynonyms
                synonyms={synonyms}
                setSynonyms={setSynonyms}
                synonymsRef={synonymsRef}
                toggleSynonyms={toggleSynonyms}
                onReplaceForSynonyms={onReplaceForSynonyms}
              />
            )}

            {!isMobile && selector?.open && (
              <DropdownSelectorAI
                selector={selector}
                setSelector={setSelector}
                selectorRef={selectorRef}
                toggleSelector={toggleSelector}
                onReplaceForSelector={onReplaceForSelector}
              />
            )}

            {!isMobile && (
              <Button
                startIcon={<FileCopyOutlined fontSize={'small'} />}
                size={'small'}
                color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
                style={{
                  textTransform: 'unset',
                  font: 'normal normal 400 13.5px/15px Inter',
                  letterSpacing: '0.12px',
                  padding: '5px 12px',
                  margin: '0 3px !important',
                  borderRadius: '7px',
                  position: 'fixed',
                  bottom: '16px',
                  right: '220px',
                  zIndex: 1001
                }}
                onClick={onCopyToClipboard}
              >
                {translate('page.review.action.copy')}
              </Button>
            )}

            {!isMobile && (
              <Box
                style={{
                  position: 'fixed',
                  bottom: '5px',
                  right: '370px',
                  zIndex: 1001
                }}
                display={'flex'}
                flexDirection={'column'}
                p={'19px'}
                pb={'0px'}
              >
                <TextMetrics />
              </Box>
            )}
          </div>
        </Box>
      </Box>
    </>
  );
};

export default Editor;

/* eslint-disable no-undef */
import { Dispatch } from 'react';
import { BasicDispatchParam } from '../../models/dispatchTypes';

import {
  BRANDS_CREATE,
  BRANDS_DELETE,
  BRANDS_GET_ALL,
  BRANDS_UPDATE
} from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';
import notify from '../../utils/notify';
import analytics from '../../utils/function/analytics';
import BrandsRequests from '../repositories/brands';
import mapping from '../../utils/function/mapping';

export const createBrand =
  (form: any, cb?: any) =>
  async (
    dispatch: Dispatch<BasicDispatchParam<models.CompletionByTemplateResponse>>
  ) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('brand', 'create');

      await mapping.track('Create Brand');

      const payload: any = await BrandsRequests.create(form);

      dispatch({
        payload,
        type: BRANDS_CREATE
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error();
        }

        throw new Error(e.response.data.message);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const updateBrand =
  (form: any, cb?: any) =>
  async (
    dispatch: Dispatch<BasicDispatchParam<models.CompletionByTemplateResponse>>
  ) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('brand', 'update');

      await mapping.track('Update Brand');

      const payload: any = await BrandsRequests.update(form);

      dispatch({
        payload,
        type: BRANDS_UPDATE
      });

      if (cb && cb.success) {
        cb.success();
      }

      await notify.success('Marca atualizada com sucesso!');
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error();
        }

        throw new Error(e.response.data.message);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const deleteBrand =
  (id: string, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.Document[]>>) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('brand', 'delete');

      await mapping.track('Delete Brand');

      await BrandsRequests.delete(id);

      const payload: any = {
        _id: id
      };

      dispatch({
        payload,
        type: BRANDS_DELETE
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (e) {
      if (e instanceof Error) {
        if (cb && cb.error) {
          cb.error();
        }

        notify.error(
          'Ocorreu algum erro ao excluir sua marca, tente novamente.'
        );
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const getBrands =
  (cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.Project>>) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('brand', 'brand-all');

      await mapping.track('Get All Brands');

      const payload: any = await BrandsRequests.getAll();

      dispatch({
        payload,
        type: BRANDS_GET_ALL
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (err) {
      if (err instanceof Error) {
        // console.log(err.message);

        if (cb && cb.error) {
          cb.error();
        }
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const generateWithIA =
  (description: any, cb?: any) =>
  async (
    dispatch: Dispatch<BasicDispatchParam<models.CompletionByTemplateResponse>>
  ) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('brand', 'create-with-ia');

      await mapping.track('Create Brand With IA');

      const payload: any = await BrandsRequests.generateWithIA(description);

      if (cb && cb.success) {
        cb.success(payload);
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error();
        }

        throw new Error(e.response.data.message);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    maxHeight: '95%',
    overflowY: 'auto',
    '& p, h2': {
      color: theme.palette.text.primary
    }
  },
  title: {
    marginBottom: 16,
    marginTop: 0,
    font: 'normal normal 600 20px/21px Inter'
  },
  containerInput: {
    // marginTop: '20px',
    // marginBottom: '20px',
    width: '100%',
    display: 'flex',
    // maxWidth: '920px',
    flexDirection: 'row',
    alignItems: 'center',
    height: '50px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      height: 'auto'
    }
  },
  inputBar: {
    background:
      theme.palette.mode === 'light'
        ? '#F2F2F2!important'
        : '#323232!important',
    height: '50px',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '6px',
    '& > img': {
      margin: '0px 19px'
    },
    [theme.breakpoints.up(1000)]: {
      marginRight: '10px'
    },
    [theme.breakpoints.down(999)]: {
      marginBottom: '20px'
    }
  },
  input: {
    flexGrow: 1,
    font: 'normal normal 400 16px/19px Inter',
    color: theme.palette.text.primary
  },
  button: {
    marginLeft: '10px',
    whiteSpace: 'nowrap',
    // minWidth: '120px',
    width: '100%',
    height: '46px',
    textTransform: 'unset',
    maxWidth: 'unset',
    font: ' normal normal 400 15px/18px Inter',
    marginTop: '1px',
    letterSpacing: 0,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 0
    },
    [theme.breakpoints.down(999)]: {
      marginBottom: '20px'
    }
  }
}));

export default useStyles;
